import React, { useState, useEffect} from 'react';
import {
  useHistory,
} from "react-router-dom";

import ViewStyles from './Views.style';

export default ({ pass, onCorrect, goToUrl=false }) => {
  const [passAttempt, editPass] = useState('');
  const history = useHistory();
  useEffect(() => {
    if (passAttempt === pass) {
      setTimeout(() => {
        if (goToUrl) {
          history.push(goToUrl);
        }

        if (onCorrect) {
          onCorrect(true);
        }
      }, 1000);
    }
  }, [passAttempt, history, goToUrl, pass, onCorrect]);

  return (
    <ViewStyles data-password>
      <div data-redirect={passAttempt === pass} className="big-circle" />
      <input placeholder="🗝" className="password" value={passAttempt} onChange={(e) => editPass(e.target.value)}/>
    </ViewStyles>
  )


};
