import styled from 'styled-components';

export default styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  /* background: linear-gradient(to bottom, #fff 50%, #C0B0B5 100%); */

  .hero-holder {
    color: ${(({ theme }) => theme.colors.black)};
    background: ${(({ theme }) => theme.colors.windowsBlue)};
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
  }

  .duck {
    z-index: 2;
    position: absolute;
    width: 100px;
    height: 100px;
    transition: 0.2s all ease-in-out;
    &.go {
      display: block;
    }

    img {
      width: 100%;
      height: auto;
    }
  }

  .exit-panel {
    width: calc(100% + 2px);
    height: 27px;
    position: absolute;
    top: -30px;
    left: -2px;
    border: 1px solid ${(({ theme }) => theme.colors.black)};
    background: ${(({ theme }) => theme.colors.windows)};
    &:before {
      line-height: 28px;
      content: 'Profile Overview';
      display: block;
      text-align: left;
      float: left;
      color: white;

      margin-left: 5px;
    }
    &:after {
      line-height: 28px;
      content: '_    □	   X';
      display: block;
      text-align: right;
      margin-right: 5px;
      float: right;
      color: white;

    }
  }

  .centered-text {
    position: absolute;
    width: 50%;
    text-align: center;
    background: #CCC6BB;

    top: 50%;
    left: 50%;
    padding: 8px;
    border: 2px solid ${(({ theme }) => theme.colors.black)};
    transform: translate(-50%, -50%);

  }
`;
