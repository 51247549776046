import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NavStyle from './Nav.style';
import { Controller } from 'react-scrollmagic';

const Logo = () => (
  <Link to="/">
    <svg className="logo" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    	 width="150" height="100" viewBox="0 0 478.5 287">
      <text transform="matrix(1 0 0 1 8.4028 146.1055)" fontSize="217.6789">SAM</text>
      <text transform="matrix(0.9738 0 0 1 10.8296 286.5527)" fontSize="147.6295">CHAMI</text>
    </svg>
  </Link>
);

const links = {
  github: (
    <a target="_blank" rel="noopener noreferrer" href="//github.com/samchami">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z"/>
      </svg>
      <span className="hover-explanation">
        Github
      </span>
    </a>
  ),
  contact: (
    <a target="_blank" rel="noopener noreferrer" href="mailto:sam@chami.io">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M12 .02c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.99 6.98l-6.99 5.666-6.991-5.666h13.981zm.01 10h-14v-8.505l7 5.673 7-5.672v8.504z"/>
      </svg>
      <span className="hover-explanation">
        Contact
      </span>
    </a>
  ),
  resume: (
    <a target="_blank" rel="noopener noreferrer" href="/resume">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M12.819 14.427c.064.267.077.679-.021.948-.128.351-.381.528-.754.528h-.637v-2.12h.496c.474 0 .803.173.916.644zm3.091-8.65c2.047-.479 4.805.279 6.09 1.179-1.494-1.997-5.23-5.708-7.432-6.882 1.157 1.168 1.563 4.235 1.342 5.703zm-7.457 7.955h-.546v.943h.546c.235 0 .467-.027.576-.227.067-.123.067-.366 0-.489-.109-.198-.341-.227-.576-.227zm13.547-2.732v13h-20v-24h8.409c4.858 0 3.334 8 3.334 8 3.011-.745 8.257-.42 8.257 3zm-12.108 2.761c-.16-.484-.606-.761-1.224-.761h-1.668v3.686h.907v-1.277h.761c.619 0 1.064-.277 1.224-.763.094-.292.094-.597 0-.885zm3.407-.303c-.297-.299-.711-.458-1.199-.458h-1.599v3.686h1.599c.537 0 .961-.181 1.262-.535.554-.659.586-2.035-.063-2.693zm3.701-.458h-2.628v3.686h.907v-1.472h1.49v-.732h-1.49v-.698h1.721v-.784z"/>
      </svg>
      <span className="hover-explanation">
        Resume
      </span>
    </a>
  ),
  linkedIn: (
    <a target="_blank" rel="noopener noreferrer" href="//www.linkedin.com/in/samchami">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z"/>
      </svg>
      <span className="hover-explanation">
        LinkedIn
      </span>
    </a>
  ),
}

const Nav = () => {
  const mobile = window.innerWidth <= 767;
  const [opened, setOpened] = useState(false);

  return (
    <Controller>
        <NavStyle
          className="nav global-gradient"
          data-mobile={mobile}
          data-opened={opened}
        >
          <div className="logo-top">
          <div
            className="nav-hamburger"
            onClick={() => setOpened(!opened)}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="links">
            {links.github}
            {links.resume}
            {links.contact}
            {links.linkedIn}
          </div>
          </div>
          <div className="logo-side">
            <Logo />
          </div>
        </NavStyle>
    </Controller>
  );
}

export default Nav;
