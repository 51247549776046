import { createGlobalStyle, keyframes } from 'styled-components';

const spin = keyframes`
  0%{transform: rotate(0deg)}
  100%{transform: rotate(360deg)}
`;

const emphasize = keyframes`
  0%{}
  100%{
    color: black;
  }
`;

const shrink = keyframes`
  0%{
    bottom: 7px;
  }
  100%{
    bottom: 1px;
    height: 4px;
  }
`;

const getBackgroundColor = (props) => {
  return props.theme.pages[props.colorKey] || props.theme.defaults.backgroundMain;
}

// Global Styling
export default createGlobalStyle`
  @font-face {
      font-family: Montserrat;
      src: local("Montserrat-Regular"), local("Montserrat-Regular"),
      url("/public/fonts/Monserrat/Montserrat-Regular.ttf") format("truetype"),
      font-weight: 300;
      font-style: normal;
  }
  body {
    margin: 0 0 0 0;
    font-family: Montserrat, Verdana, Arial, Sans-Serif;
    background: ${getBackgroundColor};
    transition: 0.3s background-color;
  }

  .emphasize {
    position: relative;
    color: ${getBackgroundColor};
    z-index: 1;
    &:after {
      z-index: -1;
      content: "";
      position: absolute;
      left: 1%;
      bottom: 7px;
      width: 98%;
      height: 100%;
      background: black;
      animation: 1.6s ${shrink} ease-in-out forwards;
      animation-delay: 0.4s;

    }

    animation: 1.2s ${emphasize} linear forwards;
    animation-delay: 0.6s;

  }

  [data-gradient] {
    // background: linear-gradient(79deg, #fff, #000);
    background: linear-gradient(to bottom, #fff 50%, #fff 80%, #5c26aa 100%);
  }

  main {
    transition: all 1s;
  }

  .trigger-section {
    position: absolute;
    top: 100vh;
    background: transparent;
    width: 5px;
    height: 5px;
    left: 0;
  }

  .general {
    text-align: right;
    position: fixed;
    transition: 0.5s left ease-in-out;
    bottom: 20px;
    left: -100px;
    overflow: hidden;
    width: 58px;
    height: 58px;
    border: ${(({ theme }) => theme.shorthand.border)};
    border-left: none;
    z-index: 3;
    padding: 4px;

    img {
      width: 42px;
      height: 42px;
      animation: 1s ${spin} linear infinite;
    }
  }

  .general-enter {
    left: 0;
  }

  .general-exit {
    opacity: 0;
    left: 0;
  }

  // Rotate Translation
  // .swipe-enter {
  //   transform: rotateY(90deg);
  //   transition: transform 0.5s 0.5s;
  // }
  //
  // .swipe-enter.swipe-enter-active {
  //   transform: rotateY(0);
  // }
  //
  // .swipe-exit {
  //   position: absolute;
  //   left: 50%;
  //   top: 50px;
  //   transform: rotateY(0deg) translateX(-50%);
  // }
  //
  // .swipe-exit.swipe-exit-active {
  //   transform: rotateY(90deg) translateX(-50%);
  //   transform-origin: left;
  //   transition: transform 0.5s;
  // }

  // Fade Translation
  .swipe-enter {
    opacity: 0;
  }

  .swipe-enter.swipe-enter-active {
    opacity: 1;
  }

  .swipe-exit {
    position: absolute;
    width: 100%;
    top: 50px;
    opacity: 1;
  }

  .swipe-exit.swipe-exit-active {
    opacity: 0;
    transition: opacity 0.5s;
  }

  // .fade-enter {
  //   opacity: 0;
  //   z-index: 1;
  //   transition: opacity 3s ease-in;
  // }
  //
  // .fade-enter.fade-enter-active {
  //   opacity: 1;
  // }


  .home {
    width: 100%;
    border: ${(({ theme }) => theme.shorthand.border)};
    height: 85vh;

    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      height: auto;
    }

    &-photo {
      width: auto;
      height: 40vh;
      min-height: 40vh;
      min-width: 40vh;
      background-color: rgba(255, 255, 255, 0.2);
      border: ${(({ theme }) => theme.shorthand.border)};

      @media ${(({ theme }) => theme.breakpoints.mobile)} {
        height: 25vh;
        min-height: 25vh;
        min-width: 25vh;
      }
    }
  }

  .bottom {
    border-bottom: ${(({ theme }) => theme.shorthand.border)};
  }
`
