import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';

import SkillsStyle from './Skills.style';

const SkillBox = ({ name, picture, backgroundColor, size }) => (
  <div className="skill-box" data-size={size}>
    <div className="skill-logo" style={{backgroundColor}}>
      <img src={picture} alt={name} />
    </div>
    <div className="skill-text">
      {name}
    </div>
  </div>
);

const Skills = () => (
  <SkillsStyle>
    <Controller>
      <Scene classToggle={['.nav', 'down-position']} reverse={true} indicators={false}>
        <div className="skills-section" />
      </Scene>
      <Scene
        triggerHook="onLeave"
        duration="300%"
        pin
      >
        <Timeline
          wrapper={<div id="skillContainer" />}
        >
          <Tween
            from={{ left: '80px'}}
            to={{ left: '-2100px' }}
          >
            <div className="skills-holder">
              <div className="skills-cluster">
                <div className="skills-text">
                  Web Development
                </div>
                  <SkillBox
                    name="JavaScript"
                    picture="../assets/skills/javascript.png"
                    backgroundColor="#f5db1a"
                  />
                  <SkillBox
                    name="HTML5"
                    picture="../assets/skills/html.png"
                    backgroundColor="#f77436"
                  />
                  <SkillBox
                    name="CSS3/SCSS"
                    picture="../assets/skills/css.png"
                    backgroundColor="#3c7adb"
                  />
                  <SkillBox
                    name="Python"
                    picture="../assets/skills/python.png"
                    backgroundColor="#fff"
                  />
                  <SkillBox
                    name="Liquid"
                    picture="../assets/skills/liquid.jpg"
                    backgroundColor="#fff"
                  />
                  <SkillBox
                    name="pHp"
                    picture="../assets/skills/php.png"
                    backgroundColor="#161822"
                  />
              </div>
              <div className="skills-cluster" data-max="4">
                <div className="skills-text">
                  Frameworks and Libraries
                </div>
                <SkillBox
                  name="React"
                  picture="../assets/skills/react.png"
                  backgroundColor="#1a1a1a"
                />
                <SkillBox
                  name="Redux"
                  picture="../assets/skills/redux.png"
                  backgroundColor="#212836"
                />
                <SkillBox
                  name="jQuery"
                  picture="../assets/skills/jquery.png"
                  backgroundColor="#142744"
                />
                <SkillBox
                  name="Django"
                  picture="../assets/skills/django.png"
                  backgroundColor="#0b2319"
                />
                <SkillBox
                  name="Laravel"
                  picture="../assets/skills/laravel.png"
                  backgroundColor="#fff"
                  size="small"
                />
                <SkillBox
                  name="ThreeJS"
                  picture="../assets/skills/threejs.png"
                  backgroundColor="#1a8af1"
                  size="small"
                />
                <SkillBox
                  name="GSAP"
                  picture="../assets/skills/gsap.svg"
                  backgroundColor="#1d1d1d"
                  size="small"
                />
                <SkillBox
                  name="Scroll Magic"
                  picture="../assets/skills/scrollmagic.png"
                  backgroundColor="#6496ec"
                  size="small"
                />
                <SkillBox
                  name="AR.js"
                  picture="../assets/skills/arjs.png"
                  backgroundColor="#fff"
                  size="small"
                />
              </div>
              <div className="skills-cluster" data-max="4">
                <div className="skills-text">
                  Systems
                </div>
                <SkillBox
                  name="Shopify"
                  picture="../assets/skills/shopify.png"
                  backgroundColor="#fff"
                />
                <SkillBox
                  name="Wordpress"
                  picture="../assets/skills/wordpress.png"
                  backgroundColor="#2d8588"
                />
                <div className="skills-text">
                  Environment and Helpers
                </div>
                <SkillBox
                  name="NodeJS"
                  picture="../assets/skills/node.png"
                  backgroundColor="#fff"
                  size="small"
                />
                <SkillBox
                  name="Git"
                  picture="../assets/skills/git.png"
                  backgroundColor="#fff"
                  size="small"
                />
                <SkillBox
                  name="Gulp"
                  picture="../assets/skills/gulp.png"
                  backgroundColor="#fff"
                  size="small"
                />
                <SkillBox
                  name="Sass"
                  picture="../assets/skills/sass.png"
                  backgroundColor="#fff"
                  size="small"
                />
              </div>
            </div>
          </Tween>
        </Timeline>
      </Scene>
    </Controller>
  </SkillsStyle>
);

export default Skills;
