const nav = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Resume",
    path: "/resume",
  },
  {
    title: "Projects",
    path: "/projects",
  },
  {
    title: "Blog",
    path: "/blog",
  },
  {
    title: "Contact",
    path: "/contact",
  },
]

export default nav;
