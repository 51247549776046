import React from 'react';
import {
  Link,
} from "react-router-dom";
import Socials from '../components/UI/Socials';
import navLinks from '../constants/nav';

export default () => {

  return (
    <div key="content" className="container">
      <div className="home" style={{ position: 'relative' }}>
        <div className="row align-items-center h-100 p-md-5 p-2 text-center text-md-left">
          <div className="col-12 col-md-6">
            <h1 className="mt-2">Hi. I'm Sam</h1>
            <img className="home-photo mt-md-0 mt-4" alt="me" src="/assets/me/sam0.jpg" />
            <div className="mt-2 d-flex">
              <Socials data-condensed />
            </div>
          </div>
          <div className="col-12 col-md-6">
            {navLinks.slice(1).map((n) => (
              <Link key={n.path} to={n.path} className="d-block h1 my-4">
                {n.title}
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
