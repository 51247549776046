import React from 'react';

import Nav from '../components/Abstract/AbstractNav/Nav';
import Hero from '../components/Abstract/Hero/Hero';
import BackDrop from '../components/Abstract/BackDrop/BackDrop';
import Projects from '../components/Abstract/Projects/Projects';
import Education from '../components/Abstract/Education/Education';
import Skills from '../components/Abstract/Skills/Skills';
import Work from '../components/Abstract/Work/Work';
import Contact from '../components/Abstract/Contact/Contact';

export default () => ([
  <BackDrop />,
  <Nav />,
  <Hero />,
  <Education />,
  <Work />,
  <Skills />,
  <Projects />,
  <Contact />,
]);
