import styled, { keyframes } from 'styled-components';

const ticker = keyframes`
  0% {
    transform: translate(0, -50%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
`;

export default styled.div`
  position: relative;
  z-index: 0;
  overflow: hidden;
  background: ${(({ theme }) => theme.colors.black)};

  .projects-bash {
    position: absolute;
    top: 100px;
    opacity: 0.8;
    color: ${(({ theme }) => theme.colors.white)};

    li {
      list-style: none;
      padding-left: 4px;
    }
  }

  .projects-overlay {
    z-index: 1;
    pointer-events: none;
    margin: auto;
    opacity: 1.2;
    background: white;
    width: 100vw;
    height: 50px;
    /* left: 50% !important; */
    background: white;
    overflow: hidden;
    .projects-title {
      width: 100%;
      height: 100%;
      background: ${(({ theme }) => theme.colors.black)};
      color: ${(({ theme }) => theme.colors.offWhite)};
      box-sizing: border-box;
      overflow: hidden;
      position: absolute;

      .wrapper {
        opacity: 0.6;

        height: 100%;
        width: calc(100% - 325px);
        color: ${(({ theme }) => theme.colors.white)};
        background: ${(({ theme }) => theme.colors.black)};
        overflow: hidden;
        position: absolute;
        top: 0;
      }

      .sliding {
        position: absolute;
        top: 50%;
        white-space: nowrap;
        font-size: 3em;
        line-height: 50px;
        animation: ${ticker} 20s linear infinite;

        &.slide-blur {
          filter: blur(3px);
        }
      }
    }
  }

  #pinContainer {
    height: calc(100vh);
    width: calc(100vw);
    color: ${(({ theme }) => theme.colors.white)};

    display: inline-block;
    grid-column-start: 1;
    grid-column-end: 3;

    .pin-box {
      position: absolute;
      top: 50px;
      height: calc(100vh - 50px);
      width:100%;
      background: ${(({ theme }) => theme.colors.black)};

      .pin-holder {
        position: relative;
        text-align: center;
        padding: 7%;

        .pin-content {
          position: relative;
          width: calc(100% - 100px);
          text-align: center;
          padding: 50px;

          ul {
            text-align: left;
          }

          @media ${(({ theme }) => theme.breakpoints.mobile)} {
            width: 100%;
            padding: 50px 0;
          }
        }
        .project-text {
          padding: 0 12%;
        }
        .project-image {
          max-height: calc(100% - 250px);
          text-align: center;
          width: 100%;
          margin: auto;
          overflow: hidden;

          @media ${(({ theme }) => theme.breakpoints.mobile)} {
            margin-top: 50px;
          }

          img {
            width: 100%;
            max-width: 80%;
            height: auto;

            @media ${(({ theme }) => theme.breakpoints.mobile)} {
              width: 100%;
              height: auto;
            }
          }
        }
        .project-url {
          border: 1px solid ${(({ theme }) => theme.colors.white)};
          line-height: 50px;
          padding: 20px 50px;
          text-decoration: none;
          background: transparent;
          color: ${(({ theme }) => theme.colors.white)};
          transition: 0.2s all ease-in-out;

          &:hover {
            color: ${(({ theme }) => theme.colors.black)};
            background: ${(({ theme }) => theme.colors.white)};
          }
        }
      }
    }
  }

  .projects-skills {
    position: absolute;
    top: 20px;
    left: 0;
    width: 100%;
    min-height: 100px;
    background: red;
  }

`;
