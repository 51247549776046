import styled from 'styled-components';

export default styled.div`
  border-bottom: ${(({ theme }) => theme.shorthand.border)};;
  display: table;
  overflow: hidden;

  &:not(:first-child) {
    &[data-position] {
      border-top: none;
    }
  }

  &.top {
    border-top: ${(({ theme }) => theme.shorthand.border)};;
  }

  &.mobile-right {
    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      border-right: ${(({ theme }) => theme.shorthand.border)};;
    }
  }

  &.mobile-bottom {
    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      border-bottom: ${(({ theme }) => theme.shorthand.border)} !important;
    }
  }

  &.no-bottom {
    border-bottom: none;
  }

  &.center {
    margin: auto;
  }

  &.right {
    margin-right: 0;
    margin-left: auto;
  }

  &.no-vertical-overflow {
    display: block;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border-left: ${(({ theme }) => theme.shorthand.border)};;
    border-right: ${(({ theme }) => theme.shorthand.border)};;

    & > div {
      border: none;
    }
  }

  &.mobile-full {
    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      min-width: 100%;
    }
  }

  &.mobile-hide {
    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      display: none;
    }
  }
`;
