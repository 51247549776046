import React from 'react';

import { Link } from "react-router-dom";

const DescriptionHolder = React.memo(({ img, copy, cta, ctaLink}) => (
  <div>
    <img src={img} alt={copy} />
    <p className="">
      { copy }
    </p>
    {ctaLink && (
      <div>
        <a href={ctaLink} target="_blank" rel="noopener noreferrer">{cta}</a>
      </div>
    )}
  </div>
));

export const projectData = [
  {
    name: 'Nutraceuticals For Life',
    type: '🖥',
    role: 'Full Stack Engineer, Frontend + Backend',
    stack: 'React / Django',
    img: '/assets/n4l.png',
  },
  {
    name: 'Cavu',
    type: '🖥',
    role: 'Lead Engineer, Frontend + Backend',
    stack: 'React / Django',
    img: '/assets/cavu.png',
    summary: (
      <DescriptionHolder
        img="/assets/cavu.png"
        copy={([
          <p key="c-desc">Cavu is an Internal Sales Navigator for Instagram, Tik Tok, and Facebook. This webapp allows outreach teams to increase their sales through tools not intuitive found within their target platforms.</p>,
        ])}
      />
    )
  },
  {
    name: 'Mail Helper',
    type: '🖥',
    role: 'Lead Engineer, Frontend',
    stack: 'React',
    img: '/assets/mail-helper.png',
    summary: (
      <DescriptionHolder
        img="/assets/mail-helper.png"
        cta="View Repo"
        ctaLink="https://github.com/samchami/mail-helper"
        copy={([
          <p key="m-desc">A simple React app for assisting with general email development.</p>,
          <p key="m-desc1">Includes:</p>,
          <ul key="m-list">
            <li>Default templates from various types of emails</li>
            <li>Browser Compatibility Checking</li>
            <li>Previews for common dimensions on mobile + desktop</li>
            <li>Import liquid-style variables into your email preview</li>
            <li>Quickly save current progress to localStorage</li>
          </ul>
        ])}
      />
    )
  },
  {
    name: 'chami.io (this portfolio)',
    type: '🖥',
    role: 'Design + Development',
    img: '/assets/chamio.png',
    stack: 'React / GSAP / ScrollMagic',
    summary: (
      <DescriptionHolder
        img="/assets/chamio.png"
        copy={([
          <p key="desc">
            The design of this portfolio saw many iterations.&nbsp;
            <Link to="/abstract" target="_blank">It originally looked something like this</Link>,
            until I decided I wanted something a little more authentic to myself; a person who does
            not just like pushing the technical boundaries, but also appreciates bold and simple design.
          </p>,
          <p key="desc-2">
            Since this portfolio looked completely different at the start, technical debt flared its head wildly.
            In all honesty, there are a few large architectural choices I made early on that I've since had to live with.
            Regardless, I'm proud of my usage of animation libraries, responsive styling, and overall design that resulted
            from of around a year of trial and error.
          </p>
        ])}
      />
    )
  },
  {
    name: 'Timeout Games',
    type: '🖥',
    role: 'Lead Engineer, Frontend + Backend',
    img: '/assets/timeout.png',
    stack: 'React / Django',
    summary: (
      <DescriptionHolder
        img="/assets/timeout.png"
        copy="This is a work in progress. A social sports application. More details coming soon!"
      />
    )
  },
  {
    name: 'Jumble.mx',
    type: '🖥',
    img: '/assets/jumble.png',
    role: 'Consultation + Frontend Development',
    stack: 'React / Next.js',
    summary: (
      <DescriptionHolder
        copy={([
          <p key="desc">
            Jumble is a tool for finding tracks that mix well with other tracks.
            Additionally, Jumble provides meaningful attributes of a track, such as BPM,
            key, and many other audio features.
          </p>,
          <p key="desc-2">
            This application was made by&nbsp;
            <a target="_blank" rel="noopener noreferrer" href="//github.com/blakecrowther">Blake Crowther</a>
            , with ocassional technical consultation and frontend development by myself.
          </p>
        ])}
        img="/assets/jumble.png"
        cta="View Website"
        ctaLink="https://jumble.mx"
      />
    )
  },
  {
    name: 'CoOp',
    type: '📱',
    role: 'Lead Developer',
    img: '/assets/coop.png',
    stack: 'React Native / Express / PostgreSQL',
    summary: (
      <DescriptionHolder
        img="/assets/coop.png"
        cta="View Repo"
        ctaLink="https://github.com/johns/coop"
        copy={([
          <p key="desc">
            CoOp is an all-in-one task management messaging application. CoOp allows groups of users to add and manage individual tasks and work together towards their goals.
          </p>,
          <ul key="list">
            <li>Built using React Native, Redux, Socket.io, Express, and Postgres</li>
          </ul>
        ])}
      />
    )
  },
  {
    name: 'Tongue and Cheek',
    type: '🎮',
    role: 'Lead Developer',
    img: '/assets/tonguecheek.png',
    stack: 'C# / Unity',
    summary: (
      <DescriptionHolder
        img="/assets/tonguecheek.png"
        cta="View Repo"
        ctaLink="https://github.com/samchami/tongue-and-cheek"
        copy={([
          <p key="desc">
            Inspired by QWOP and Getting Over it, Tongue and Cheek is a 2D side scrolling game following a similar ridiculous physics concept. Tongue and Cheek is a light-hearted adventure game featuring potato superhero, Cheek, as he finds his way through a comical and difficult labyrinth.
          </p>
        ])}
      />
    )
  },
  {
    name: 'CookieJar',
    type: '🎨',
    role: 'UI / UX',
    stack: 'Figma',
    img: '/assets/cookiejar.png',
    excerpt: '',
    summary: (
      <DescriptionHolder
        img="/assets/cookiejar.png"
        copy={([
          <p key="desc">
            CookieJar is an habit-first map and navigation and discovery application. This lightweight mobile application focuses on simplicty, location trends, and user's individual interests, delivering a more user-tailored experience than a traditional mapping application.
          </p>
        ])}
      />
    )
  },
  {
    name: 'alüla',
    type: '📚',
    role: 'Development',
    img: '/assets/alula.png',
    stack: 'JavaScript / Ohm',
    excerpt: 'A static, strongly typed experimental programming language.',
    summary: (
      <DescriptionHolder
        img="/assets/alula.png"
        cta="View Repo"
        ctaLink="https://github.com/johns/alula"
        copy={([
          <p key="desc">
            A static, strongly typed experimental programming language.
          </p>,
        ])}
      />
    )
  },
]
