import styled, { keyframes } from 'styled-components';

const clouds = keyframes`
  0%{transform: translate(0px)}
  100%{transform: translate(-450px)}
`;

const wind = keyframes`
  0%{transform: rotate(1deg)}
  20%{transform: rotate(0deg)}
  40%{transform: rotate(1deg)}
  60%{transform: rotate(0deg)}
  80%{transform: rotate(1deg)}
  100%{transform: rotate(0deg)}
`;

const right = keyframes`
  0%{transform: translateY(60px)}
  10%{transform: translateY(25px)}
  25%{transform: translateY(30px)}
  40%{transform: translateY(10px)}
  50%{transform: translateY(0px)}
  75%{transform: translateY(5px)}
  100%{transform: translateY(0px)}
`;

const left = keyframes`
  0%{transform: translateY(30px)}
  10%{transform: translateY(0px)}
  25%{transform: translateY(30px)}
  40%{transform: translateY(5px)}
  65%{transform: translateY(23px)}
  75%{transform: translateY(20px)}
  100%{transform: translateY(15px)}
`;

const ball = keyframes`
  0%{transform: translate(calc(100vw / 12 - 30px), 60px)}
  25%{transform:  translate(calc(-1 * ((100vw / 12) - 30px)), 30px)}
  50%{transform: translate(calc(100vw / 12 - 30px), -10px)}
  75%{transform:  translate(calc(-1 * ((100vw / 12) - 30px)), 20px)}
  100%{transform: translate(calc(100vw / 12 - 30px), 0px)}
`;

export default styled.div`
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-height: 100vh;
    min-width: 1000px;
    width: 100vw;

    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      height: 100vh;
    }
  }
  #screen {
    min-width: calc(100vw / 6);
    min-height: calc(100vh / 6);
  }

  #clouds {
    animation: 20s ${clouds} ease-in-out alternate infinite;
  }

  #palm-front {
    transform-origin: top center;
  }

  .leaf-1 {
    animation: 5s ${wind} ease-in-out alternate infinite;
    animation-delay: 300ms;
  }
  .leaf-2 {
    animation: 5s ${wind} ease-in-out alternate infinite;
    animation-delay: 200ms;

  }
  .leaf-3 {
    animation: 5s ${wind} ease-in-out alternate infinite;
    animation-delay: 600ms;
  }
  .leaf-4 {
    animation: 5s ${wind} ease-in-out alternate infinite;
  }
  .leaf-5 {
    animation: 5s ${wind} ease-in-out alternate infinite;
  }

  #right-side {
    animation: 1s ${right} linear alternate infinite;
  }

  #left-side {
    animation: 1s ${left} linear alternate infinite;
  }

  #ball {
    animation: 1s ${ball} linear alternate infinite;
  }

  .ref {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
