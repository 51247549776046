import React from 'react';
import WorkStyle from './Work.style';
import { Controller, Scene } from 'react-scrollmagic';

const Work = () => (
  <WorkStyle>
    <Controller>
      <Scene classToggle={['.nav', 'blue']} reverse={true} indicators={false}>
        <div className="projects-section" />
      </Scene>
    </Controller>
    <div
      className="work-holder"
      style={{backgroundImage: 'url(https://wallpaperaccess.com/full/90279.jpg)'}}
    >
      <div className="experience-box">
        <div className="experience-panel">
          <span role="img" aria-label="Wonderful">&#10024;</span>
          &nbsp;Wonderful Collective <span className="d-none d-md-inline">/ Los Angeles, CA.</span>
        </div>
        <div className="experience-holder">
          <h2>Frontend Software Engineer</h2>
          <h3>August 2019 - Present</h3>
          <ul>
            <li>
              Developed a lightweight, open source library comprised of useful React Custom Hooks
              for ease of use during the development process.
            </li>
            <li>
              Work closely with project managers and other team members to develop, test, and implement
              a seamless user experience on a social platform meant to host 700,000 users in React Hooks
            </li>
            <li>
              Acted as the lead frontend engineer through the development of a realtime scheduling application in React.
            </li>
          </ul>
          <h2>Software Engineer Intern</h2>
          <h3>June 2018 - June 2019</h3>
        </div>
      </div>
      <div className="experience-box">
        <div className="experience-panel">
          <span role="img" aria-label="Wonderful">&#9978;</span>
          &nbsp;River Way Ranch Camp <span className="d-none d-md-inline">/ Fresno, CA.</span>
        </div>
        <div className="experience-holder">
          <h2>Age 10-12 Overnight Camp Division Leader</h2>
          <h3>June - August 2019</h3>
          <ul>
            <li>

            </li>
            <li>

            </li>
          </ul>
        </div>
      </div>
    </div>

  </WorkStyle>
);

export default Work;
