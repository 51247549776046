import styled from 'styled-components';

export default styled.div`
  z-index: 20;
  position: fixed;
  transition: 0.4s ease-in-out;
  top: -150px;
  left: 0;
  width: 100%;

  &.main-nav {
    pointer-events: none;
    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      top: 25px;
      position: sticky;
    }
  }

  [data-expanded="true"] {
    transition: 0.4s ease-in-out;

    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      width: 50vw !important;
    }

    .main-menu__more {
      @media ${(({ theme }) => theme.breakpoints.mobile)} {
        max-height: 1000px;
      }
    }
    .main-menu__option {
      @media ${(({ theme }) => theme.breakpoints.mobile)} {
        color: transparent !important;
        &:after {
          color: ${(({ theme }) => theme.defaults.text)};
        }

      }
    }
  }

  &[data-hide="true"] {
    top: -150px !important;
    pointer-events: none;
    opacity: 0;
    position: absolute;
  }

  &.down-trigger, &[data-down="true"] {
    top: 25px;
    [data-expanded="true"] {
      .main-menu__more {
        max-height: 1000px;
      }
    }
  }

  .main-logo {
    pointer-events: auto;
    font-size: 32px;
    padding: 5px;
    border: ${(({ theme }) => theme.shorthand.border)};;
    color: ${(({ theme }) => theme.defaults.text)};
    display: inline-block;

    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      position: absolute;
    }
  }

  .main-menu {
    pointer-events: auto;
    text-align: right;
    float: right;
    width: calc(15% + 4px);
    border: ${(({ theme }) => theme.shorthand.border)};;
    display: inline-block;
    a {
      color: ${(({ theme }) => theme.defaults.text)};
    }
    &__option {
      display: block;
      border: none;
      width: 100%;
      text-align: right;
      padding: 15px;
      &:first-child {
        border-bottom: ${(({ theme }) => theme.shorthand.border)};;

        @media ${(({ theme }) => theme.breakpoints.mobile)} {
          background: ${(({ theme }) => theme.defaults.text)};
        }
      }

      @media ${(({ theme }) => theme.breakpoints.mobile)} {
        color: transparent !important;
        position: relative;
        &.email-option {
          &:after {
            content: '@';
          }
        }
        &:after {
          content: '+';
          color: ${(({ theme }) => theme.defaults.text)};
          font-size: 40px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .main-menu__more {
    max-height: 0;
    overflow: hidden;
    transition: 0.4s ease-in-out;

    a {
      display: block;
      width: 100%;
      padding: 16px;
      background: ${(({ theme }) => theme.defaults.text)};
      border-bottom: 4px solid;

      &:hover {
        color: ${(({ theme }) => theme.defaults.text)};
        border-bottom: ${(({ theme }) => theme.shorthand.border)};;
      }
    }
  }
`;
