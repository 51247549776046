import React, { useState } from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';
import Outside from '../Outside/Outside';
import ContactStyle from './Contact.style';

const Contact = () => {
  const isMobile = window.innerWidth <= 767;

  const [exit, setExit] = useState(false);

  return (
    <ContactStyle>
      <Controller>
        <Scene classToggle={['.nav', 'bye-bye']} reverse={true} indicators={false}>
          <div className="contact-section" />
        </Scene>
        <Scene
          triggerHook="onLeave"
          duration="100%"
          pin
        >
          <Timeline
            wrapper={<div id="contactContainer" />}
          >
          <Tween
            from={{ transform: `scale(${isMobile ? 12 : 6})` }}
            to={{ transform: 'scale(1.0)' }}
          >
          <div className="screen-contact">
              <Outside />
          </div>
          </Tween>
          </Timeline>
        </Scene>
        <Scene classToggle={['.screen-contact-form', 'stick']} reverse={true} indicators={false}>
          <div className="contact-form-section" />
        </Scene>
        <Scene classToggle={['.made-by', 'stick']} reverse={true} indicators={false}>
          <div className="contact-form-section" />
        </Scene>
        <div className="screen-contact-form" data-exited={exit}>
          <button
            className="exit"
            onClick={() => setExit(!exit)}
          >
            <span />
          </button>
          <div className="form-links">
            <h2 className="screen-subhead">
              Wanna see more? Let's Chat!
            </h2>
            <a
              href={`mailto:sam@chami.io`}
              className="form-link"
            >
              Email Me
            </a>
            <a
              href="//www.linkedin.com/in/samchami/"
              className="form-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
            <a
              href="//github.com/samchami"
              target="_blank"
              className="form-link"
              rel="noopener noreferrer"
            >
              Github
            </a>
          </div>
        </div>
        <div className="made-by">
          Made by Sam Chami using React, GSAP, and ScrollMagic.
        </div>
      </Controller>
    </ContactStyle>
  )
};

export default Contact;
