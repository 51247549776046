import styled, { keyframes } from 'styled-components';
const spin = keyframes`
  0%{transform: rotate(0deg)}
  100%{transform: rotate(360deg)}
`;

const pulse = keyframes`
  0%{transform: scale(1.0)}
  50%{transform: scale(1.1)}
  100%{transform: scale(1.0)}
`;

const enlarge = keyframes`
  0%{transform: scale(1.0)}
  100%{transform: scale(1.7) rotate(260deg)}
`;

const bob = keyframes`
  0%{transform: translateY(2px)}
  100%{transform: scale(-2px)}
`;

const down = keyframes`
  0%{
    transform: scale(1.0);
    bottom: 100%;

  }
  100%{
    transform: scale(0.1);
    bottom: 0;
    opacity: 0;
  }
`;
const shake = keyframes`
  0%{
    transform: translateX(-3px) rotate(6deg);
  }
  33%{
    transform: translateX(3px) rotate(0deg);
  }
  66%{
    transform: translateX(3px) rotate(6deg);
  }
  100%{
    transform: translateX(0px) rotate(0deg);
  }
`;

export default styled.div`
  .experimental-logo {
    width: 100%;
    height: auto;
    display: block;

    &:hover {
      background: none;

      .big-star {
        animation: 1s ${pulse} linear infinite;
      }
      .small-star {
        animation: 1s ${spin} linear infinite;
      }
      .experimental-tooltip {
        animation: 0.3s ${shake} linear infinite;
        animation-direction: alternate-reverse;
      }
    }

    &:active {
      .experimental-tooltip {
        animation: 0.3s ${down} linear forwards;
      }
      .small-star {
        animation: 0.5s ${spin} linear infinite;
      }
      /* .big-star {
        animation: 0.3s ${enlarge} linear forwards;
      } */
    }

    svg {
      overflow: visible;
    }

    .small-star, .big-star {
      transform-origin: center;
    }
  }

  .experimental-tooltip {
    text-align: center;
    font-size: 14px;
    border: 2px solid;
    margin-bottom: 12px;
    position: absolute;
    bottom: 100%;
    animation: 0.5s ${bob} ease-in-out infinite;
    animation-direction: alternate-reverse;
  }
`;
