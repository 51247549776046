import React from 'react';

import OuterStyles from './OuterBox.style';

const Outer = ({ children, dimension, styles={}, ...rest }) => {
  return (
    <OuterStyles {...rest} style={{ width: dimension || '100%', ...styles }}>
      {children}
    </OuterStyles>
  );
};

export default Outer;
