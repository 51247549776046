import React from 'react';
import { Controller, Scene } from 'react-scrollmagic';
import { Tween, Timeline } from 'react-gsap';

import ProjectsStyle from './Projects.style';

const Projects = () => (
  <ProjectsStyle>
    <Controller>
      <Scene
        triggerHook="onLeave"
        pin
      >
        <div className="projects-overlay">
          <div className="projects-title">
          <span className="sliding slide-blur">
            <div>PROJECTS - PROJECTS - PROJECTS - PROJECTS - PROJECTS - PROJECTS - PROJECTS - PROJECTS - PROJECTS -</div>
          </span>
            <div className="wrapper">
              <span className="sliding">
              <div>PROJECTS - PROJECTS - PROJECTS - PROJECTS - PROJECTS - PROJECTS - PROJECTS - PROJECTS - PROJECTS -</div>
              </span>
            </div>
          </div>
        </div>
      </Scene>
      <div className="projects-bash">
        <li>Sams-Portfolio:~ sam$ cat description.txt</li>
        <br />
        <br />
        <li>Projects:</li>
        <li>-CoOp: React Native, Redux, Express, Socket.io, PostgreSQL</li>
        <li>-alula: ohm (Language design inspired by Python)</li>
        <li>-Tongue and Cheek: Unity</li>
        <li>-CookieJar: PostgreSQL, Adobe XD, React Native, AWS, Project Management</li>
        <br />
        <br />
        <li>Sams-Portfolio:~ sam$ cd projects/</li>
        <li>Sams-Portfolio:projects sam$ nano config.json</li>
        <li>Sams-Portfolio:projects sam$ yarn install</li>
        <li>yarn install v1.19.2</li>
        <li>[1/4] <span aria-label="search" role="img">🔍</span>  Resolving packages...</li>
        <li>[2/4] <span aria-label="bus" role="img">🚚</span>  Fetching packages...</li>
        <li>[3/4] <span aria-label="chain" role="img">🔗</span>  Linking dependencies...</li>
        <li>[4/4] <span aria-label="hammer" role="img">🔨</span>  Building fresh packages...</li>
        <li><span aria-label="stars" role="img">✨</span>  Done in 5.93s.</li>
        <li>Sams-Portfolio:projects sam$ yarn runProjects </li>
        <li>yarn run v1.19.2</li>
        <li>$ react-scripts start</li>
        <li>Running on server... samchami.com</li>
        <li>[WARNING]: Spent too much time figuring out something nifty to put here. Consider spending time elsewhere</li>
        <li>[WARNING]: Like literally anything else</li>
        <li>[WARNING]: Little Bo Peep lost her sheep.</li>
        <li>Compiled with warnings</li>
      </div>
      <Scene
        triggerHook="onLeave"
        duration="100%"
        pin
      >
        <Timeline
          wrapper={<div id="pinContainer" />}
        >
          <Tween
            from={{ opacity: '0', pointerEvents: 'none' }}
            to={{ opacity: '1.5', pointerEvents: 'auto' }}
          >
            {ProjectBox(
              'Co Op',
              ([
                <p>
                  Trying to stay organized? CoOp is an all-in-one task management messaging application. Do you and your roommates want to keep better track of chores? Inspired by apps like GroupMe, use CoOp to create group chats with the added benefit of a built in task management system.
                </p>,
                <ul>
                  <li>Built using React Native, Redux, Socket.io, Express, and Postgres</li>
                  <li>Served as lead developer</li>
                </ul>
              ]),
              '../../assets/coop.png',
              '#cde4ef',
              '//github.com/johns/coop',
              'View Repo'
            )}
          </Tween>
          <Tween
            from={{ opacity: '0', pointerEvents: 'none' }}
            to={{ opacity: '1.5', pointerEvents: 'auto' }}
          >
            {ProjectBox(
              'CookieJar',
              'CookieJar is an habit-first map and navigation and discovery application. This lightweight mobile application focuses on simplicty, location trends, and user\'s individual interests, delivering a more user-tailored experience than a traditional mapping application.',
              '../../assets/cookiejar.png',
              '#f15c3c'
            )}
          </Tween>
          <Tween
            from={{ opacity: '0', pointerEvents: 'none' }}
            to={{ opacity: '1.5', pointerEvents: 'auto' }}
          >
            {ProjectBox(
              'alula',
              'Lim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
              '../../assets/alula.png',
              'white'
            )}
          </Tween>
          <Tween
            from={{ opacity: '0', pointerEvents: 'none' }}
            to={{ opacity: '1.5', pointerEvents: 'auto' }}
          >
            {ProjectBox(
              'Tongue and Cheek',
              'Lorem doloagna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
              '../../assets/tonguecheek.png',
              '#f17c1f'
            )}
          </Tween>
        </Timeline>
      </Scene>
    </Controller>
  </ProjectsStyle>
);

function ProjectBox(name, description, image, color, url, urlCopy) {
  return (
    <div className="pin-box">
      <div className="pin-holder">
        <div className="project-image">
          <img src={image} alt={name} />
        </div>
        <div className="pin-content">
          <div className="project-description">
            {description}
          </div>
        </div>
        {url && (
          <a
            href={url}
            className="project-url"
            target="_blank"
            rel="noopener noreferrer"
          >
            {urlCopy}
          </a>
        )}
      </div>
    </div>
  );
}


export default Projects;
