import styled from 'styled-components';

export default styled.div`
  position: relative;
  overflow: hidden;
  width: 100vw;
  overflow: hidden;


  .work-holder {
    padding: 100px 40px;
    position: relative;
    height: 100%;
    z-index: 0;
    width: 100%;
    top: 0;
    left: 0;
    background-attachment: fixed;
    background-size: cover;

  }

  .experience-box {
    display: inline-block;
    position: relative;
    height: auto;
    background: ${(({ theme }) => theme.colors.windowsTan)};
    border: 2px solid ${(({ theme }) => theme.colors.xpBlue)};
    border-radius: 2px;
    margin-bottom: 40px;

    vertical-align: top;

    .experience-panel {
      background: ${(({ theme }) => theme.colors.xpBlueGradient)};
      height: 40px;
      width: calc(100% + 4px);
      position: absolute;
      top: -40px;
      left: -2px;
      border-radius: 8px 8px 0 0;
      color: ${(({ theme }) => theme.colors.white)};
      line-height: 40px;
      padding: 0 10px;
      font-weight: bold;

    }

    .experience-holder {
      overflow: auto;
      width: 100%;
      height: calc(100% - 20px);
      padding: 10px;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
      background: white;

      h2 {
        margin: 0;
      }

      h3 {
        margin: 0;
      }
    }

    &:first-child {
      width: 100%;
    }

    &:last-child {
      width: 67%;
      margin-top: 30px;
      margin-left: 50px;

      @media ${(({ theme }) => theme.breakpoints.mobile)} {
        width: 100%;
        margin-left: 0;
      }
    }
  }
`;
