import React, { useState, useEffect} from 'react';
import {
  useHistory,
} from "react-router-dom";

import CryptoJS from 'crypto-js';
import ViewStyles from './Views.style';
import FriendAppreciation from '../components/FriendAppreciation/FriendAppreciation';

export default () => {
  const [userAttempt, editUser] = useState('');
  const [passAttempt, editPass] = useState('');
  const [appreciation, toggleAppreciation] = useState(false);
  const [friendData, editFriendData] = useState(false);


  const showAppreciation = () => {
    toggleAppreciation(true);

    setTimeout(() => {
      toggleAppreciation(false);
    }, 2000);
  }

  useEffect(() => {
    const passwords = JSON.parse(process.env.REACT_APP_FRIENDSHIP_PASSWORDS);

    if (userAttempt in passwords) {
      const passwordToCheckAgainst = passwords[userAttempt];
      var encrypted = CryptoJS.AES.encrypt(process.env.REACT_APP_FRIENDSHIP_CREDENTIALS, passwordToCheckAgainst.password);

      try {
        var decrypted = CryptoJS.AES.decrypt(encrypted, passAttempt);
        const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);

        if (decryptedString) {
          setTimeout(() => {
            const decryptedJSON = JSON.parse(decryptedString)

            editFriendData({...decryptedJSON, activeSince: passwordToCheckAgainst.active });
          }, 300);
        }
      } catch (e) {
        console.log("WRONG")
      }
    }
  }, [passAttempt, userAttempt]);


  return (
    <div data-password>
      {!friendData ? (
        <div className="password-box">
          <input placeholder="First and Last Name" className="username" value={userAttempt} onChange={(e) => editUser(e.target.value)}/>
          <input placeholder="🗝" className="password" value={passAttempt} onChange={(e) => editPass(e.target.value)}/>
        </div>
      ) : (
        <div>
          {appreciation && ( <FriendAppreciation /> )}
          <div className="relative p-5 mb-4 bg-light rounded-3">
            <div className="container-fluid py-5">
              <h1 className="display-5 fw-bold">Welcome! Active Since {friendData.activeSince}</h1>
              <div className="row">
                <div className="col-md-2" />
                <p className="col-md-8">
                  Thanks for signing up for this general friendship. Please enjoy the following perks at no extra cost.
                </p>
                <div className="col-md-2" />
              </div>
              <button
                onClick={showAppreciation}
                className="btn btn-primary btn-lg" type="button"
                disabled={appreciation}
              >
                {appreciation ? 'Delivering.....' : 'Click to Show Appreciation'}
              </button>
            </div>
            <div className="row align-items-md-stretch">
              {friendData.perks.map((p) => (
                <div className="col-12 col-md-6" key={p.service}>
                  <div className="h-100 p-5 bg-light border rounded-3">
                    <h2>{p.service}</h2>
                    <p>Email: {p.username}</p>
                    <p>Password: {p.password}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
};
