import React, { useState, useEffect, useRef } from 'react';
import Outside from './Outside.style';

// Hook
function useOnScreen(ref, rootMargin = '0px') {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.unobserve(ref.current);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
}

export default ({ ...rest }) => {
  const isMobile = window.innerWidth <= 767;
  const ref = useRef();
  const onScreen = useOnScreen(ref, '-300px');
  // x="calc(50% - (100vw / 6) / 2 - 10)"
  // y="calc(50% - (100vh / 6) / 2 - 10)"
  // width="calc((100vw / 6) + 20)"
  // height="calc((100vh / 6) + 20)"

  // const width = window.innerWidth;
  // const height = window.innerHeight;

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const updateWidth = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
      return true;
    }

    window.addEventListener('resize', updateWidth);
    return () => {
        window.removeEventListener('resize', updateWidth);
    }
  }, []);

  return (
    <Outside>
      <span className="ref" ref={ref} />
      {!onScreen && !isMobile ? <span /> : (
      <svg version="1.1" preserveAspectRatio xmlns="http://www.w3.org/2000/svg" x="100%" y="100%" viewBox="0 0 1000 1000">
      <g id="mtns">
      	<rect x="-25.833" y="21" fill="#75D0EC" width="1069.833" height="302"/>
      	<rect id="water" x="-20" y="311" fill="#487FC1" width="1064" height="300"/>
      	<g id="sand">
      		<path fill="#FFD28A" d="M-52.816,574.897c24-13-13.333-53.127,15.667-59.127c30-7,59.153,18.668,102,18.668
      			c49.244,0,35,23.332,85,11.332c25-6,113,4,138-5c24-9,78.333-8.873,103.333-14.873c48-13,101.313,7.211,150,13
      			c57.809,6.873,155.603,40.224,255.666,6.873c34-11.332,132.103,1.066,152.764-17.103C988.614,494.371,993,423,1018,420
      			c27-4,30-86,57-76c16,6-9.815,204.897,3.185,215.897c10,8,30,14,36,25c12,20-10,58-14,78c-8,31-22,45-53,53c-48,11-96,15-144,20
      			c-95,10-192,4-287,1c-96-3-193-7-290-12c-63-3-126-4-189-4c-39,0-77-3-115-3c-22,0-57,7-69-12c-18-30-18-97-9-130L-52.816,574.897
      			z"/>
      	</g>
      	<g id="mtn3">
      		<path fill="#3C2415" d="M-19.694,314.428C-3.607,303.581-9.914,273.415-13,257c-1.912-7.494,10.733-11.286,15.155-19.846
      			c3.105-6.71,8.64-12.526,14.019-17.338c7.432-8.089,18.878-15.55,21.184-27.011l0,0l0,0c24.647-6.425,28.503-4.793,45.794,12.815
      			c18.137,18.769,34.491,9.505,56.988,13.538c7.447,1.678,12.416,6.053,19.706,8.735c9.297,2.996,15.835-2.663,26.026-2.097
      			c7.181,0.095,47.101,35.077,50.801,37.711L-19.694,314.428z"/>
      	</g>
      	<g id="mtn2">
      		<path fill="#754C29" d="M107.117,326.255c2.054-31.929,8.258-48.114,38.419-56.731c7.181-1.436,17.235,0,23.698-5.026
      			c5.745-5.027,12.498-17.235,17.525-21.544c10.772-8.617,0.428-46.377,16.227-43.086c9.247,1.925,30.929,11.482,32.315,20.825
      			c1.49,10.046,21.69,32.913,28.725,41.65c5.135,6.377,11.597,9.669,14.362,17.606c2.241,6.434,14.173,9.786,18.671,14.644
      			c5.521,5.961,5.336,22.326,10.363,29.508c5.745,8.617,2.933,9.357,0,17.234c-5.63,15.124-26.172,0.557-29.098,15.746
      			c-1.664,8.638-16.336-3.097-25.07-4.144c-14.903-1.786-41.783,1.235-56.731-0.112C176.12,350.986,105.802,346.697,107.117,326.255
      			z"/>
      	</g>
      	<g id="mtn1">
      		<path fill="#8B5E3C" d="M188.559,363.379c-2.135-10.583-19.491-27.906-27.288-35.906c-13.827-14.187-18.671-25.852-22.98-42.369
      			c-5.026-17.234-20.938-13.644-33.145-23.698c-10.772-8.617-19.07-24.397-28.613-30.161c-13.868-8.376-35.188,17.953-48.113,28.006
      			c-12.926,10.054-25.421,11.868-22.374,28.725c2.355,13.03-23.466,23.05-29.21,31.667c-5.745,10.053-7.716,17.165-17.77,22.909
      			c-15.798,10.054-12.623,22.98,8.92,40.933c0,0,43.656,8.229,58.279,7.002c15.685-1.316,81.942,1.707,108.436-4.129
      			C148.997,383.21,191.453,377.729,188.559,363.379z"/>
      	</g>
      </g>
      {!isMobile && (
        <g id="palm-front">
        	<g className="leaf-1">
        		<path fill="#0E783D" d="M259.162,375.032c-11.75-4.014-25.018,4.869-24.044,12.822c1.809,18.288-2.602,25.358,10.62,36.869
        			c5.802,2.672,23.271,8.439,29.529,10.784c5.501,1.996,11.609,4.003,17.218,4.438c-11.101,7.51-25.744,3.452-36.069-5.331
        			c0.423,7.713,14.754,13.775,21.313,16.796c4.036,1.858,8.528,3.391,12.87,4.583c4.09,1.078,10.155,1.186,13.633,3.594
        			c-8.304,3.426-15.954,7.421-26.002,4.674c-8.432-2.271-15.441-6.306-20.518-13.208c-0.646,9.37,14.113,21.805,22.18,26.863
        			c10.03,6.768,20.766,14.666,32.934,18.121c-9.243-1.839-20.956-5.623-30.391-9.699c-4.794-2.208-9.689-4.193-13.719-7.392
        			c-4.282-3.314-6.831-10.396-11.372-12.486c-0.377,5.465,1.973,11.649,3.417,17.148c1.49,6.056,1.763,13.433,4.822,19.407
        			c5.367,10.26,14.798,15.676,25.444,19.773c-12.473-2.791-24.325-9.592-33.845-17.467c-5.238-4.56-9.767-9.332-13.637-14.873
        			c-2.865-3.736-8.031-11.602-11.456-14.79c-4.004,2.721,4.316,17.679,4.45,22.036c-0.023,5.361-1.007,10.816,0.689,16.429
        			c3.336,12.01,13.626,17.555,22.943,25.875c-18.546-1.561-40.933-15.359-46.271-32.318c-2.597-7.642-0.454-12.294,0.132-19.543
        			c0.7-10.151-11.272-0.089-14.417,2.759c-5.278,4.82-10.357,10.537-16.691,14.333c-8.057,4.883-6.266,11.615-3.975,19.922
        			c-19.898-4.598-11.333-21.867-6.13-33.167c3.572-7.755,8.5-13.81,16.252-18.028c6.13-3.353,13.006-3.678,19.636-5.458
        			c-6.859-3.696-15.195-4.849-22.618-6.656c-4.444-0.973-12.384-0.329-21.402-8.716c-2.064-1.918-11.965-14.437-11.965-14.437
        			l5.502,1.997c9.934,5.649,15.484,8.205,27.218,6.627c9.002-0.957,16.281-0.828,25.218,1.676
        			c-8.367-5.733-19.988-7.056-29.118-11.798c-4.187-2.196-8.571-5.29-12.35-8.372c-1.964-1.71-4.937-3.885-6.445-5.923
        			c-1.958-3.05-1.191-5.381-2.542-8.421c12.272,14.514,26.1,20.344,45.053,21.016c-15.184-7.529-32.333-16.769-34.836-34.57
        			c12.644,10.388,23.432,17.503,39.444,20.581c-7.364-3.928-11.791-8.921-15.805-16.141c-3.714-6.544-8.436-13.552-9.771-20.611
        			c1.046,3.704,7.395,8.508,10.819,11.695c4.734,4.33,8.351,9.754,13.289,13.639c5.087,4.222,11.291,5.74,17.647,8.341
        			c7.177,2.937,2.107-13.713,5.918-17.589c5.473-5.565-1.563-4.98-0.246-12.52c1.688-9.668,5.311-17.494,16.014-20.137
        			L259.162,375.032z"/>
        		<g>
        			<path fill="#91C83E" d="M260.491,370.211c-11.439-3.376-25.684,1.494-29.923,13.315c-0.848,2.364-0.373,5.025-0.19,7.442
        				c0.234,3.106,0.268,6.227,0.312,9.341c0.069,4.889,0.197,9.85,1.769,14.526c1.641,4.883,4.943,8.916,8.623,12.418
        				c3.652,3.477,9.527,4.804,14.181,6.478c12.007,4.319,24.474,9.891,37.224,11.213c-0.841-3.106-1.683-6.211-2.523-9.317
        				c-9.875,6.213-21.561,2.377-30.01-4.549c-2.899-2.376-9.148-1.302-8.536,3.536c1.205,9.52,10.147,14.544,18.023,18.432
        				c4.839,2.389,9.807,4.541,14.914,6.285c5.47,1.868,12.392,1.656,17.355,4.574c0.398-3.046,0.796-6.092,1.194-9.139
        				c-6.892,2.876-13.918,6.376-21.595,5.09c-7.381-1.236-14.707-5.394-19.279-11.326c-2.874-3.729-9.247-2.881-9.317,2.523
        				c-0.155,11.902,11.422,21.418,20,27.959c11.601,8.846,24.733,17.604,38.785,21.846c0.886-3.214,1.772-6.428,2.658-9.643
        				c-11.146-2.308-22.037-5.955-32.397-10.65c-5.024-2.277-9.804-4.583-13.109-9.141c-2.557-3.526-4.889-7.105-8.782-9.282
        				c-3.346-1.871-7.443,0.497-7.523,4.317c-0.146,6.971,2.426,13.325,3.945,20.018c1.598,7.04,2.143,13.922,5.699,20.372
        				c5.841,10.594,16.873,16.508,27.71,20.76c0.886-3.213,1.772-6.428,2.658-9.643c-13.344-3.176-25.498-10.26-35.435-19.648
        				c-8.271-7.814-13.255-18.26-21.296-26.194c-2.017-1.99-5.035-1.829-7.071,0c-4.041,3.63-2.386,9.75-0.971,14.207
        				c0.887,2.791,2,5.508,2.986,8.264c1.121,3.134,0.937,5.015,0.762,8.495c-0.435,8.664,1.479,16.746,6.859,23.658
        				c5.153,6.621,12.194,11.301,18.446,16.787c1.179-2.846,2.357-5.691,3.536-8.535c-13.531-1.338-26.676-8.5-35.641-18.611
        				c-4.784-5.396-7.357-12.123-6.685-19.324c0.483-5.173,3.547-13.772-2.307-17.205c-8.837-5.181-20.041,8.019-25.543,13.301
        				c-2.993,2.873-6.081,5.534-9.595,7.752c-2.923,1.844-5.389,4.149-6.915,7.284c-2.818,5.789-0.962,12.436,0.642,18.283
        				c2.05-2.049,4.1-4.1,6.15-6.15c-4.649-1.189-8.667-3.041-8.829-8.309c-0.146-4.77,2.152-9.709,4.057-13.962
        				c2.776-6.202,5.807-11.804,10.955-16.372c6.576-5.833,15.443-6.325,23.574-8.368c4.055-1.019,5.088-7.169,1.194-9.139
        				c-7.035-3.558-14.578-5.067-22.208-6.782c-5.072-1.14-10.214-1.303-14.96-3.651c-7.554-3.738-12.714-12.164-17.805-18.595
        				c-1.622,2.786-3.243,5.571-4.865,8.357c7.537,2.735,14.146,7.512,22.219,8.806c5.731,0.918,11.324-0.062,17.052-0.438
        				c6.363-0.417,12.517,0.296,18.667,1.931c5.609,1.491,8.561-6.09,3.853-9.139c-8.512-5.511-18.61-6.994-27.73-11.112
        				c-4.587-2.07-8.817-5.144-12.725-8.276c-1.93-1.547-3.826-3.152-5.581-4.895c-1.511-1.502-1.425-5.284-2.122-7.243
        				c-2.786,1.622-5.571,3.243-8.357,4.865c12.756,14.782,29.274,21.643,48.588,22.48c4.863,0.211,6.982-7.1,2.523-9.317
        				c-13.768-6.848-29.795-14.872-32.538-31.582c-2.786,1.622-5.571,3.243-8.357,4.865c12.532,10.232,25.58,18.603,41.65,21.866
        				c5.373,1.091,9.023-6.262,3.853-9.139c-5.76-3.204-9.856-7.395-13.253-13.019c-3.937-6.518-8.336-13.242-10.025-20.745
        				c-3.214,0.886-6.428,1.772-9.643,2.658c2.159,5.571,6.827,9.222,11.193,13.072c5.506,4.856,9.659,10.954,15.496,15.47
        				c5.465,4.227,12.226,6.109,18.559,8.625c4.5,1.788,8.154-0.993,9.795-5.017c1.406-3.446,0.712-8.063,0.867-11.698
        				c0.031-0.734,0.104-1.306,0.255-2.018c0.326-0.887,0.281-0.935-0.135-0.143c0.608-0.619,1.163-1.281,1.667-1.987
        				c1.443-1.976,2.254-4.271,1.722-6.729c-0.324-1.499-1.122-2.768-1.911-4.056c-1.177-1.922,0.169-5.044,0.705-7.025
        				c1.639-6.057,5.337-9.901,11.378-11.574c-1.284-0.168-2.568-0.336-3.853-0.504c3.811,2.285,7.623,4.57,11.434,6.855
        				c5.545,3.325,10.57-5.324,5.047-8.635c-2.988-1.792-5.976-3.583-8.964-5.375c-4.897-2.936-8.835-1.762-13.777,1.085
        				c-9.102,5.245-16.061,21.307-10.226,30.838c1.22,1.992-0.241,0.512,0.432-0.78c-0.528,1.014-1.614,1.816-2.195,2.841
        				c-1.219,2.148-1.415,4.802-1.579,7.205c-0.148,2.179-0.07,4.374-0.143,6.556c-0.03,0.89-0.128,1.641-0.367,2.483
        				c3.534-0.884,4.369-1.54,2.505-1.967c-1.047-0.426-2.101-0.835-3.161-1.228c-3.672-1.393-7.267-2.697-10.66-4.706
        				c-3.703-2.192-6.585-5.788-9.397-8.969c-2.346-2.654-4.773-5.164-7.43-7.507c-2.391-2.109-6.421-4.692-7.616-7.773
        				c-2.296-5.925-11.083-3.741-9.643,2.658c1.987,8.828,7.181,16.755,11.805,24.411c3.937,6.518,9.438,11.64,16.069,15.329
        				c1.284-3.046,2.568-6.093,3.853-9.139c-14.367-2.918-26.007-10.125-37.237-19.295c-4-3.266-9.188-0.195-8.357,4.865
        				c3.175,19.346,21.079,29.574,37.134,37.559c0.841-3.106,1.682-6.211,2.523-9.317c-16.814-0.729-30.461-6.738-41.517-19.551
        				c-3.229-3.742-10.013,0.205-8.357,4.865c1.965,5.529,2.216,9.552,6.88,13.712c4.32,3.854,9.027,7.358,13.944,10.405
        				c9.936,6.157,22.131,7.042,31.93,13.385c1.284-3.046,2.568-6.093,3.853-9.139c-5.911-1.571-11.876-2.392-17.994-2.402
        				c-5.943-0.009-11.782,1.281-17.729,0.879c-5.043-0.34-9.75-3.063-14.06-5.452c-2.587-1.435-5.377-2.317-8.155-3.325
        				c-5.24-1.902-7.621,4.875-4.865,8.357c5.584,7.055,10.808,14.523,18.507,19.415c4.72,2.998,10.121,4.01,15.55,4.759
        				c7.566,1.043,16.114,2.966,22.941,6.418c0.398-3.046,0.796-6.093,1.194-9.139c-10.365,2.604-19.448,3.984-27.987,10.939
        				c-6.691,5.451-10.443,13.475-13.759,21.226c-2.787,6.515-4.857,13.095-3.756,20.232c1.209,7.836,8.778,12.117,15.745,13.898
        				c3.675,0.941,7.172-2.424,6.15-6.15c-1.419-5.176-3.626-10.758,1.677-14.273c6.094-4.041,11.204-8.97,16.488-13.989
        				c2.652-2.519,5.768-5.476,9.256-6.745c-1.341,0.488-2.097-3.834-2.159-0.676c-0.032,1.593-0.379,3.216-0.621,4.788
        				c-0.509,3.303-0.891,6.531-0.629,9.873c1.901,24.281,30.833,40.068,52.388,42.201c4.312,0.426,6.672-5.783,3.536-8.535
        				c-10.421-9.146-22.647-16.043-22.448-31.561c0.071-5.603,0.179-9.655-1.75-14.905c-0.9-2.45-1.826-4.885-2.616-7.374
        				c-0.311-0.979-0.551-1.973-0.776-2.973c-0.335-1.496,0.435-1.29-0.492-0.457c-2.357,0-4.714,0-7.071,0
        				c3.9,3.848,6.842,8.582,10.071,12.979c4.142,5.639,8.551,10.814,13.727,15.539c10.403,9.498,23.651,16.637,37.347,19.896
        				c6.401,1.525,8.549-7.33,2.658-9.643c-9.229-3.621-17.549-8.574-22.456-17.475c-2.698-4.894-2.906-11.287-3.968-16.672
        				c-1.157-5.865-3.715-11.277-3.587-17.359c-2.508,1.439-5.016,2.878-7.523,4.317c3.699,2.068,5.803,6.762,8.493,9.939
        				c3.758,4.438,9.846,6.875,14.998,9.209c10.604,4.806,21.78,8.572,33.186,10.933c6.281,1.301,8.87-7.768,2.658-9.643
        				c-12.421-3.75-22.832-11.664-33.497-18.766c-6.39-4.254-18.059-12.772-17.946-21.397c-3.106,0.841-6.211,1.683-9.317,2.523
        				c5.776,7.496,13.031,12.233,22.033,15.086c10.724,3.399,20.255-0.132,30.133-4.255c3.794-1.583,5.282-6.735,1.194-9.139
        				c-3.772-2.218-7.922-2.831-12.173-3.541c-5.199-0.869-10.38-2.827-15.153-5.013c-3.566-1.634-7.1-3.3-10.502-5.259
        				c-2.218-1.276-7.139-3.904-7.511-6.843c-2.845,1.179-5.69,2.357-8.536,3.536c11.862,9.724,28.218,14.865,42.128,6.113
        				c4.466-2.81,2.293-8.818-2.523-9.317c-9.292-0.964-18.295-5.151-27.068-8.212c-7.684-2.681-16.563-4.25-21.494-11.215
        				c-4.353-6.147-3.121-14.554-3.386-21.649c-0.171-4.583-1.345-7.674,2.198-11.088c3.926-3.784,10.127-4.394,15.098-2.926
        				C264.033,381.684,266.662,372.032,260.491,370.211z"/>
        		</g>
        	</g>
        	<g>
        		<path fill="#3D2A17" d="M203.757,722.113c0-89.917,3.376-189.823,13.504-279.739c3.375-25.976-1.688-61.942,20.255-75.929
        			c33.759-21.98,30.383,21.979,30.383,47.955c0,43.959-5.064,83.921-3.376,125.883c1.688,61.941-1.688,123.884,5.064,183.828
        			L203.757,722.113z"/>
        	</g>
        	<g>
        		<path fill="#4C331E" d="M212.679,391.041c3.5,13-1,19.5-5,32c-4,14-5.5,29-8,43.5c-2.5,12,0,25-1,38s-1.5,24.5,8,35.5
        			c8.5,9.5,59.5,21.5,66,5.5c4.5-12,3-30,5-43.5c1.5-11.5,2.5-23.5,2.5-35.5c0-14-2-27.5-2.5-42c-0.5-11,2.5-23.5,0.5-34.5
        			c-3.5-20-17-13-32.5-14c-8-0.5-29.5-5.5-36-1c-8,5.5,2,6,2,12.5L212.679,391.041z"/>
        	</g>
        	<g className="leaf-2">
        		<path fill="#0E783D" d="M246.667,377.962c-7.333-13-28.365-15.028-35-6.766c-15.667,18.667-26.571,21.874-24.967,43.099
        			c2.965,7.07,14.058,26.208,17.767,33.328c3.322,6.197,7.209,12.88,12.118,17.689c-17.67-1.625-27.707-16.935-29.155-33.316
        			c-6.931,7.606,0.904,24.562,4.256,32.554c2.063,4.917,4.869,9.886,7.854,14.417c2.856,4.224,8.508,9.082,9.52,14.08
        			c-11.135-3.28-22.192-5.512-29.114-15.982c-5.841-8.755-8.658-18.057-6.914-28.548c-9.519,8.33-7.336,31.632-4.489,42.729
        			c3.084,14.248,5.763,30.116,14.023,42.917c-7.021-8.982-14.539-21.736-19.616-32.98c-2.45-5.842-5.207-11.553-5.991-17.73
        			c-0.913-6.484,3.4-15.162,1.08-20.694c-5.553,4.859-9.201,12.534-13.058,18.852c-4.343,6.881-11.096,14.051-13.871,22.084
        			c-4.66,13.884-0.861,26.388,5.347,38.603c-9.183-12.414-13.964-28.121-15.511-43.015c-0.635-8.409-0.398-16.461,1.198-24.721
        			c0.833-5.771,3.407-17.24,3.189-22.932c-6.386-0.575-12.708,20.057-16.724,24.271c-5.116,5.038-11.234,9.411-14.962,16.035
        			c-8.249,13.942-3.756,27.242-2.823,42.393c-16.115-16.014-24.238-46.585-13.183-66.765c4.799-9.242,11.254-11.95,18.701-18.327
        			c10.313-9.024-10.611-8.923-16.302-8.705c-9.589,0.407-19.842,1.816-29.461,0.429c-12.286-1.715-16.986,6.04-22.707,15.668
        			c-14.508-19.94,10.033-29.511,25.709-36.086c10.759-4.512,21.19-6.357,32.555-4.256c9.004,1.646,15.836,6.732,23.819,10.251
        			c-2.996-8.865-9.809-16.489-15.134-24.016C91.529,398.12,83.384,392.5,82.8,377.519c-0.134-3.429,2.37-22.999,2.37-22.999
        			l3.322,6.197c4.056,13.119,6.893,19.881,19.526,27.595c9.45,6.158,16.233,11.988,22.333,21.358
        			c-2.49-11.969-12.258-22.33-16.414-33.962c-1.884-5.354-3.104-11.71-3.759-17.581c-0.238-3.153-0.992-7.536-0.487-10.64
        			c1.042-4.413,3.986-6.009,5.592-9.936c-2.15,23.313,5.427,39.654,22.771,55.151c-7.25-19.008-14.738-41.17-0.192-59.922
        			c2.123,19.713,5.595,34.884,17.862,50.342c-3.253-9.479-2.708-17.66,0.345-27.616c2.697-9.083,4.877-19.396,10.321-27.102
        			c-2.528,4.313-1.07,13.823-0.852,19.515c0.377,7.794-1.348,15.747-0.355,23.283c0.814,7.972,5.257,14.268,8.816,21.706
        			c4.018,8.398,15.034-11.28,22.334-11.947c10.482-0.957,3.25-5.921,11.667-12c10.792-7.794,21.667-12.333,34.333-6.432
        			L246.667,377.962z"/>
        		<g>
        			<path fill="#91C83E" d="M250.984,375.439c-6.834-11.3-21.242-16.412-33.883-13.193c-7.081,1.803-10.929,7.861-15.672,12.885
        				c-5.142,5.446-10.748,10.489-14.909,16.773c-3.786,5.719-4.994,12.596-4.969,19.339c0.024,6.412,3.96,12.128,6.929,17.632
        				c7.216,13.376,13.882,29.027,24.568,39.973c1.179-2.845,2.357-5.69,3.536-8.536c-14.436-1.646-22.766-14.795-24.155-28.316
        				c-0.391-3.798-5.471-7.312-8.536-3.536c-7.464,9.197-3.434,21.577,0.485,31.469c2.298,5.802,4.824,11.489,7.939,16.902
        				c1.83,3.18,3.901,6.126,6.236,8.95c2.198,2.658,4.741,5.191,5.684,8.594c2.05-2.05,4.1-4.1,6.15-6.15
        				c-8.563-2.493-17.515-4.442-23.997-10.999c-6.777-6.855-9.893-18-8.538-27.38c0.686-4.747-4.429-8.581-8.357-4.865
        				c-10.403,9.843-9.079,27.513-7.211,40.389c2.505,17.27,6.649,36.387,15.964,51.316c2.878-1.684,5.756-3.365,8.635-5.047
        				c-8.116-10.539-15.097-22.318-20.29-34.57c-2.62-6.181-5.5-12.455-4.423-19.306c0.856-5.442,2.485-10.906,0.689-16.335
        				c-1.198-3.623-5.52-4.84-8.357-2.207c-5.254,4.879-8.75,11.129-12.303,17.274c-4.068,7.036-9.561,13.105-13.469,20.242
        				c-8.512,15.544-4.018,33.192,3.409,48.081c2.878-1.684,5.756-3.365,8.635-5.047c-9.267-12.865-14.249-28.563-15.058-44.35
        				c-0.766-14.94,4.783-29.025,4.616-43.795c-0.031-2.707-2.251-5.024-5-5c-6.393,0.057-10.068,6.348-12.775,11.304
        				c-3.492,6.392-5.568,12.763-10.878,17.608c-8.146,7.433-14.73,15.14-17.064,26.236c-2.261,10.743,0.451,21.8,1.208,32.552
        				c2.845-1.18,5.69-2.357,8.536-3.535c-12.262-12.518-19.038-31.852-16.227-49.283c1.495-9.271,6.22-16.772,13.641-22.424
        				c5.234-3.986,13.429-8.323,12.12-16.043c-0.97-5.72-9.125-6.729-13.589-7.244c-7.052-0.813-14.224-0.046-21.264,0.413
        				c-5.559,0.363-11.098,0.489-16.651-0.023c-4.617-0.426-8.826-0.701-13.216,1.11c-7.445,3.072-11.676,10.183-15.619,16.806
        				c2.878,0,5.756,0,8.635,0c-4.003-5.764-5.197-10.968-0.012-16.274c4.729-4.838,11.635-7.687,17.727-10.351
        				c8.842-3.868,17.669-6.989,27.431-7.056c11.422-0.08,20.122,5.881,30.097,10.431c3.561,1.625,9.014-1.081,7.345-5.646
        				c-3.479-9.517-9.803-17.08-15.638-25.21c-3.323-4.631-7.549-8.091-9.644-13.586c-3.733-9.791-0.606-21.849,0.675-31.894
        				c-3.106,0.841-6.211,1.683-9.317,2.523c4.566,8.517,6.503,17.976,12.609,25.71c4.278,5.42,10.547,8.763,16.113,12.638
        				c6.532,4.548,12.047,10.188,16.459,16.802c3.142,4.71,10.457,1.78,9.139-3.853c-2.817-12.039-11.178-21.31-15.866-32.515
        				c-2.492-5.957-3.678-12.721-4.257-19.118c-0.271-2.99-1.269-6.712,0.046-9.559c1.169-2.531,3.285-4.531,4.505-7.074
        				c-3.106-0.841-6.211-1.682-9.317-2.523c-0.903,10.844,0.101,21.708,4.253,31.835c4.312,10.515,11.627,19.279,19.982,26.852
        				c3.403,3.084,10.26,0.144,8.357-4.865c-6.992-18.403-13.631-38.667-0.696-56.07c-3.106-0.841-6.211-1.682-9.317-2.523
        				c2.199,19.905,6.849,37.836,19.327,53.878c3.134,4.029,9.812-0.332,8.357-4.865c-2.96-9.224-1.879-17.341,0.791-26.482
        				c2.462-8.43,4.41-16.991,9.372-24.383c-2.878-1.682-5.756-3.365-8.635-5.047c-2.983,6.106-2.149,13.015-1.675,19.579
        				c0.653,9.046-1.259,18.052-0.036,27.071c1.135,8.368,5.665,15.456,9.32,22.9c2.381,4.851,7.554,5.345,11.93,3.116
        				c4.28-2.18,7.647-6.517,11.124-9.727c1.499-1.384,3.032-2.791,5.11-3.043c2.912-0.354,5.591-1.179,7.705-3.295
        				c1.416-1.418,2.011-3.435,2.472-5.322c0.83-3.394,3.968-4.963,6.732-6.75c7.702-4.979,16.319-7.347,25.055-3.564
        				c-0.766-0.996-1.532-1.992-2.298-2.988c1.444,5.144,2.889,10.288,4.333,15.432c1.741,6.199,11.39,3.563,9.643-2.658
        				c-1.095-3.901-2.191-7.802-3.286-11.703c-0.438-1.562-0.715-3.497-1.551-4.924c-2.033-3.471-8.717-4.517-12.342-4.918
        				c-8.908-0.988-17.101,2.104-24.602,6.689c-3.68,2.249-7.125,4.733-9.57,8.326c-0.725,1.065-1.16,2.432-1.557,3.65
        				c-0.193,0.593-1.154,2.432-0.194,1.781c-1.726,1.17-5.215,0.994-7.29,2.059c-2.593,1.33-4.851,3.589-6.946,5.574
        				c-2.068,1.959-4.005,4.164-6.277,5.895c0.013-0.01-1.854,0.997-1.153,0.929c1.899,1.482,2.431,1.614,1.596,0.395
        				c-0.842-1.513-1.561-3.117-2.365-4.652c-2.139-4.086-4.209-8.053-5.389-12.537c-1.366-5.191-0.609-10.97-0.247-16.245
        				c0.328-4.778,0.232-9.459-0.117-14.228c-0.26-3.556-1.121-8.458,0.509-11.794c2.831-5.794-5.028-10.421-8.635-5.047
        				c-5.412,8.063-7.752,17.548-10.379,26.771c-3.081,10.815-4.288,20.903-0.791,31.799c2.786-1.622,5.571-3.243,8.357-4.865
        				c-10.661-13.706-14.525-29.859-16.398-46.807c-0.563-5.09-6.26-6.637-9.317-2.523c-14.534,19.555-8.227,42.941-0.312,63.775
        				c2.786-1.622,5.571-3.243,8.357-4.865c-7.153-6.482-13.264-13.63-17.382-22.42c-4.245-9.061-4.742-19.392-3.925-29.195
        				c0.407-4.889-7.2-6.938-9.317-2.523c-1.227,2.558-3.046,4.659-4.505,7.074c-1.816,3.007-2.018,6.477-1.866,9.883
        				c0.325,7.283,1.568,14.707,3.469,21.731c3.639,13.451,14.294,23.731,17.465,37.283c3.046-1.284,6.092-2.568,9.139-3.853
        				c-3.979-5.964-8.58-11.259-14.087-15.859c-5.642-4.713-12.375-8.014-17.714-13.061c-4.25-4.018-6.448-9.4-8.279-14.833
        				c-1.384-4.106-3.059-7.588-5.102-11.398c-2.601-4.851-8.744-1.969-9.317,2.523c-1.402,10.989-3.816,22.418-0.732,33.305
        				c1.777,6.273,5.728,11.345,10.029,16.086c6.102,6.725,12.539,15.4,15.667,23.957c2.448-1.882,4.896-3.764,7.345-5.646
        				c-5.387-2.458-10.476-5.502-15.913-7.854c-6.14-2.655-12.566-3.759-19.231-3.943c-11.879-0.329-22.891,4.228-33.553,8.892
        				c-7.848,3.433-16.31,7.646-21.491,14.731c-5.858,8.011-4.123,17.352,1.263,25.105c2.107,3.034,6.604,3.411,8.635,0
        				c3.31-5.559,6.883-12.782,14.121-13.406c4.388-0.378,9.012,0.759,13.433,0.811c5.56,0.065,11.129-0.478,16.672-0.833
        				c5.015-0.322,10.231-0.554,15.211,0.264c1.054,0.173,2.079,0.454,3.092,0.787c1.059,0.348-0.972-1.855-0.441-2.533
        				c-2.486,3.17-6.524,5.371-9.669,7.834c-26.946,21.099-18.878,63.781,2.681,85.787c2.908,2.969,8.848,0.904,8.536-3.535
        				c-0.622-8.824-2.364-17.576-1.968-26.449c0.458-10.245,6.502-17.646,13.737-24.219c4.933-4.481,7.649-8.587,10.521-14.537
        				c0.998-2.067,5.291-12.477,7.219-12.494c-1.667-1.667-3.333-3.333-5-5c0.074,6.546-1.569,13.065-2.674,19.484
        				c-1.508,8.755-2.299,17.344-1.843,26.235c0.855,16.682,6.556,33.912,16.325,47.473c3.741,5.191,11.548,0.795,8.635-5.047
        				c-6.606-13.244-9.737-27.432-1.775-40.852c3.322-5.599,7.713-10.485,11.068-16.068c3.491-5.81,6.475-11.886,11.507-16.559
        				c-2.786-0.735-5.571-1.471-8.357-2.207c1.696,5.13-1.004,11.405-1.373,16.667c-0.476,6.784,2.857,13.709,5.435,19.775
        				c5.446,12.817,12.466,25.114,20.969,36.157c3.802,4.938,12.15,0.588,8.635-5.047c-8.494-13.613-11.271-30.087-14.384-45.601
        				c-1.962-9.781-4.02-26.334,4.067-33.986c-2.786-1.622-5.571-3.243-8.357-4.865c-1.8,12.466,1.258,24.757,9.125,34.732
        				c7.602,9.638,19.124,12.666,30.394,15.948c3.672,1.069,7.157-2.516,6.15-6.15c-1.42-5.125-4.891-9.029-8.256-13.007
        				c-4.304-5.088-7.397-11.443-9.984-17.542c-1.732-4.083-3.353-8.193-4.611-12.45c-1.083-3.667-2.803-9.814-0.065-13.187
        				c-2.845-1.179-5.69-2.357-8.536-3.536c2.001,19.467,13.82,35.997,34.155,38.316c4.559,0.52,6.313-5.69,3.536-8.536
        				c-8.677-8.888-13.897-21.399-19.862-32.203c-2.708-4.904-5.409-9.818-7.883-14.845c-1.806-3.668-0.84-9.535,0.87-14.246
        				c1.733-4.776,5.904-8.674,9.352-12.221c4.667-4.8,8.965-10.119,13.768-14.749c2.915-2.81,8.71-2.528,12.316-1.89
        				c5.594,0.989,10.702,3.958,13.668,8.862C245.681,385.995,254.33,380.972,250.984,375.439z"/>
        		</g>
        	</g>
        	<g className="leaf-3">
        		<path fill="#0E783D" d="M248.714,378.314c6.348-10.585,23.777-11.796,29.081-4.82c12.54,15.777,21.482,18.669,19.69,36.18
        			c-2.604,5.779-12.195,21.354-15.417,27.158c-2.883,5.05-6.242,10.488-10.405,14.355c14.642-0.953,23.274-13.387,24.831-26.896
        			c5.562,6.44-1.287,20.284-4.232,26.817c-1.813,4.019-4.242,8.064-6.808,11.744c-2.454,3.429-7.233,7.319-8.179,11.428
        			c9.277-2.465,18.466-4.066,24.417-12.568c5.02-7.109,7.552-14.736,6.341-23.447c7.686,7.097,5.37,26.311,2.772,35.421
        			c-2.862,11.711-5.424,24.77-12.533,35.169c6-7.271,12.495-17.648,16.938-26.832c2.153-4.774,4.557-9.434,5.34-14.524
        			c0.897-5.34-2.478-12.608-0.437-17.131c4.483,4.14,7.33,10.564,10.379,15.872c3.439,5.785,8.863,11.861,10.98,18.563
        			c3.547,11.581,0.133,21.833-5.267,31.794c7.863-10.061,12.16-22.939,13.766-35.217c0.71-6.938,0.69-13.599-0.447-20.462
        			c-0.561-4.789-2.438-14.327-2.133-19.027c5.292-0.333,10.065,16.862,13.291,20.434c4.119,4.277,9.081,8.028,12.016,13.586
        			c6.512,11.708,2.507,22.603,1.403,35.107c13.673-12.883,21.059-37.975,12.364-54.901c-3.765-7.747-9.041-10.127-15.056-15.563
        			c-8.326-7.688,8.967-7.142,13.667-6.836c7.917,0.549,16.361,1.939,24.342,1.005c10.193-1.146,13.909,5.369,18.426,13.455
        			c12.431-16.164-7.645-24.617-20.459-30.399c-8.794-3.968-17.376-5.724-26.816-4.238c-7.479,1.162-13.238,5.215-19.914,7.949
        			c2.671-7.263,8.469-13.415,13.037-19.519c2.818-3.566,9.675-8.032,10.486-20.404c0.185-2.831-1.455-19.064-1.455-19.064
        			l-2.882,5.049c-3.641,10.755-6.134,16.282-16.748,22.38c-7.946,4.881-13.68,9.551-18.928,17.163
        			c2.321-9.839,10.623-18.188,14.313-27.712c1.675-4.385,2.822-9.612,3.492-14.45c0.267-2.602,0.986-6.208,0.637-8.785
        			c-0.764-3.671-3.163-5.056-4.405-8.337c1.267,19.319-5.355,32.659-20.033,45.088c6.411-15.553,13.086-33.708,1.475-49.531
        			c-2.188,16.249-5.39,28.712-15.87,41.221c2.896-7.764,2.626-14.538,0.321-22.836c-2.03-7.568-3.606-16.142-7.936-22.632
        			c1.995,3.623,0.581,11.45,0.275,16.151c-0.483,6.435,0.768,13.047-0.218,19.255c-0.847,6.572-4.659,11.678-7.764,17.749
        			c-3.505,6.854-12.179-9.657-18.199-10.37c-8.644-1.021-2.557-4.966-9.38-10.177c-8.748-6.683-17.639-10.674-28.238-6.076
        			L248.714,378.314z"/>
        		<g>
        			<path fill="#91C83E" d="M253.031,380.837c3.556-5.46,14.242-9.676,20.056-5.056c3.907,3.105,7.049,8.151,10.484,11.839
        				c3.005,3.227,6.321,6.543,7.962,10.725c1.376,3.507,1.813,8.532,0.572,10.975c-2.173,4.278-4.661,8.405-7.086,12.543
        				c-5.099,8.7-9.64,18.688-16.893,25.787c-2.922,2.86-0.931,8.947,3.536,8.536c17.448-1.609,27.628-15.342,29.831-31.896
        				c-3.106,0.841-6.211,1.683-9.317,2.523c4.233,5.645-1.354,15.453-3.845,20.916c-2.176,4.775-4.714,9.669-8.116,13.697
        				c-3.087,3.656-6.271,6.864-7.763,11.524c-1.189,3.711,2.595,7.081,6.15,6.15c9.497-2.486,18.978-4.831,25.657-12.567
        				c6.656-7.71,9.913-18.195,8.771-28.269c-2.845,1.179-5.69,2.357-8.536,3.536c6.349,6.427,3.919,19.899,2.202,27.746
        				c-2.752,12.577-5.584,25.898-12.744,36.786c-3.094,4.705,4.261,10.49,7.853,6.059c7.284-8.984,13.639-18.898,18.444-29.432
        				c2.485-5.447,4.79-10.714,5.435-16.724c0.516-4.815-2.383-9.847-0.751-14.537c-2.786,0.735-5.571,1.471-8.357,2.207
        				c3.878,3.853,6.24,8.767,8.838,13.512c2.652,4.843,6.169,9.155,9,13.893c6.378,10.674,2.883,22.574-2.527,32.766
        				c-3.102,5.844,4.831,10.07,8.635,5.047c8.11-10.711,13.205-24.357,14.448-37.741c0.718-7.729,0.317-15.142-0.754-22.81
        				c-0.771-5.516-2.009-11.086-1.826-16.68c-1.667,1.667-3.333,3.333-5,5c-1.412-0.081,0.626,1.647,1.296,2.784
        				c1.351,2.291,2.415,4.762,3.508,7.181c2.2,4.867,4.261,8.422,8.153,12.15c5.795,5.551,10.27,11.15,10.927,19.471
        				c0.592,7.494-1.441,15.113-2.174,22.541c-0.449,4.549,5.655,6.338,8.536,3.535c17.682-17.208,25.994-51.357,6.184-70.167
        				c-2.398-2.277-5.151-4.195-7.684-6.319c-0.751-0.629-1.661-1.332-2.215-2.167c2.229,3.363-3.337,4.097,0.37,3.145
        				c3.402-0.873,7.309-0.538,10.783-0.327c8.714,0.529,17.076,1.57,25.823,0.89c6.776-0.527,9.817,6.078,12.627,11.094
        				c1.981,3.537,6.523,2.905,8.635,0c4.576-6.295,6.109-13.557,2.18-20.563c-3.722-6.636-11.578-10.633-18.147-13.801
        				c-8.659-4.175-17.307-7.602-26.994-8.272c-11.657-0.806-20.791,4.711-31.069,9.106c2.448,1.882,4.896,3.765,7.345,5.646
        				c5.02-12.492,17.597-20.031,22.151-32.63c3.119-8.628,0.987-18.826,0.096-27.686c-0.47-4.671-6.663-7.174-9.317-2.523
        				c-3.908,6.848-5.49,15.148-11.152,20.845c-4.067,4.092-9.757,6.541-14.346,10.019c-5.133,3.891-9.341,8.486-13.06,13.729
        				c3.046,1.284,6.093,2.568,9.139,3.853c2.983-11.158,11.776-19.708,15.353-30.747c1.797-5.545,2.478-11.386,3.182-17.146
        				c0.793-6.498-2.503-9.778-5-15.244c-2.078-4.548-9.582-2.277-9.317,2.523c0.438,7.973-0.281,15.854-3.629,23.194
        				c-3.336,7.314-8.92,13.174-14.939,18.358c2.786,1.622,5.571,3.243,8.357,4.865c7.125-17.367,12.647-36.61,0.971-53.383
        				c-2.144-3.08-8.485-3.545-9.139,1.194c-1.962,14.227-5.305,27.679-14.584,39.014c2.786,1.622,5.571,3.243,8.357,4.865
        				c2.956-8.513,2.594-16.864,0.321-25.494c-2.157-8.188-3.884-16.556-8.44-23.826c-3.498-5.582-11.196-0.736-8.635,5.047
        				c1.064,2.403,0.28,6.104,0.058,8.614c-0.305,3.452-0.606,6.833-0.554,10.306c0.062,4.104,0.382,8.155,0.087,12.26
        				c-0.327,4.562-2.714,8.627-4.894,12.518c-0.819,1.462-1.624,2.928-2.404,4.411c1.429-2.716,4.071,0.484,1.803-1.378
        				c-1.818-1.493-3.397-3.303-5.04-4.979c-1.676-1.71-3.454-3.254-5.402-4.648c-0.977-0.699-2.141-1.12-3.259-1.528
        				c-0.697-0.155-1.4-0.275-2.109-0.36c-1.479-0.622-1.563-0.343-0.254,0.837c-0.766-1.92-0.998-3.83-2.025-5.704
        				c-1.565-2.858-4.647-4.771-7.222-6.595c-6.284-4.452-13.752-7.238-21.511-6.562c-5.576,0.487-10.218,1.984-11.916,7.466
        				c-1.103,3.561-2.205,7.122-3.308,10.683c-1.913,6.177,7.742,8.798,9.643,2.658c1.307-4.22,2.614-8.44,3.92-12.661
        				c-1.164,1.164-2.328,2.328-3.492,3.492c7.465-2.949,13.563-1.5,20.225,2.631c2.051,1.271,4.827,2.743,5.55,5.193
        				c0.565,1.916,0.845,3.888,2.347,5.379c3.013,2.99,7.167,2.47,10.259,5.33c3.26,3.014,6.055,6.935,10.108,8.957
        				c4.001,1.996,8.038,0.566,10.316-3.165c3.531-5.782,7.134-12.201,8.268-18.943c1.211-7.203-0.076-14.479,0.484-21.721
        				c0.466-6.016,1.478-11.839-1.046-17.538c-2.878,1.682-5.756,3.365-8.635,5.047c3.809,6.078,5.28,13.044,7.031,19.912
        				c1.945,7.631,2.711,14.125,0.08,21.704c-1.596,4.598,5.187,8.737,8.357,4.865c10.468-12.788,14.926-27.261,17.155-43.427
        				c-3.046,0.398-6.092,0.796-9.139,1.194c10.097,14.504,4.113,30.832-1.979,45.678c-2.081,5.071,4.989,7.766,8.357,4.865
        				c14.81-12.756,22.58-28.938,21.498-48.624c-3.106,0.841-6.211,1.683-9.317,2.523c0.754,1.65,1.708,3.063,2.742,4.548
        				c1.545,2.222,1.013,4.669,0.705,7.189c-0.667,5.46-1.804,10.804-3.675,15.982c-3.568,9.875-11.544,17.354-14.313,27.712
        				c-1.536,5.743,5.962,8.331,9.139,3.853c3.606-5.084,8.013-9.242,13.125-12.787c4.625-3.207,9.53-5.73,13.539-9.764
        				c6.062-6.099,7.753-14.786,11.894-22.042c-3.106-0.841-6.211-1.682-9.317-2.523c0.864,8.589,2.832,17.916-0.175,26.235
        				c-1.563,4.322-5.479,7.167-8.174,10.709c-5.083,6.682-10.375,12.834-13.541,20.713c-1.858,4.625,3.895,7.122,7.345,5.646
        				c7.585-3.244,14.164-7.309,22.617-7.767c8.244-0.447,15.85,2.569,23.195,5.925c4.939,2.256,10.052,4.674,14.16,8.285
        				c4.84,4.253,3.911,8.542,0.376,13.405c2.878,0,5.756,0,8.635,0c-3.006-5.367-6.142-10.695-11.585-13.898
        				c-2.896-1.704-6.383-2.16-9.677-2.149c-4.92,0.017-9.674,0.458-14.622,0.097c-7.937-0.578-26.025-4.789-31.274,4.079
        				c-3.484,5.886,3.642,11.089,7.51,14.163c6.339,5.037,10.414,10.275,12.141,18.328c3.093,14.429-3.812,31.207-14.088,41.209
        				c2.845,1.178,5.69,2.355,8.536,3.535c0.845-8.557,2.96-17.03,1.851-25.661c-1.165-9.057-6.024-16.162-12.44-22.38
        				c-2.171-2.104-4.104-3.725-5.457-6.364c-1.509-2.945-2.746-6.031-4.178-9.014c-2.332-4.856-5.531-10.368-11.485-10.708
        				c-2.788-0.16-4.915,2.397-5,5c-0.373,11.346,3.336,22.436,2.99,33.866c-0.413,13.667-5.253,27.435-13.493,38.318
        				c2.878,1.682,5.756,3.363,8.635,5.047c6.265-11.803,10.251-25.452,4.722-38.377c-2.645-6.183-6.93-11.341-10.436-17.027
        				c-3.598-5.835-6.216-11.972-11.161-16.884c-2.802-2.784-7.142-1.288-8.357,2.207c-1.332,3.827-0.706,7.716-0.099,11.623
        				c0.908,5.836-0.434,10.318-2.849,15.666c-4.641,10.277-10.499,20.222-17.609,28.992c2.618,2.02,5.235,4.039,7.853,6.059
        				c8.122-12.349,11.735-28.022,14.405-42.407c2.028-10.929,3.064-25.647-5.426-34.242c-2.822-2.857-9.046-0.968-8.536,3.536
        				c0.935,8.257-1.391,16.041-6.859,22.375c-4.926,5.707-13.323,7.01-20.227,8.818c2.05,2.05,4.1,4.1,6.15,6.15
        				c1.531-4.782,6.799-8.842,9.566-13.056c2.86-4.355,5.035-9.064,7.119-13.824c3.458-7.896,7.916-19.114,2.031-26.962
        				c-3.122-4.163-8.646-2.517-9.317,2.523c-1.43,10.744-8.382,20.84-19.831,21.896c1.179,2.845,2.357,5.69,3.536,8.536
        				c9.041-8.848,14.818-21.492,21.109-32.365c2.566-4.434,5.864-9.047,6.311-14.236c0.525-6.11-0.753-12.149-3.787-17.464
        				c-2.826-4.952-7.232-9.05-11.062-13.206c-4.426-4.803-7.518-9.888-13.827-12.239c-10.564-3.937-23.633,1.497-29.547,10.578
        				C240.866,381.212,249.527,386.218,253.031,380.837z"/>
        		</g>
        	</g>
        	<g className="leaf-4">
        		<path fill="#0E783D" d="M249.181,369.911c-3.271-11.901,7.891-25.343,16.599-24.359c20.07,1.828,28.338-2.642,39.764,10.748
        			c2.38,5.875,7.021,23.567,8.993,29.906c1.661,5.573,3.273,11.757,3.194,17.439c9.424-11.247,6.393-26.077-2.301-36.534
        			c8.499,0.426,13.781,14.94,16.471,21.582c1.654,4.087,2.902,8.637,3.791,13.035c0.785,4.142,0.297,10.286,2.616,13.808
        			c4.625-8.412,9.813-16.163,7.774-26.338c-1.674-8.54-5.441-15.639-12.577-20.779c10.44-0.657,22.737,14.288,27.531,22.459
        			c6.493,10.158,14.166,21.029,16.778,33.354c-1.115-9.362-4.133-21.225-7.706-30.781c-1.965-4.854-3.675-9.812-6.815-13.893
        			c-3.243-4.337-10.83-6.916-12.69-11.516c6.09-0.382,12.702,1.996,18.647,3.456c6.558,1.508,14.699,1.782,21.008,4.879
        			c10.824,5.433,16.769,4.338,20.244,15.123c-1.845-12.635-9.08-13.993-16.85-23.633c-4.527-5.305-9.358-9.891-15.107-13.809
        			c-3.851-2.902-12.044-8.132-15.233-11.599c3.414-4.057,19.146,4.367,23.957,4.501c5.938-0.025,12.077-1.023,18.124,0.693
        			c12.965,3.377,18.077,13.797,26.358,23.233c0.126-18.786-12.919-41.457-31.165-46.859c-8.202-2.628-13.567-0.456-21.653,0.14
        			c-11.31,0.712,1.028-11.417,4.495-14.605c5.865-5.346,12.703-10.493,17.539-16.91c6.212-8.163,19.067,9.53,28.035,11.849
        			c-3.104-20.153-23.083-11.474-36.114-6.2c-8.943,3.619-21.719-7.268-27.164,0.585c-4.325,6.211-5.371,13.176-8.005,19.891
        			c-3.407-6.947-3.852-15.389-5.112-22.909c-0.633-4.501,6.451,3.337-1.935-5.794c-1.919-2.089-14.792-12.114-14.792-12.114
        			l1.662,5.571c5.263,10.061,1.96-0.2-0.96,11.687c-1.958,9.118-2.541,16.49-0.661,25.542c-5.513-8.475-5.816-20.244-10.156-29.491
        			c-2.014-4.239,0.577,7.201-2.459,3.375c-1.698-1.99-3.808-5-5.913-6.527c-3.183-1.983-5.841-1.205-9.072-2.573
        			c14.846,12.427,14.341,10.549,13.192,29.748c-6.82-15.378-10.678,1.569-30.141-0.961c10.24,12.804,12.381-10.586,14.189,5.632
        			c-3.615-7.457-9.617,6.493-17.211,2.429c-6.876-3.759-6.164,18.08-13.848,16.729c3.998,1.06,0.682-19.132,3.87-15.665
        			c4.32,4.793,10.884-9.978,14.692-4.977c4.167,5.152,5.228,11.434,7.474,17.873c2.536,7.269-15.396,2.138-20.069,6
        			c-6.708,5.545-5.357-1.581-13.838-0.245c-10.874,1.71-19.902,5.384-23.896,16.225L249.181,369.911z"/>
        		<g>
        			<path fill="#91C83E" d="M254.002,368.582c-1.13-4.922,0.576-9.822,3.731-13.66c3.282-3.991,6.354-4.47,11.201-4.125
        				c8.023,0.569,17.736-1.19,25.219,2.4c1.557,0.747,2.942,1.768,4.241,2.891c2.012,1.739,2.542,2.039,3.165,3.807
        				c1.446,4.11,2.61,8.327,3.813,12.512c2.885,10.041,7.204,20.742,7.359,31.237c0.059,3.96,5.684,7.153,8.536,3.536
        				c10.84-13.755,8.184-30.482-2.301-43.605c-1.179,2.845-2.357,5.69-3.536,8.536c5.904,0.674,9.516,12.584,11.327,17.111
        				c1.926,4.813,3.729,10.013,4.285,15.194c0.513,4.768,0.602,9.333,2.948,13.643c1.781,3.272,6.843,3.23,8.635,0
        				c4.905-8.841,10.277-17.986,8.553-28.517c-1.712-10.457-6.706-19.047-15.149-25.441c-0.841,3.106-1.682,6.211-2.523,9.317
        				c9.045-0.125,17.018,10.665,21.56,17.351c7.259,10.685,14.981,22.061,17.928,34.791c1.252,5.411,10.543,4.313,9.821-1.329
        				c-1.554-12.149-4.659-23.97-9.175-35.351c-2.087-5.259-4.349-10.582-8.437-14.621c-3.12-3.083-8.249-4.714-10.282-8.741
        				c-1.439,2.508-2.878,5.016-4.317,7.523c5.977-0.138,11.59,1.814,17.318,3.277c5.512,1.408,11.233,1.714,16.733,3.237
        				c4.721,1.307,9.327,3.901,13.899,5.717c3.775,1.499,5.792,3.728,7.126,7.556c2.146,6.154,10.721,3.656,9.643-2.658
        				c-2.083-12.193-11.652-18.371-19.274-27.151c-4.672-5.383-10.105-9.675-15.806-13.89c-4.502-3.329-9.453-6.186-13.396-10.207
        				c0,2.357,0,4.714,0,7.071c-0.581,0.542-1.084,0.351,0.31,0.381c1.189,0.026,2.419,0.455,3.56,0.757
        				c2.587,0.684,5.11,1.631,7.631,2.521c5.651,1.994,10.087,2.297,16.124,2.045c8.125-0.338,14.951,1.741,20.876,7.354
        				c4.841,4.586,8.527,10.332,12.867,15.369c2.913,3.381,8.573,0.607,8.536-3.536c-0.13-14.285-5.923-27.914-15.171-38.668
        				c-4.309-5.011-9.904-9.148-16.007-11.673c-8.416-3.482-16.049-2.554-24.819-1.541c0.483-0.056-3.395,0.155-2.956,0.341
        				c2.488,1.055,0.747,3.889,2.159,1.505c1.272-2.147,3.154-3.963,4.896-5.726c5.271-5.336,11.357-9.777,16.532-15.227
        				c1.696-1.786,2.577-4.718,4.604-4.169c2.467,0.667,4.832,2.776,6.84,4.271c4.699,3.498,9.127,7.579,14.844,9.323
        				c3.928,1.199,6.789-2.589,6.15-6.15c-1.534-8.559-6.823-15.292-15.843-16.188c-8.121-0.806-16.208,2.39-23.601,5.353
        				c-4.244,1.701-7.065,1.882-11.547,0.772c-4.435-1.098-9.01-2.71-13.634-1.896c-11.065,1.948-13.077,17.628-16.301,26.235
        				c3.046-0.398,6.092-0.796,9.139-1.194c-1.922-4.233-2.7-8.853-3.398-13.415c-0.34-2.221-0.624-4.452-0.951-6.675
        				c0.211,1.436-7.571,2.693-4.248,3.428c2.321,0.514,4.327-0.352,5.646-2.297c0.011-0.017,0.022-0.033,0.033-0.049
        				c1.008-1.487,0.817-3.544,0-5.047c-3.958-7.284-13.343-12.817-19.702-17.774c-3.643-2.839-9.929-0.404-8.357,4.865
        				c0.535,1.793,1.07,3.587,1.604,5.38c0.407,1.364,1.206,2.602,1.892,3.836c1.021,1.838,1.208,3.671,0.763,0.062
        				c0.488-1.178,0.977-2.357,1.464-3.535c0.421-0.492,3.729-2.004,0.635-0.594c-0.711,0.324-1.302,0.862-1.881,1.379
        				c-2.174,1.942-3.051,5.398-3.776,8.071c-2.486,9.169-2.429,18.94-0.661,28.201c3.046-1.284,6.092-2.568,9.139-3.853
        				c-3.346-5.493-4.62-11.745-6.182-17.91c-1.022-4.034-2.265-12.149-6.438-14.331c-1.923-1.006-4.515-0.933-6.059,0.782
        				c-1.202,1.334-1.54,2.593-1.652,4.333c-0.036,0.559,0.004,1.132,0.012,1.691c0.056,4.021,8.733,0.502,6.042-2.762
        				c-1.718-2.083-3.552-4.982-6.044-6.241c-3.347-1.69-6.773-1.905-10.267-3.077c-4.616-1.55-8.717,5.141-4.865,8.357
        				c3.855,3.218,9.261,6.486,11.68,11.042c1.969,3.709,0.295,11.093,0.048,15.17c3.106-0.841,6.211-1.683,9.317-2.523
        				c-2.657-5.733-7.035-10.436-13.827-8.479c-2.762,0.795-5.238,2.454-7.886,3.533c-4.151,1.691-8.32,1.983-12.745,1.509
        				c-4.777-0.512-6.014,5.598-3.536,8.536c3.69,4.374,8.381,6.783,13.887,4.329c1.508-0.672,2.802-2.082,3.894-3.293
        				c0.18-0.203,0.363-0.402,0.55-0.599c0.084-0.084,0.169-0.169,0.254-0.253c-1.851,1.624-4.04,1.055-6.568-1.707
        				c0.464,1.112,0.567,2.438,0.708,3.62c3.106-0.841,6.211-1.683,9.317-2.523c-2.648-4.697-7.222-5.693-11.873-3.231
        				c-1.953,1.034-3.55,2.79-5.6,3.648c-1.81,0.758-3.755-0.783-5.666-0.898c-4.907-0.296-7.383,5.013-8.842,8.758
        				c-0.833,2.137-1.482,4.342-2.31,6.483c-0.311,0.803-0.793,1.553-1.258,2.272c0.112-0.172,1.674-0.209,0.173-0.351
        				c-6.439-0.607-6.333,9.345,0,10c6.258,0.647,7.018-7.213,7.19-11.567c0.084-2.129-0.041-5.642,0.177-7.016
        				c0.785-2.72-1.56-2.235-7.033,1.453c0.654,0.596,1.318,1.139,2.089,1.577c1.514,0.859,3.324,0.996,5.001,0.668
        				c2.857-0.558,5.119-2.61,7.2-4.515c0.886-0.812,1.721-1.675,2.724-2.339c-0.564-0.437-1.272-0.46-2.124-0.069
        				c0.994,1.33,1.783,2.928,2.418,4.453c1.038,2.491,1.738,5.137,2.528,7.713c0.329,1.073,0.677,2.141,1.044,3.202
        				c-0.024-0.073,0.962-2.318,1.159-2.379c-2.612,0.807-5.862,0.341-8.563,0.393c-2.686,0.052-5.54,0.219-8.071,1.176
        				c-1.673,0.633-2.866,1.672-4.358,2.628c0.383-0.028,0.759,0.021,1.129,0.147c-0.606-0.298-1.178-0.705-1.765-1.039
        				c-6.932-3.945-16.908-0.318-23.54,2.719c-3.668,1.679-6.913,4.425-9.385,7.58c-2.298,2.934-6.022,8.147-3.905,11.949
        				c2.149,3.86,4.299,7.72,6.448,11.58c3.137,5.634,11.775,0.592,8.635-5.047c-2.149-3.86-4.299-7.72-6.448-11.58
        				c0.168,1.284,0.336,2.568,0.504,3.853c3.083-7.735,10.073-10.668,17.726-12.261c3.298-0.687,4.459-0.211,7.228,1.149
        				c2.904,1.427,5.606,0.943,8.421-0.415c2.663-1.284,3.313-2.249,6.414-2.408c2.856-0.146,5.716-0.02,8.571-0.235
        				c5.883-0.442,11.245-3.845,9.444-10.469c-1.767-6.5-4.529-19.724-11.93-22.206c-5.543-1.858-9.071,3.319-12.908,6.202
        				c-1.651,1.241,1.602,1.097,1.098,0.638c-1.108-1.01-2.477-1.62-4.002-1.584c-2.813,0.067-4.768,2.203-5.39,4.807
        				c-0.543,2.272-0.446,4.755-0.479,7.073c-0.021,1.426-0.056,2.851-0.135,4.275c-0.045,0.303-0.065,0.606-0.061,0.91
        				c-0.437,3.066-0.861-1.411,2.95-1.017c0,3.333,0,6.667,0,10c8.817,0.831,10.938-10.359,13.329-16.494
        				c0.184-0.471,0.505-0.931,0.766-1.363c-0.731,1.215-4.287-0.227-1.829,0.862c2.477,1.098,4.819,1.127,7.443,0.638
        				c2.161-0.403,4.246-1.804,6.024-3.024c0.717-0.492,1.422-0.999,2.145-1.483c1.838-1.232-1.527-1.463-1.137-0.771
        				c2.293,4.068,9.927,2.582,9.317-2.523c-0.621-5.194-2.371-13.744-9.73-10.14c-1.431,0.701-2.46,2.277-3.577,3.363
        				c-0.397,0.386-0.801,0.198,0.009,0.158c0.097-0.004-1.933-2.047-2.355-2.549c-1.179,2.845-2.357,5.69-3.536,8.536
        				c6.792,0.728,12.961-0.635,19.112-3.459c1.269-0.583,3.859-2.028,4.508-2.101c1.372-0.155,0.005,0.09-0.02,0.066
        				c1.037,0.988,1.635,2.71,2.223,3.979c2.253,4.862,9.043,2.002,9.317-2.523c0.386-6.382,1.591-13.618-1.156-19.652
        				c-2.623-5.762-8.811-9.716-13.5-13.631c-1.622,2.786-3.243,5.571-4.865,8.357c1.988,0.667,3.969,0.948,6.023,1.329
        				c2.236,0.415,3.608,2.629,4.936,4.248c1.953,2.381,3.238,4.121,6.452,4.486c2.593,0.295,4.781-2.305,5.265-4.571
        				c0.213-1,0.25-2.037,0.252-3.055c0.004-2.721,0.655-1.113-1.289,1.046c-2.02,0.261-4.039,0.521-6.059,0.782
        				c-1.403-1.676-1.778-1.956-1.123-0.838c0.746,1.435,1.194,3.077,1.689,4.61c0.977,3.026,1.671,6.149,2.444,9.231
        				c1.371,5.458,3.079,10.819,6.022,15.65c3.064,5.031,10.18,1.602,9.139-3.853c-1.22-6.393-1.157-12.664-0.09-19.073
        				c0.413-2.481,0.82-5.169,1.974-7.431c-0.061,0.119-1.125,0.57-1.166,0.584c0.825-0.286,1.744-0.648,2.334-1.338
        				c2.285-2.671,1.489-5.18-0.114-7.867c-1.472-2.466-2.157-4.923-2.978-7.676c-2.786,1.622-5.571,3.243-8.357,4.865
        				c2.938,2.291,5.857,4.606,8.756,6.948c1.821,1.471,3.639,2.952,5.377,4.522c1.017,0.918,1.895,1.99,2.82,2.998
        				c0.327,0.356,2.073,2.914,1.186,1.283c0-1.683,0-3.365,0-5.047c-0.011,0.017-0.022,0.033-0.033,0.049
        				c1.882-0.766,3.764-1.532,5.646-2.298c-3.791-0.839-8.861,0.114-8.529,5.307c0.558,8.723,2.213,18.073,5.833,26.044
        				c1.976,4.351,7.796,2.39,9.139-1.194c1.768-4.719,2.918-9.679,4.971-14.289c1.725-3.873,2.738-5.339,6.886-4.532
        				c7.002,1.362,13.677,4.327,20.741,1.463c5.771-2.34,11.807-5.025,18.046-5.774c6.822-0.818,9.821,2.288,10.998,8.854
        				c2.05-2.05,4.1-4.1,6.15-6.15c-9.578-2.923-19.342-19.664-30.688-12.553c-3.012,1.888-5.109,5.534-7.605,8.039
        				c-3.456,3.468-7.293,6.557-10.96,9.794c-4.262,3.763-8.91,8.19-11.085,13.536c-2.457,6.041,1.932,10.234,7.739,10.698
        				c4.146,0.331,8.631-1.012,12.765-1.364c4.767-0.405,9.356,0.846,13.574,2.995c14.068,7.168,22.542,24.761,22.682,40.062
        				c2.845-1.179,5.69-2.357,8.536-3.536c-5.255-6.099-9.667-12.97-15.963-18.09c-7.161-5.822-15.771-7.787-24.851-7.561
        				c-3.456,0.086-6.362,0.583-9.523-0.355c-3.172-0.942-6.258-2.18-9.404-3.204c-5.065-1.648-11.428-3.269-15.769,0.784
        				c-2.006,1.874-1.858,5.176,0,7.071c8.067,8.227,18.787,13.245,26.879,21.563c4.068,4.182,7.745,8.56,12.046,12.529
        				c4,3.69,6.056,7.338,6.979,12.742c3.214-0.886,6.428-1.772,9.643-2.658c-1.72-4.934-4.099-8.825-8.429-11.832
        				c-4.152-2.882-9.588-4.08-14.113-6.28c-6.081-2.957-12.724-3.676-19.305-4.801c-7.717-1.319-14.917-4.401-22.874-4.217
        				c-3.941,0.091-6.027,4.137-4.317,7.523c1.89,3.744,5.351,6.083,8.714,8.404c4.66,3.216,6.896,7.377,8.997,12.57
        				c4.214,10.421,7.39,21.524,8.818,32.692c3.274-0.443,6.547-0.886,9.821-1.329c-3.296-14.24-11.934-27.277-20.261-39.094
        				c-6.484-9.203-16.603-20.559-28.869-20.389c-5.371,0.074-6.296,6.46-2.523,9.317c5.873,4.447,8.78,10.746,10.279,17.791
        				c1.725,8.105-3.546,15.772-7.271,22.485c2.878,0,5.756,0,8.635,0c-1.412-2.594-1.341-5.724-1.583-8.596
        				c-0.256-3.06-0.934-6.026-1.684-8.997c-1.291-5.117-3.368-9.991-5.425-14.836c-3.53-8.318-8.703-17.353-18.504-18.472
        				c-4.867-0.556-5.883,5.597-3.536,8.536c6.745,8.441,9.449,20.393,2.301,29.462c2.845,1.179,5.69,2.357,8.536,3.536
        				c-0.188-12.689-5.281-25.578-8.813-37.684c-1.391-4.767-2.422-10.445-5.759-14.244c-3.704-4.216-8.375-7.36-13.71-9.076
        				c-4.657-1.498-9.851-1.444-14.687-1.511c-6.198-0.085-13.273-1.59-19.289,0.061c-12.187,3.345-18.757,18.539-16.114,30.049
        				C245.802,377.524,255.443,374.859,254.002,368.582z"/>
        		</g>
        	</g>
        	<g className="leaf-5">
        		<path fill="#0E783D" d="M254.392,367.937c-11.232,5.117-26.273-3.771-26.684-12.526c-1.378-20.106-7.103-27.561,4.306-40.965
        			c5.423-3.282,22.156-10.669,28.102-13.621c5.239-2.524,11.09-5.098,16.712-5.92c-12.599-7.521-26.761-2.176-35.706,8.066
        			c-0.928-8.459,12.565-15.976,18.697-19.685c3.773-2.282,8.068-4.235,12.269-5.811c3.965-1.432,10.109-1.925,13.218-4.772
        			c-9.039-3.232-17.514-7.125-27.237-3.499c-8.167,3.007-14.578,7.852-18.521,15.713c-2.305-10.204,10.501-24.715,17.808-30.744
        			c9-8.021,18.517-17.322,30.271-21.855c-9.066,2.585-20.301,7.447-29.17,12.49c-4.481,2.711-9.104,5.185-12.636,8.933
        			c-3.768,3.889-5.111,11.789-9.357,14.356c-1.344-5.952-0.044-12.858,0.455-18.959c0.449-6.715-0.571-14.796,1.486-21.516
        			c3.647-11.55,12.275-18.057,22.372-23.198c-12.182,3.826-23.026,11.995-31.313,21.196c-4.52,5.311-8.282,10.808-11.238,17.106
        			c-2.254,4.262-6.119,13.181-9.036,16.88c-4.547-2.727,1.275-19.596,0.644-24.367c-0.966-5.859-2.925-11.762-2.189-18.005
        			c1.278-13.336,10.755-20.037,18.758-29.709c-18.568,2.854-38.883,19.33-41.323,38.201c-1.294,8.516,1.702,13.469,3.572,21.357
        			c2.497,11.054-11.436,0.795-15.133-2.122c-6.208-4.942-12.375-10.877-19.478-14.635c-9.044-4.839-8.409-12.311-7.542-21.533
        			c-19.406,6.261-7.668,24.61-0.394,36.64c4.992,8.257,11.063,14.57,19.681,18.701c6.818,3.285,13.861,3.214,20.909,4.749
        			c-6.319,4.466-14.584,6.244-21.808,8.681c-4.344,1.339-12.524,1.127-20.209,10.856c-1.758,2.226-9.616,16.525-9.616,16.525
        			l5.237-2.524c9.099-6.792,14.288-9.93,26.487-8.932c9.313,0.487,16.684-0.106,25.324-3.398
        			c-7.493,6.787-19.065,8.954-27.507,14.704c-3.866,2.661-7.776,6.315-11.072,9.92c-1.696,1.991-4.333,4.553-5.507,6.874
        			c-1.453,3.457-0.263,5.958-1.102,9.365c9.915-16.629,22.936-23.859,42.074-25.77c-14.102,9.173-29.897,20.338-29.31,39.956
        			c11.019-12.142,20.725-20.589,36.451-24.946c-6.79,4.751-10.409,10.483-13.217,18.625c-2.621,7.384-6.187,15.339-6.302,23.14
        			c0.412-4.115,6.017-9.759,8.935-13.458c4.048-5.025,6.768-11.181,11.101-15.734c4.426-4.932,10.46-6.975,16.461-10.213
        			c6.774-3.656,4.553,14.861,9.106,18.863c6.539,5.744-0.711,5.54,1.953,13.702c3.414,10.465,8.472,18.796,19.81,21.021
        			L254.392,367.937z"/>
        		<g>
        			<path fill="#91C83E" d="M253.063,363.115c-4.829,1.957-9.51,1.504-14.121-0.862c-4.553-2.336-6.074-5.328-6.508-10.169
        				c-0.745-8.307-3.883-17.573-1.682-25.843c1-3.758,3.334-7.313,6.738-9.111c3.962-2.093,8.046-3.965,12.104-5.863
        				c9.186-4.296,18.507-9.773,28.563-11.542c4.358-0.766,4.751-7.157,1.194-9.139c-14.621-8.144-30.943-3.066-41.765,8.848
        				c2.845,1.179,5.69,2.357,8.536,3.536c-0.287-7.112,11.1-12.287,16.22-15.367c4.584-2.758,9.504-5.029,14.699-6.335
        				c4.36-1.097,8.219-2.223,11.8-5.029c3.231-2.533,0.912-7.228-2.207-8.357c-10.417-3.774-20.365-7.174-31.24-2.98
        				c-8.688,3.349-15.785,9.232-20.165,17.493c3.046,0.398,6.093,0.796,9.139,1.194c-1.517-8.674,8.559-18.523,14.186-23.829
        				c9.013-8.499,18.771-17.895,30.401-22.62c5.801-2.356,3.688-11.512-2.658-9.643c-11.059,3.257-21.969,7.964-31.862,13.891
        				c-4.892,2.931-10.32,5.981-13.705,10.721c-2.352,3.293-3.491,9.281-6.791,11.671c2.448,0.996,4.896,1.992,7.345,2.988
        				c-1.07-5.804,0.104-11.824,0.633-17.63c0.566-6.214-0.357-12.445,0.885-18.613c2.132-10.583,11.562-17.153,20.496-21.783
        				c5.024-2.604,1.532-10.921-3.853-9.139c-13.437,4.447-25.558,12.964-34.633,23.813c-4.576,5.47-8.113,11.388-11.378,17.697
        				c-2.766,5.346-4.969,11.099-8.564,15.969c2.618-0.337,5.235-0.675,7.853-1.012c-1.082-0.84,0.118,1.117-0.281-0.597
        				c-0.233-1.002,0.034-2.37,0.105-3.384c0.183-2.582,0.746-5.147,1.208-7.689c1.05-5.769,1.065-9.995-0.141-15.783
        				c-1.694-8.134-1.295-15.18,3.194-22.292c3.561-5.642,8.845-10.184,13.126-15.264c3.336-3.958,0.145-9.212-4.865-8.357
        				c-24.839,4.24-50.397,28.375-44.216,55.266c0.683,2.969,1.738,5.861,2.557,8.795c0.139,0.549,0.277,1.099,0.416,1.648
        				c0.035,1.821,0.979,1.381,2.829-1.318c-0.497,0.045-1.566-0.536-1.963-0.726c-4.341-2.079-8.024-5.211-11.637-8.226
        				c-3.028-2.526-6.063-5.025-9.198-7.419c-3.071-2.345-6.755-3.875-9.733-6.285c-4.48-3.627-3.091-10.67-2.629-15.645
        				c0.29-3.128-3.432-5.839-6.329-4.821c-7.3,2.564-13.595,7.393-14.208,15.646c-0.547,7.358,2.881,14.432,6.323,20.712
        				c4.313,7.871,9.177,15.59,15.981,21.545c3.752,3.284,8.212,6.111,12.901,7.835c6.212,2.284,12.756,2.703,19.2,3.995
        				c-0.398-3.046-0.796-6.092-1.194-9.139c-6.338,4.221-14.018,6.327-21.293,8.367c-5.843,1.639-11.095,2.64-16.088,6.433
        				c-7.53,5.72-11.592,14.959-16.045,23.056c-2.529,4.599,2.411,8.976,6.841,6.841c6.901-3.326,12.658-8.911,20.263-10.589
        				c5.661-1.249,11.962,0.093,17.722-0.117c6.125-0.223,12.126-1.554,17.869-3.644c-1.622-2.786-3.243-5.571-4.865-8.357
        				c-8.455,7.234-20.224,9.045-29.072,15.832c-4.636,3.556-8.596,7.745-12.457,12.105c-1.474,1.665-3.158,3.613-3.844,5.767
        				c-1.008,3.164-0.57,6.164-1.102,9.365c-0.898,5.409,6.027,8.954,9.139,3.853c8.651-14.184,21.344-21.522,37.756-23.293
        				c-0.841-3.106-1.683-6.211-2.523-9.317c-16.101,10.505-31.96,23.299-31.786,44.273c0.035,4.2,5.521,6.835,8.536,3.536
        				c9.77-10.697,20.084-19.563,34.245-23.661c-1.284-3.046-2.568-6.092-3.853-9.139c-6.806,4.969-11.17,10.938-14.419,18.67
        				c-3.629,8.635-7.148,17.984-7.576,27.413c-0.254,5.593,8.736,6.89,9.821,1.329c0.625-3.2,3.041-5.739,5.08-8.167
        				c2.537-3.02,4.92-6.082,7.065-9.397c2.413-3.731,4.633-7.388,7.744-10.597c3.005-3.101,7.506-4.846,11.315-6.718
        				c0.499-0.25,0.997-0.501,1.496-0.751c2.441-0.877,1.959-1.342-1.448-1.396c-0.236-0.19,0.386,1.392,0.423,1.528
        				c0.673,2.436,0.958,4.991,1.407,7.474c0.448,2.482,1.144,4.787,2.205,7.077c0.449,0.969,1.228,1.855,1.96,2.611
        				c0.567,0.461,1.074,0.978,1.522,1.552c0.059-1.53-0.03-1.786-0.267-0.767c-3.513,11.456,3.678,24.553,13.087,31.313
        				c4.669,3.355,10.822,5.517,15.575,1.771c3.109-2.45,6.218-4.9,9.328-7.35c5.063-3.99-2.066-11.016-7.071-7.071
        				c-3.47,2.735-6.94,5.469-10.41,8.204c1.622-0.429,3.243-0.857,4.865-1.286c-6.211-1.389-10.219-4.707-13.3-10.209
        				c-1.515-2.706-2.572-5.695-3.39-8.676c-0.425-1.55,0.701-3.192,1.138-4.618c0.681-2.221,0.04-4.358-0.919-6.358
        				c-0.845-1.763-2.926-2.686-3.585-4.411c-1.542-4.035-1.573-8.558-2.721-12.709c-1.066-3.861-3.825-7.407-8.182-7.23
        				c-2.928,0.119-5.707,2.15-8.245,3.397c-3.281,1.612-6.5,3.252-9.397,5.505c-5.95,4.628-9.412,11.234-13.551,17.374
        				c-3.793,5.627-10.003,10.267-11.365,17.246c3.274,0.443,6.547,0.886,9.821,1.329c0.346-7.622,3.565-14.728,6.123-21.811
        				c2.265-6.27,5.461-11.651,10.919-15.637c4.355-3.18,1.938-10.814-3.853-9.139c-15.799,4.571-27.728,14.266-38.658,26.232
        				c2.845,1.179,5.69,2.357,8.536,3.536c-0.142-17.111,13.839-27.161,26.833-35.638c3.792-2.474,2.782-9.89-2.523-9.317
        				c-9.323,1.006-18.255,3.108-26.487,7.734c-8.458,4.753-14.914,12.33-19.904,20.512c3.046,1.284,6.093,2.568,9.139,3.853
        				c0.429-2.582,0.215-5.147,0.574-7.708c0.332-2.363,3.252-4.624,4.75-6.324c3.633-4.126,7.828-7.872,12.551-10.691
        				c8.581-5.12,18.288-7.31,26.028-13.933c3.746-3.206-0.215-10.049-4.865-8.357c-6.108,2.222-12.119,3.289-18.614,3.369
        				c-6.194,0.077-12.077-0.689-18.204,0.663c-8.002,1.766-14.188,7.839-21.425,11.326c2.28,2.28,4.561,4.561,6.841,6.841
        				c4.018-7.304,7.633-16.322,14.755-21.114c4.061-2.732,9.011-2.959,13.54-4.449c7.406-2.436,14.975-4.33,21.544-8.705
        				c3.322-2.212,3.349-8.228-1.194-9.139c-8.905-1.786-17.258-2.248-25.099-7.32c-6.255-4.047-10.387-10.321-14.158-16.568
        				c-4.8-7.952-14.055-21.953-0.94-26.56c-2.11-1.607-4.219-3.214-6.329-4.821c-0.583,6.28-0.839,12.206,1.881,18.045
        				c1.402,3.012,4.187,5.236,6.853,7.059c3.939,2.693,7.974,4.91,11.714,7.947c5.469,4.439,19.867,19.728,28.322,14.355
        				c5.488-3.487,3.426-10.047,1.9-15.177c-2.43-8.168-3.275-15.232,0.432-23.219c6.27-13.505,20.738-23.001,35.131-25.458
        				c-1.622-2.786-3.243-5.571-4.865-8.357c-5.174,6.138-11.113,11.622-15.423,18.449c-4.833,7.658-5.534,16.53-4.164,25.271
        				c0.544,3.469,1.823,6.829,1.464,10.361c-0.318,3.132-1.123,6.249-1.61,9.36c-0.757,4.83-1.984,12.212,2.52,15.71
        				c2.723,2.114,5.776,1.801,7.853-1.012c6.488-8.79,9.635-19.643,16.009-28.59c7.86-11.035,19.732-20.038,32.59-24.294
        				c-1.284-3.046-2.568-6.092-3.853-9.139c-10.884,5.641-19.66,13.133-24.188,24.768c-2.569,6.6-1.974,14.145-2.026,21.077
        				c-0.061,7.961-1.864,15.513-0.396,23.474c0.554,3.002,4.847,4.797,7.345,2.988c3.502-2.536,5.389-5.827,7.223-9.675
        				c2.418-5.074,5.503-7.931,10.288-10.926c10.075-6.307,21.055-11.315,32.458-14.674c-0.886-3.214-1.772-6.428-2.658-9.643
        				c-13.752,5.587-24.965,15.905-35.645,25.975c-8.612,8.12-18.124,20.209-15.927,32.775c0.751,4.299,7.214,4.824,9.139,1.194
        				c3.224-6.081,7.871-9.983,14.071-12.847c8.804-4.067,17.628-0.117,26.041,2.931c-0.735-2.786-1.471-5.571-2.207-8.357
        				c-3.542,2.777-9.986,3.035-14.17,4.738c-5.242,2.134-10.099,4.876-14.898,7.851c-7.449,4.618-16.977,11.375-16.58,21.214
        				c0.167,4.126,5.458,6.924,8.536,3.536c7.732-8.513,18.887-13.277,29.647-7.284c0.398-3.046,0.796-6.093,1.194-9.139
        				c-12.396,2.179-24.409,9.427-35.72,14.794c-4.625,2.195-9.828,4.046-13.019,8.18c-3.401,4.407-5.8,9.248-6.468,14.815
        				c-0.625,5.208,0.275,10.403,1.034,15.55c0.895,6.069,0.585,12.729,3.15,18.365c4.914,10.796,20.923,15.152,31.244,10.97
        				C261.617,370.369,259.043,360.692,253.063,363.115z"/>
        		</g>
        	</g>
        </g>
      )}
      {isMobile && (
        <g id="boat">
        	<g id="smoke">
        		<g>
        			<path fill="#939393" d="M708.201,249.987c1.697-9.935-4.098-15.411-9.58-22.765c-1.655,2.884-3.31,5.768-4.965,8.652
        				c6.146-0.658,8.764-4.953,7.621-10.867c-1.226-6.339-8.192-8.963-13.426-11.094c-6.871-2.798-9.83,8.331-3.057,11.089
        				c1.386,0.564,2.702,1.209,4.021,1.914c1.148,0.125,1.571,0.396,1.267,0.814c0.493,1.919,1.685,0.801,3.573-3.356
        				c-3.799,0.407-7.86,4.769-4.965,8.652c2.711,3.636,9.282,8.858,8.42,13.902C695.872,254.181,706.953,257.286,708.201,249.987
        				L708.201,249.987z"/>
        		</g>
        	</g>
        	<g id="smoke2">
        		<g>
        			<path fill="#939393" d="M755.288,259.86c2.483-4.889,2.068-9.051,1.518-14.326c-0.539-5.158,2.08-9.305-1.085-14.165
        				c-2.873-4.411-8.779-5.467-13.259-7.461c-1.42-0.632-2.751-1.56-4.038-2.421c-0.82-0.548-1.539-1.147-2.277-1.798
        				c-1.862-1.641-0.171-1.033-0.778,0.464c2.79-6.875-8.338-9.835-11.09-3.058c-2.883,7.103,5.902,12.98,10.976,15.994
        				c2.234,1.328,4.759,2.203,7.187,3.101c2.725,1.007,3.856,0.75,3.298,3.606c-0.479,2.454-0.534,4.773-0.274,7.252
        				c0.245,2.354,1.024,4.782-0.105,7.007C742.009,260.652,751.929,266.474,755.288,259.86L755.288,259.86z"/>
        		</g>
        	</g>
        	<g id="top_red">
        		<path fill="#EE233C" d="M696.566,284.958c-1.5-6.227,0.416-16.958,0.416-23.6c0.167-8.511-0.347-7.059,0.153-15.362
        			c2.598-1.555,5.515-1.555,9.181-0.517c2.5,0.623,3.667,1.142,3.25,2.283c0.333,7.888,0.75,11.312-0.084,19.2
        			c-0.333,3.113-1.582,10.067-2.249,13.181c-0.5,2.698,4.083,0.933,5.749,2.387c3,2.491,11.834,1.453,15.167,0.83
        			c3.167-0.623,12.249,1.764,12.083-1.972c-0.167-3.321-0.249-7.368-0.083-10.689c0.167-5.812,2.508-11.097,2.841-16.909
        			c1.917-6.642,5.5,1.035,14.5-0.833c0.5,4.151-0.174,10.893-0.508,15.043c-0.333,4.774,0.5,13.699-1.5,17.851
        			c-1,2.283-3.5,1.868-5.833,2.283s-4.5,0.83-6.833,1.453c-4.334,1.038-8.334,2.698-12.834,3.114
        			c-4.166,0.415-8.5,0.207-12.666,0.415c-3.667,0-7.5,0-11.167,0c-1.667,0-4.167,0.623-5.833,0c-2.167-0.831-2.917,0.52-3.25-2.802
        			L696.566,284.958z"/>
        	</g>
        	<g id="frontscape">
        		<path fill="#E5E5E1" d="M720.948,292.875c-15.948,0-37.494-3.293-53.302-1.625c-4.844,0.511-18.794,3.357-22.686,0.058
        			c-3.171-2.688-2.241-12.697-0.138-15.683c1.714-2.432,4.752-1.333,8.018-1.333c3.267,0,6.439-0.408,9.599-0.383
        			c0.095-1.851,0.053-3.748,0.051-5.617c-0.002-2.766-0.59-3.836,1.666-4.407c3.048-0.771,8.956,0.289,12.283,0.458
        			c0.211,4.148-0.853,5.111,3.384,5.283c3.359,0.136,6.683-0.133,9.956,0.384c-0.594,3.609-2.523,7.765-3.59,11.149
        			c6.323-2.969,22.018-1.38,28.467-4.366c-0.577-2.729-0.108-3.035-0.833-5.833c1.726,2.518,10.948,0.625,14.5-0.5
        			c0.635,2.314-1.693,2.724-0.501,4.816c1.73,3.035,2.405,2.921,6.42,3.61c8.388,1.439,16.728,1.68,25.205,2.125
        			c-0.685,3.666-2.926,6.514-3.291,10.283L720.948,292.875z"/>
        	</g>
        	<g id="top_1_">
        		<path fill="#1E1E1E" d="M759.719,292.876c-17.636,0-120.801-1.913-123.896-2.627c-1,0-5-0.625-6-0.625v13c-6,41,25,25,55,27
        			c14,1,62,5,73-6C757.823,323.625,767.886,292.876,759.719,292.876z"/>
        		<g>
        			<path fill="#1D1D1D" d="M759.719,290.376c-23.742-0.016-47.496-0.64-71.232-1.123c-12.715-0.259-25.429-0.532-38.141-0.894
        				c-7.628-0.217-13.896-0.85-20.522-1.235c-1.361-0.079-2.5,1.197-2.5,2.5c0,6.811-0.345,13.421-0.708,20.202
        				c-0.376,7.021,0.835,15.264,6.872,19.735c5.5,4.073,12.859,4.172,19.413,3.943c9.12-0.319,18.163-1.608,27.305-1.555
        				c17.762,0.103,35.261,2.497,53.086,1.195c7.762-0.567,16.153-1.517,23.079-5.331c4.646-2.558,5.073-7.275,6.316-12.059
        				C764.135,310.179,769.954,291.189,759.719,290.376c-3.217-0.255-3.191,4.747,0,5c2.69,0.213-1.429,17.369-1.854,19.048
        				c-0.65,2.566-1.383,5.112-2.168,7.64c-0.47,1.515,0.373-0.098-0.65,0.666c-1.497,1.117-3.267,1.835-5.012,2.453
        				c-8.683,3.074-18.479,3.265-27.586,3.44c-9.481,0.183-18.975-0.249-28.435-0.854c-8.077-0.517-15.995-1.002-24.098-0.494
        				c-8.385,0.526-16.85,1.837-25.257,1.068c-8.123-0.743-12.303-5.723-12.993-13.69c-0.377-4.339,0.657-8.525,0.657-12.82
        				c0-3.62,0-7.239,0-10.858c0-0.451,0-0.901,0-1.352c-0.833,0.833-1.667,1.667-2.5,2.5c43.078,2.508,86.646,3.223,129.896,3.252
        				C762.943,295.378,762.943,290.378,759.719,290.376z"/>
        		</g>
        	</g>
        	<g id="bottom_1_">
        		<path fill="#DE9127" d="M747.99,334.516c-26-1-90.506,3.068-116.506-2.932c-1,0-1.255-5.866-2.005-6.46
        			c0,0,128.148-2.689,130.177-0.335C760.714,326.017,747.99,334.516,747.99,334.516z"/>
        	</g>
        	<g id="trailings1">
        		<path fill="#558FC9" d="M619,333c-12,10-36,5-50,5c-18,0-30,10-46,11"/>
        	</g>
        	<g id="trailings">
        		<path fill="#558FC9" d="M619,325c-32-2-65-4-97,3"/>
        	</g>
        </g>
      )}
      <g id="clouds">
      	<g>
      		<path fill="#F9FCF3" d="M891.726,102.22c-10.389,0.493-18.179,8.871-8.31,16.264c13.501,9.855,23.891,0.985,37.395-2.465
      			c0,11.827,7.79,15.771,19.734,15.771c15.063,0,23.893-5.914,35.317-14.292c15.063,11.335,36.356,0,31.683-18.234
      			c-4.674-18.235-34.278-12.321-47.782-6.899c3.635-8.378-7.271-19.22-16.619-17.742c-9.869,1.479-10.389,13.799-19.218,16.263
      			c3.116-13.306-23.891-10.35-30.125-4.436c-10.385,10.35-6.231,27.598,8.831,30.063"/>
      	</g>
      	<g>
      		<path fill="#F9FCF3" d="M864.549,80.18c-3.588,2.485,6.313,1.936,10.814,1.909c18.009-0.597,31.467-9.053,14.313-16.347
      			c-23.464-9.72-41.427-0.746-64.821,2.838c-0.066-11.826-13.596-15.691-34.305-15.573c-26.116,0.15-41.392,6.153-61.151,14.646
      			c-26.181-11.185-63.034,0.362-54.824,18.55c8.208,18.188,59.501,11.977,82.883,6.422c-6.252,8.413,12.718,19.147,28.916,17.574
      			c17.104-1.577,17.935-13.902,33.228-16.454c-5.326,13.337,41.48,10.11,52.256,4.134c17.944-10.454,10.644-27.659-15.485-29.974
      			L864.549,80.18z"/>
      	</g>
      	<g>
      		<path fill="#F9FCF3" d="M552.309,136.2"/>
      	</g>
      	<g>
      		<path fill="#F9FCF3" d="M529.825,190.318c20.261-6.398,18.395-24.861-2.212-32.136c-28.189-9.693-49.788-0.697-77.909,2.914
      			c-0.066-11.826-16.321-15.676-41.21-15.533c-31.388,0.181-49.753,6.201-73.512,14.717c-31.452-11.154-75.758,0.436-65.912,18.614
      			c9.843,18.179,112.456,22.923,140.564,17.34"/>
      	</g>
      	<g>
      		<path fill="#F9FCF3" d="M9,90.75c5.92,11.841,57.556,24.036,69.5,24.036c15.063,0,44.718,3.843,56.143-4.535
      			c26.607,6.75,33.516-11.71,28.842-29.944c-4.674-18.235-34.278-12.321-47.782-6.899c3.635-8.378-7.271-19.22-16.619-17.742
      			c-9.869,1.479-10.389,13.799-19.218,16.263c3.116-13.306-59.615-2.428-64.55,3.901C15.315,75.83,4.25,81.25,9,90.75z"/>
      	</g>
      	<g>
      		<path fill="#F9FCF3" d="M1270.354,199.618c20.261-6.398,18.395-24.861-2.212-32.136c-28.188-9.693-49.788-0.697-77.909,2.914
      			c-0.066-11.826-16.321-15.676-41.211-15.533c-31.388,0.181-49.753,6.201-73.512,14.717c-31.451-11.154-75.758,0.436-65.911,18.614
      			c9.843,18.179,112.456,22.923,140.563,17.34"/>
      	</g>
      	<g>
      		<path fill="#F9FCF3" d="M1337.549,94.526c-3.588,2.485,6.313,1.936,10.814,1.909c18.009-0.597,31.467-9.053,14.313-16.347
      			c-23.464-9.72-41.427-0.746-64.821,2.838c-0.066-11.826-13.596-15.691-34.305-15.573c-26.116,0.15-41.392,6.153-61.151,14.646
      			c-26.181-11.185-63.034,0.362-54.824,18.55c8.208,18.188,59.501,11.977,82.883,6.422c-6.252,8.413,12.718,19.147,28.916,17.574
      			c17.104-1.577,17.935-13.902,33.228-16.454c-5.326,13.337,41.48,10.11,52.256,4.134c17.944-10.454,10.644-27.659-15.485-29.974
      			L1337.549,94.526z"/>
      	</g>
      </g>
      <g id="floor_1_">
      	<rect id="flooring" x="-13" y="712.006" fill="#1E1E1E" width="1013" height="296.994"/>
      	<rect id="left" x="205" y="46.503" fill="#1E1E1E" width="10" height="665.503"/>
      	<rect id="right" x="793" y="46.503" fill="#1E1E1E" width="10" height="665.503"/>
      	<rect id="bottom" x="-61.482" y="699" fill="#1E1E1E" width="1087" height="13.006"/>
      	<rect id="top" x="-25.833" y="40" fill="#1E1E1E" width="1069.833" height="13.006"/>
      	<rect id="wallpaper" x="-25.833" y="-57" fill="#CDE9F9" width="1069.833" height="97"/>
        <g id="windows">
      		<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="783.1748" y1="-100.4683" x2="232.9929" y2="852.4747">
      			<stop  offset="0" style={{stopColor:'#000000'}} />
      			<stop  offset="0" style={{stopColor:'#000000'}}/>
      			<stop  offset="0.5323" style={{stopColor:'#fff'}}/>
      			<stop  offset="0.9355" style={{stopColor:'#000000'}}/>
      			<stop  offset="1" style={{stopColor:'#000000'}}/>
      		</linearGradient>
      		<rect x="-32.833" y="53.006" opacity="0.8" fill="url(#SVGID_1_)" fill-opacity="0.4" width="1081.833" height="645.994"/>
      	</g>
      </g>
      <g id="desk">
      	<polygon id="table-side" fill="#715A24" points="909,842.001 925.247,842.001 925.247,609.088 837,605.5 837,752.999 	"/>
      	<line fill="#FFFFFF" stroke="#866C2A" stroke-width="35" stroke-miterlimit="10" x1="0" y1="609.088" x2="942.569" y2="609.088"/>

      		<line fill="#FFFFFF" stroke="#866C2A" stroke-width="35" stroke-miterlimit="10" x1="925.247" y1="594.011" x2="926.753" y2="842.001"/>
      	<polygon fill="#95772D" points="0,563.009 870.334,563.009 942.569,594.011 0,594.011 	"/>
      </g>
      {!isMobile && ([
        <g id="desktop">
          <path fill="#B0B9C6" d="M512.667,557.91c0,6.232-4.179,5.051-9.333,5.051l0,0c-5.155,0-9.334,1.182-9.334-5.051v-46.126
            c0-6.232,4.179-11.284,9.334-11.284l0,0c5.154,0,9.333,5.052,9.333,11.284V557.91z"/>
          <rect
            fill="#D3E0F3"
            id="screen"
            x="calc(50% - (100vw / 6) / 2)"
            y="calc(50% - (100vh / 6) / 2)"
            width="calc((100vw / 6))"
            height="calc((100vh / 6))"
          />
          <polygon id="base" fill="#1E1E1E" points="605.143,587.237 510.948,587.237 508.08,567.357 590.757,567.095 	"/>
          <path id="mouse" fill="#B6B6B7" d="M590.97,579.65c0.402,1.671-1.318,3.518-3.846,4.126l0,0c-2.527,0.609-4.901-0.252-5.304-1.922
            l-1.791-7.432c-0.402-1.67,1.319-3.518,3.849-4.127l0,0c2.525-0.608,4.899,0.253,5.302,1.922L590.97,579.65z"/>
          <path id="cord1" fill="none" stroke="#D3E0F3" stroke-miterlimit="10" d="M583.501,570.666c0,0-2.606-3.257-3.834-3.828
            c-5.002-2.328-7.926-2.512-13.417-3.045c-2.212-0.215-17.25-0.784-17.25-0.784"/>
          <path id="cord2" fill="none" stroke="#D3E0F3" stroke-miterlimit="10" d="M471.285,571.707c0,0,1.622-3.699,2.385-4.349
            c3.112-2.645,4.931-2.854,8.348-3.459c1.376-0.244,10.732-0.891,10.732-0.891"/>
          <polygon id="keyboard" fill="#EAEAEA" points="486.266,580.334 404.397,580.334 408.476,570.182 488.25,570.182 	"/>
          <rect
            fill="#000000"
            id="screen"
            x="calc(50% - (100vw / 6) / 2)"
            y="calc(50% - (100vh / 6) / 2)"
            width="calc((100vw / 6))"
            height="calc((100vh / 6) - 10px)"
          />
          {
        //   <line
        //     id="left-side"
        //     fill="none"
        //     stroke="#F9F9FA"
        //     stroke-width="7"
        //     stroke-miterlimit="10"
        //     x1="calc(50% - (100vw / 6) / 2 + 30)"
        //     x2="calc(50% - (100vw / 6) / 2 + 30)"
        //     y1="calc(50% - (100vh / 6) / 2)"
        //     y2="calc(50% - (100vh / 6) / 2 + 25)"
        //   />
        //   <line
        //     id="right-side"
        //     fill="none"
        //     stroke="#F9F9FA"
        //     stroke-width="7"
        //     stroke-miterlimit="10"
        //     x1="calc(50% + (100vw / 6) / 2 - 30)"
        //     x2="calc(50% + (100vw / 6) / 2 - 30)"
        //     y1="calc(50% - (100vh / 6) / 2)"
        //     y2="calc(50% - (100vh / 6) / 2 + 25)"
        //   />
        //   <rect
        //     id="ball"
        //     x="calc(50% - 3.5px)"
        //     y="455.548"
        //     fill="#F9F9FA"
        //     width="7"
        //     height="7"
        //   />
        //
        //   <rect id="mid5" x="499.548" y="calc(50% - (100vh / 6) / 2)" fill="#F9F9FA" width="7" height="7"/>
        //   <rect id="mid4" x="499.548" y="calc(50% - (100vh / 6) / 2 + 14)" fill="#F9F9FA" width="7" height="7"/>
        //   <rect id="mid3" x="499.548" y="calc(50% - (100vh / 6) / 2 + 28)" fill="#F9F9FA" width="7" height="7"/>
        //   <rect id="mid2" x="499.548" y="calc(50% - (100vh / 6) / 2 + 42)" fill="#F9F9FA" width="7" height="7"/>
        //   <rect id="mid1" x="499.548" y="calc(50% - (100vh / 6) / 2 + 56)" fill="#F9F9FA" width="7" height="7"/>
        //   <rect id="mid" x="499.548" y="calc(50% - (100vh / 6) / 2 + 70)" fill="#F9F9FA" width="7" height="7"/>
        }
        </g>,
        <g id="desktop-human">
          <g id="neck_1_" display="inline">
            <path fill="#BA9866" d="M678.772,392.785c0,6.667-4.771,22.667-2.12,28c3.711,6.667,16.964,4,22.265,4.667
              c4.771,0,20.676,2,23.326-4c2.12-4.667-5.301-22.667-5.831-28.667H678.772z"/>
          </g>
          <g id="face_1_" display="inline">
            <path fill="#D6B06E" d="M615.426,263.677c-3.61,62,18.16,131.843,79.523,133.843c24.364,1,49.959-4.788,63.495-29.788
              c12.634-22,9.927-53,9.927-78c0-6,0.244-20.055,0.244-26.055"/>
          </g>
          <g display="inline">
            <path fill="#20386C" d="M762.744,621.943c-6,39.872-2,76.896,0,116.768c0.5,15.663,1,31.327,1.5,46.991
              c0,9.969,1,19.937,1.5,29.904c0.5,8.544,4,22.783,2.5,32.04c-1.5,9.255-6.5,6.407-13,6.407c-7.5,0-16,2.136-19-7.119
              c-5-13.528-1.5-40.584-2-54.824c-1-42.008-2-79.743-9-121.039c-1-5.696-2-11.392-2.5-17.801l-4.767-5.162
              c-0.5,6.409-1.5,12.104-2.5,17.801c-7,41.296-8,79.031-9,121.039c-0.5,14.24,3,41.296-2,54.824c-3,9.255-11.5,7.119-19,7.119
              c-6.5,0-11.5,2.848-13-6.407c-1.5-9.257,2-23.496,2.5-32.04c0.5-9.968,1.5-19.936,1.5-29.904c0.5-15.664,1-31.328,1.5-46.991
              c2-39.871,6-76.896,0-116.768L762.744,621.943z"/>
          </g>
          <g id="phone-arm_2_" display="inline">
            <path fill="#D6B06E" d="M766.932,471.302c5.071,28.751,11.987,52.882,24.896,78.554c3.689,8.214,18.903,28.751,5.994,32.859
              c-10.604,3.08-27.202-8.216-31.813-17.971c-5.994-12.321-6.454-29.265-7.837-43.127c-1.846-14.376-3.688-28.751-6.455-43.641
              L766.932,471.302z"/>
          </g>
          <g id="desktop-arm" display="inline">
            <path fill="#D6B06E" d="M645.119,472.17c-2.472,25.861-7.334,47.879-18.328,71.96c-3.054,7.652-16.975,27.494-3.031,29.578
              c11.423,1.453,27.709-10.433,31.578-19.548c5.064-11.534,3.871-26.475,3.948-38.818c0.511-12.849,1.021-25.696,2.447-39.105
              L645.119,472.17z"/>
          </g>
          <g id="desktop-shirt_1_" display="inline">
            <path fill="#022537" d="M742.362,405.805c14.667,8.66,36.117,43.833,31.5,64c-2.043,8.921-17.287,14.604-23.5,11
              c-7.734-4.487-9.247-0.923-9.625-19c0,0,8.721,49.291,12.121,70.82c3.449,21.836,20.337,84.84,20.337,84.84
              c-4.739,28.564-16.333,23.34-33.333,23.34l-26.667,0.375c-17,0-40.198,1.604-44.938-26.961l-0.064-84.842l-2.331-64.072
              c-0.378,18.077-1.077,17.3-8.812,21.787c-6.213,3.604-17.7,2.634-19.743-6.287c-4.617-20.167,3.092-76.433,26.422-73.413
              c15.324,1.983,26.885,8.974,41.777,8.083C716.82,414.797,734.29,401.039,742.362,405.805z"/>
          </g>
          <g id="smile_1_" display="inline">
            <g>
              <path fill="#7D6541" d="M673.457,360.712c6.87,9.249,16.327,14.633,28.024,14.045c10.568-0.531,20.826-7.044,26.292-16.045
                c1.679-2.764-2.646-5.274-4.316-2.523c-4.042,6.655-12.443,13.076-20.509,13.429c-10.535,0.46-18.745-2.775-25.175-11.429
                C675.874,355.632,671.531,358.121,673.457,360.712L673.457,360.712z"/>
            </g>
          </g>
          <g id="hair_1_" display="inline">
            <path fill="#3D3315" d="M630.115,327.534c-15.33,1.492,1.716-11.157-10.833-19.079c-4.48-2.829-7.769-10.278-3.856-44.778
              c1.039-9.161,4.654-13.808,12.689-17.893c9.834-5,28.588-12.038,33.588-4.038c13.912-26.462,37.818-13.962,56.818-7.962
              c27,9,27.811,10.312,50.094,27.333c12,9.167,10.06,22.593,8.06,32.644c-20,12.5-17.06-3.978-39.06-9.478
              c-24.5-6-13.039-5.168-37-13.879c-15.342-5.578-52.25-11.871-64.25,8.629"/>
          </g>
          <ellipse id="eye2_1_" display="inline" fill="#7D6541" cx="675.616" cy="308.455" rx="7.094" ry="6.876"/>
          <ellipse id="eye1_1_" display="inline" fill="#7D6541" cx="725.616" cy="310.856" rx="7.094" ry="6.876"/>
          <g id="ear" display="inline">
            <path fill="#D6B06E" d="M620.369,310.784c-3-5.75-9.25-7.25-14-2.5s-5.25,12.5-4,18.75c1.25,5.5,5,12.75,10.25,15.5
              c6.25,3.25,13-1.75,12.25-8.75L620.369,310.784z"/>
          </g>
        </g>
      ])}
      {isMobile && ([
        <g id="mobile-human">
        	<g id="neck_2_">
        		<path fill="#BA9866" d="M485.501,389.785c0,6.667-4.771,22.667-2.12,28c3.711,6.667,16.964,4,22.265,4.667
        			c4.771,0,20.676,2,23.326-4c2.119-4.667-5.301-22.667-5.832-28.667H485.501z"/>
        	</g>
        	<g id="face_2_">
        		<path fill="#D6B06E" d="M422.155,260.677c-3.61,62,18.16,131.843,79.523,133.843c24.365,1,49.959-4.788,63.496-29.788
        			c12.633-22,9.926-53,9.926-78c0-6,0.244-20.055,0.244-26.055"/>
        	</g>
        	<g>
        		<path fill="#20386C" d="M569.473,618.943c-6,39.872-2,76.896,0,116.768c0.5,15.663,1,31.327,1.5,46.991
        			c0,9.969,1,19.937,1.5,29.904c0.5,8.544,4,22.783,2.5,32.04c-1.5,9.255-6.5,6.407-13,6.407c-7.5,0-16,2.136-19-7.119
        			c-5-13.528-1.5-40.584-2-54.824c-1-42.008-2-79.743-9-121.039c-1-5.696-2-11.392-2.5-17.801l-4.766-5.162
        			c-0.5,6.409-1.5,12.104-2.5,17.801c-7,41.296-8,79.031-9,121.039c-0.5,14.24,3,41.296-2,54.824c-3,9.255-11.5,7.119-19,7.119
        			c-6.5,0-11.5,2.848-13-6.407c-1.5-9.257,2-23.496,2.5-32.04c0.5-9.968,1.5-19.936,1.5-29.904c0.5-15.664,1-31.328,1.5-46.991
        			c2-39.871,6-76.896,0-116.768L569.473,618.943z"/>
        	</g>
        	<g id="phone-arm_1_">
        		<path fill="#D6B06E" d="M573.66,468.302c5.072,28.751,11.988,52.882,24.896,78.554c3.689,8.214,18.904,28.751,5.994,32.859
        			c-10.604,3.08-27.201-8.216-31.813-17.971c-5.994-12.321-6.455-29.265-7.838-43.127c-1.846-14.376-3.688-28.751-6.455-43.641
        			L573.66,468.302z"/>
        	</g>
        	<g id="desktop-shirt_2_">
        		<path fill="#022537" d="M549.092,402.805c14.666,8.66,36.117,43.833,31.5,64c-2.043,8.921-17.287,14.604-23.5,11
        			c-7.734-4.487-9.248-0.923-9.625-19c0,0,8.721,49.291,12.121,70.82c3.449,21.836,20.336,84.84,20.336,84.84
        			c-4.738,28.564-16.332,23.34-33.332,23.34l-26.668,0.375c-17,0-40.198,1.604-44.937-26.961l-0.064-84.842L475,444.333
        			c-0.378,18.077,6.592,24.846-1.142,29.333c-6.213,3.604-15.719,7.667-24.191-12.333c-8.07-19.05-2.538-59.961,20.792-56.941
        			c15.324,1.983,26.885,8.974,41.778,8.083C523.549,411.797,541.02,398.039,549.092,402.805z"/>
        	</g>
        	<g id="desktop-arm_1_">
        		<path fill="#D6B06E" d="M462.754,453.849c16.726,4.755,29.891,7.119,50.95,5.687c6.415-0.157,6.963,1.13,7.833,8.464
        			c1.209,10.191-9.34,12.761-17.127,13.105c-9.96,0.549-17.971-3.967-25.26-6.98c-7.862-2.847-15.721-5.696-24.484-8.064
        			L462.754,453.849z"/>
        	</g>
        	<g id="smile_2_">
        		<g>
        			<path fill="#7D6541" d="M480.186,357.712c6.871,9.249,16.328,14.633,28.025,14.045c10.568-0.531,20.825-7.045,26.291-16.045
        				c1.679-2.764-2.646-5.274-4.316-2.523c-4.042,6.655-12.443,13.076-20.508,13.429c-10.536,0.46-18.746-2.775-25.175-11.429
        				C482.604,352.632,478.26,355.121,480.186,357.712L480.186,357.712z"/>
        		</g>
        	</g>
        	<g id="hair_2_">
        		<path fill="#3D3315" d="M436.844,324.534c-15.33,1.492,1.716-11.157-10.833-19.079c-4.48-2.829-7.769-10.278-3.856-44.778
        			c1.039-9.161,4.654-13.808,12.689-17.893c9.834-5,28.588-12.038,33.588-4.038c13.912-26.462,37.818-13.962,56.818-7.962
        			c27,9,27.811,10.312,50.094,27.333c12,9.167,10.061,22.593,8.061,32.644c-20,12.5-17.061-3.978-39.061-9.478
        			c-24.5-6-13.039-5.168-37-13.879c-15.341-5.578-52.25-11.871-64.25,8.629"/>
        	</g>
        	<ellipse id="eye2_2_" fill="#7D6541" cx="482.345" cy="305.455" rx="7.094" ry="6.876"/>
        	<ellipse id="eye1_2_" fill="#7D6541" cx="532.345" cy="307.856" rx="7.095" ry="6.876"/>
        	<g id="ear_1_">
        		<path fill="#D6B06E" d="M427.098,307.784c-3-5.75-9.25-7.25-14-2.5s-5.25,12.5-4,18.75c1.25,5.5,5,12.75,10.25,15.5
        			c6.25,3.25,13-1.75,12.25-8.75L427.098,307.784z"/>
        	</g>
        	<g>
        		<path fill="#022537" d="M481.738,448.236c-5.93-3.86-18.134-3.425-23.096,1.914c-2.193,2.398-8.497,15.528-3.968,17.559
        			c3.199,1.545,13.099-3.45,15.171-5.516c3.57-3.589,7.726-5.127,11.084-9.17L481.738,448.236z"/>
        	</g>
        	<g>
        		<path fill="#BA9866" d="M467.201,465.3c4.14-1.198,8.168-2.752,11.881-4.974c1.156-0.691,2.22-1.182,3.206-1.937
        			c-2.357-0.359-4.692-0.994-7.006-1.465c-2.02,0.801-4.073,1.512-6.219,2.012c-3.994,0.929-8.073,1.667-11.849,3.278l-2.548,3.848
        			c2.234,0.604,4.394,1.247,6.525,1.906C462.974,466.737,465.071,465.917,467.201,465.3z"/>
        	</g>
          <rect
            fill="#B0B9C6"
            id="screen"
            x="calc(50% - (100vw / 6) / 2)"
            y="calc(50% - (100vh / 6) / 2)"
            width="calc((100vw / 6))"
            height="calc((100vh / 6))"
          />
          <rect
            fill="#D3E0F3"
            id="screen"
            x="calc(50% - (100vw / 6) / 2)"
            y="calc(50% - (100vh / 6) / 2)"
            width="calc((100vw / 6))"
            height="calc((100vh / 6) - 10px)"
          />
        </g>
      ])}
      </svg>
    )}
    </Outside>
  )
};
