import React, { useState } from 'react';
import {
  Link,
  useLocation,
} from "react-router-dom";

import InnerBox from '../components/UI/InnerBox/InnerBox';
import OuterBox from '../components/UI/OuterBox/OuterBox';
import Socials from '../components/UI/Socials';

import Hero from '../components/UI/Hero/Hero';
import ExperimentalLogo from '../components/Experimentals/ExperimentalLogo';
import useAlternate from '../components/UI/TextAlternate/TextAlternate';
import { Home } from './Views.style';

import { projectData } from '../constants/projects';
import { workData } from '../constants/work';

const Experience = (({...rest}) => (
    <div className="outer-block home__skills-block no-top">
      <div className="inner-left home__skills-fill inner-fill home__skills-stick">
      </div>
      <div className="inner-left fit-height home__skills-title home__skills-stick">
        <h2 className="title__h2">
          Languages and Frameworks
        </h2>
      </div>
      <div className="inner-right home__skills-showcase">
        <h3 className="title__h3 home__skill" data-tier="1">
          JavaScript / TypeScript
        </h3>
        <h3 className="title__h3 home__skill" data-tier="1">
          Python
        </h3>
        <h3 className="title__h3 home__skill" data-tier="1">
          HTML/CSS
        </h3>
        <h3 className="title__h3 home__skill" data-tier="2">
          pHp
        </h3>
        <h3 className="title__h3 home__skill" data-tier="2">
          Java
        </h3>
        <h3 className="title__h3 home__skill" data-tier="3">
          SQL
        </h3>
      </div>
    </div>
));

const ExperienceMinor = React.memo(() => {
  const [experienceHover, setHover] = useState(0);

  const experienceData = [
    (<React.Fragment>
      <ul>
        <li>React Auth View Implementation</li>
        <li>Used i18n in a frontend and backend environment to support multi-language support on a global networking platform.</li>
        <li>3D Modeling using ThreeJS + AR.js</li>
        <li>Interactive map implementation</li>
        <li>Analytics through Google, GTM, Segment, and FB Pixel</li>
      </ul>
    </React.Fragment>),
    (<React.Fragment>
      <ul>
        <li>User auth implementation with MFA options</li>
        <li>Email Backend</li>
        <li>Data encryption</li>
        <li>Location anonymization</li>
      </ul>
    </React.Fragment>),
    (<React.Fragment>
      <p>
        Developed a fleet of sleek marketing and customer facing websites with an emphasis on user experience
      </p>
    </React.Fragment>),
    (<React.Fragment>
      <p>
        Developed a fleet of sleek ecommerce sites with an emphasis on user experience
      </p>
    </React.Fragment>),
    (<React.Fragment>
      <p>
        Implemented an events calendar with the ability to RSVP, create, and delete upcoming events for an organization of 30,000 users.
      </p>
    </React.Fragment>),
  ];

  return (
    <OuterBox dimension="calc(100% - 108px)" className="right top no-bottom mobile-full">
      <InnerBox dimension="30%" className="home__experience">
        <ul>
          <li
            onClick={() => setHover(0)}
            onMouseEnter={() => setHover(0)}
            data-hovered={experienceHover === 0}
          ><h3 className="title__h3">React</h3></li>
          <li
            onClick={() => setHover(1)}
            onMouseEnter={() => setHover(1)}
            data-hovered={experienceHover === 1}
          ><h3 className="title__h3">DJango</h3></li>
          <li
            onClick={() => setHover(2)}
            onMouseEnter={() => setHover(2)}
            data-hovered={experienceHover === 2}
          ><h3 className="title__h3">Wordpress</h3></li>
          <li
            onClick={() => setHover(3)}
            onMouseEnter={() => setHover(3)}
            data-hovered={experienceHover === 3}
          ><h3 className="title__h3">Shopify</h3></li>
          <li
            onClick={() => setHover(4)}
            onMouseEnter={() => setHover(4)}
            data-hovered={experienceHover === 4}
          ><h3 className="title__h3">Laravel</h3></li>
        </ul>
      </InnerBox>
      <InnerBox dimension="70%" className="home__experience-box">
        {experienceData[experienceHover]}
      </InnerBox>

    </OuterBox>
    );
});

// const ProjectDescription = React.memo(() => {

const Projects = React.memo(() => {
  const [reveal, setReveal] = useState(-1);

  return (
    <React.Fragment>
      <OuterBox dimension="100%" className="center no-bottom">
        <InnerBox className=""><h2 className="title__h2">Recent Projects</h2></InnerBox>
      </OuterBox>
      <OuterBox dimension="100%" className="center">
        <InnerBox className="no-pad">
          <div className="home__projects-accordion">
              {projectData.slice(0, 2).map((project, index) => ([
                <button data-project={project.name} key={project.name} data-selected={reveal === index} onClick={() => setReveal(reveal !== index ? index : -1)} className="home__projects-item">
                  <h3 className="item-title title__h3">{project.name}</h3>
                  <div className="item-meta">
                    <div className="item-role">{project.role}</div>
                    <div className="item-stack">{project.stack}</div>
                  </div>
                </button>,
                <div key={project.summary} className="item-description" data-expand={reveal === index}>
                  {project.summary}
                </div>
              ]))}
              <Link to={'/projects'} className="home__projects-item">
                <h3 className="item-title title__h3">View More Projects...</h3>
              </Link>
          </div>
        </InnerBox>
      </OuterBox>
    </React.Fragment>
  )
});

const WorkExperience = React.memo(() => {
  return (
    <React.Fragment>
      <OuterBox dimension="80%" className="center">
        <InnerBox className="home__career">
          <h2 className="title__h2">
            Work Experience
          </h2>
        </InnerBox>
      </OuterBox>
        {workData.map((work, index) => {
          const workLoad = [
            <InnerBox key={`image-${work}`} className="home__career-image d-none d-md-table-cell" dimension="20%">
              <img width="100%" src={`/assets/home/${work.logo}`} alt="cleanup" />
            </InnerBox>,
            <InnerBox key={`info-${work}`} dimension="80%">
              <h3 className="title__h3">{work.title}</h3>
              <h4 className="title__h4">{work.company}</h4>
              <p>{work.description}</p>
            </InnerBox>
          ];
          return (
            <OuterBox key={work.title} dimension="80%" className={`center ${work.classes || ''}`}>
              {/* index < 2 ? workLoad : workLoad.reverse() */}
              {workLoad}
            </OuterBox>
          );
        })}
    </React.Fragment>
  );
});


export default React.memo(() => {
  const alternate = useAlternate(['an Engineer', 'an Innovator', 'a Creative', 'Sam'], 2000)
  const loc = useLocation();
  return (
    <Home colorKey={loc.pathname}>
      <div className="container">
        <div className="box" style={{ position: 'relative' }}>
          <Hero rightSide={<ExperimentalLogo />}>
            Hi. I'm { alternate } <br />
            I'm a Full Stack Engineer who creates slick and effective web experiences.
          </Hero>
          <OuterBox dimension="100%" className="top center">
            <InnerBox dimension="100%" className="tablet-hide"><h2 className="title__h2">About Me</h2></InnerBox>
          </OuterBox>
          <OuterBox dimension="100%" className="center">
            <InnerBox className="home__about">
              <p>Born and raised in Las Vegas -- Living in Los Angeles</p>
              <p>
                I've had a love for problem solving since an early age. I harness that passion by staying communicative,
                and providing constant insight on the most effective design and system architecture.
              </p>
              <p>
                I've worked in multiple tech stacks and enjoy collaborating on innovative platforms with people who dream big and work hard.
              </p>
              <p>
                My identity spans beyond my profession. I believe in seeing people beyond their titles, which makes for successful collaboration.
              </p>
            </InnerBox>
          </OuterBox>
          <OuterBox className="home__skills-connectors inner-fill" />
          <Projects />
          <OuterBox className="home__skills-connectors inner-fill" />
          <Experience />
          <ExperienceMinor />
          <OuterBox className="home__skills-connectors inner-fill top" />
          <WorkExperience />

          <OuterBox className="top">
            <InnerBox className="d-none d-md-table-cell align-middle" dimension="20%">
              <div className="footer__attention">
                Made from scratch in React
              </div>
            </InnerBox>
            <InnerBox dimension="80%">
              <Socials />
            </InnerBox>
          </OuterBox>
          <p className="footer__credit">Design inspired by the&nbsp;
            <a rel="noreferrer noopener" href="https://contract-district.com/architetti/" target="_blank">
            Milano Contract District
            </a>
          </p>
        </div>
      </div>
    </Home>
  );
});
