import styled from 'styled-components';


export default styled.div`
  position: relative;
  width: 100%;
  height: 200vh;
  overflow: hidden;

  .screen-contact {
    position: absolute;
    min-width: 200px;
    min-height: 100px;
    height: 100vh;
    width: 100vw;
    left: 0;
    background-color: ${(({ theme }) => theme.colors.black)};
    color: ${(({ theme }) => theme.colors.white)};
    background-size: 2000px;
    background-position: center;
  }

  .screen-contact-form {
    position: fixed;
    bottom: 50%;
    left: 50%;
    padding: 50px;
    opacity: 0;
    transition: 0.3s all ease-in-out;
    transform: translate(-50%, 50%);
    max-width: 400px;
    max-height: 400px;
    user-select: none;
    pointer-events: none;

    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      max-height: 95vh;
      width: 80vw;
      max-width: calc(100vw - 15px);
    }

    border: 3px solid ${(({ theme }) => theme.colors.white)};
    background: ${(({ theme }) => theme.colors.fadedWhite)};

    &.stick {
      opacity: 1;
      user-select: auto;
      pointer-events: auto;

    }

    .exit {
      cursor: pointer;

      display: block;
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      background: transparent;
      border: none;
      padding: 10px;
      span {
        display: block;
        transition: 0.1s all ease-in;
        border: 4px solid ${(({ theme }) => theme.colors.white)};
        width: 40px;
        height: 40px;
      }

      &:hover {
        span {
          height: 36px;
          width: 36px;
        }
      }
    }

    .screen-subhead {
      color:  ${(({ theme }) => theme.colors.white)};
      text-align: center;
    }

    .form-links {
      overflow: hidden;
      display: block;
      text-align: center;
      transition: 0.4s all ease-in-out;
      opacity: 1;
      max-width: 1000px;
      max-height: 1000px;

      .form-link {
        margin: 5px auto;
        transition: 0.4s all ease-in-out;
        display: block;
        padding: 20px;
        color:  ${(({ theme }) => theme.colors.white)};
        border: 1px solid ${(({ theme }) => theme.colors.white)};
        background: ${(({ theme }) => theme.colors.fadedWhite)};
        font-weight: bold;
        text-align: center;
        text-decoration: none;

        &:hover {
          color:  ${(({ theme }) => theme.colors.offWhite)};
          background: ${(({ theme }) => theme.colors.white)};
        }
      }
    }

    &[data-exited="true"] {
      width: 40px;
      height: 40px;
      overflow: hidden;
      padding: 0;
      bottom: 50px;
      left: 50px;

      .form-links {
        pointer-events: none;
        opacity: 0;
        max-width: 0px;
        max-height: 0px;
      }
      .exit {
        opacity: 0;
      }

      &:hover {
        padding: 2px;
      }

      @media ${(({ theme }) => theme.breakpoints.mobile)} {
        left: unset;
        right: 0px;
        bottom: 100px;
      }
    }
  }

  .made-by {
    transition: 0.4s all ease-in-out;
    position: fixed;
    bottom: -20px;
    right: 0;
    opacity: 0;
    max-width: 100%;
    color: white;

    &.stick {
      opacity: 1;
      bottom: 0px;
    }
  }
`;
