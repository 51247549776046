import React, { useState, useEffect} from 'react';
import {
  Link,
  useParams,
} from "react-router-dom";

import ReactMarkdown from 'react-markdown';
import articles from '../md/articleDictionary';

import Password from '../views/Password';
import InnerBox from '../components/UI/InnerBox/InnerBox';
import OuterBox from '../components/UI/OuterBox/OuterBox';
import { Article } from './Views.style';

import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {oneDark as ideStyle} from 'react-syntax-highlighter/dist/esm/styles/prism'

export default ({ props }) => {
  const { slug } = useParams();
  /*
    name
    subheading
    subheadingSmall
    hero
    copy
    tags
  */
  const [ article, setArticle ] = useState({});
  const [ md, setMd] = useState('');
  const [passwordCorrect, setPassCorrect] = useState(false);


  useEffect(() => {
    if (articles[slug]) {
      const readmePath = require(`../md/${slug}.md`);

      fetch(readmePath)
        .then(response => {
          return response.text();
        })
        .then(text => {
          setMd(text);
        })
      setArticle(articles[slug]);
      // Handle scroll top
      if (!passwordCorrect) {
        document.documentElement.scrollTop = 0;
      }
    }
  }, [slug, passwordCorrect]);

  return (
      <Article
        key="body"
      >
        <div className="container">
          <div className="bread">
            <Link className="no-default" to="/">Home</Link>○
            <Link className="no-default" to="/blog">Blog</Link>○
            <span className="d-none d-md-inline">{article.name}</span>
          </div>
          <OuterBox
            className="top article__hero"
            styles={{ backgroundImage: `url(${article.hero})` }}
          >
            <InnerBox
              style={{color: article.color}}
              className="article__hero-box"
            >
              <div className="article__meta-holder">
                <h1>{article.name}</h1>
                <h2>{article.subheading}</h2>
                <h3>{article.subheadingSmall}</h3>
              </div>
            </InnerBox>
          </OuterBox>
          <OuterBox className="article__box">
            <InnerBox className="article__box-inner">
              { (article.password && !passwordCorrect ) ? (
                <Password pass={article.password} onCorrect={setPassCorrect} />
              ) : (
                <ReactMarkdown
                  linkTarget="_blank"
                  children={md}
                  components={{
                    code: ({node, inline, className, children, ...props}) => {
                      console.log(';HITS');
                      const match = /language-(\w+)/.exec(className || '')
                      return !inline && match ? (
                        <SyntaxHighlighter
                          children={String(children).replace(/\n$/, '')}
                          style={ideStyle}
                          language={match[1]}
                          PreTag="div"
                          {...props}
                        />
                      ) : (
                        <code className={className} {...props}>
                          {children}
                        </code>
                      )
                    }
                  }}
                />
              )}
            </InnerBox>
          </OuterBox>
          <OuterBox className="article__box">
            <InnerBox className="article__box-inner p-0 m-0">
              <div className="d-flex m-0 justify-content-center">
                <div className="p-3 m-0">If this was helpful, consider sharing:</div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`//www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}
                  className="no-default pe-auto p-3 m-0"
                >
                  <img
                    src="/assets/nav/linkedin.svg"
                    alt="linkedin"
                    className="m-0"
                  />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`//twitter.com/share?text=${article.name}&url=${window.location.href}`}
                  className="no-default pe-auto p-3 m-0"
                >
                  <img
                    src="/assets/nav/twitter.svg"
                    alt="linkedin"
                    className="m-0"
                  />
                </a>
              </div>
            </InnerBox>
          </OuterBox>
        </div>
      </Article>
  )
};
