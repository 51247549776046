import React, { useState } from 'react';
import TextAlternateStyle from './TextAlternate.style';

const useAlternate = (list, delay=1000, loop=false) => {
  const [currentIndex, setcurrentIndex] = useState(0);
  const [currentEntry, setEntry] = useState(list.length ? list[0] : '');

  // useEffect(() => {
  //
  // }, [currentEntry, delay, currentIndex, list]);

  setTimeout(() => {
    if (list.length !== currentIndex) {
      setEntry(list[currentIndex]);
      const newIndex = currentIndex + 1;
      setcurrentIndex(loop && newIndex === list.length ? 0 : newIndex);
    }
  }, delay);

  return (
    <TextAlternateStyle>
      <div data-index={currentIndex} className="alternate-box">
        { list && list.map((phrase, i) => (
          <div key={phrase} className="">
            {phrase}
          </div>
        ))}
      </div>
    </TextAlternateStyle>
  );

};


export default useAlternate;
