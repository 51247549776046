import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import navLinks from '../../../constants/nav';
import HeroStyles from './Hero.style';

const Hero = ({ children, colorKey, rightSide, ...rest }) => {
  const location = useLocation().pathname;
  return (
  <HeroStyles colorKey={colorKey} {...rest}>
      <div className="outer-block site__hero-block">
        <div className="inner-left site__hero-summary align-text-bottom">
          <Link className="main__logo no-default" to="/">
            <div className="main__background" />
            <h1 className="d-none d-md-block">Sam Chami</h1>
          </Link>
          <h2 className="site__hero-copy">
            { children }
          </h2>
        </div>
        <div className="inner-right site__hero-nav d-none d-md-block">
          <div className="site__hero-links">
            {navLinks.map((n) => (
              <Link key={n.path} to={n.path} className="no-default" data-current={location === n.path}>
                {n.title}
              </Link>
            ))}
          </div>
          <div className="site__hero-right">
            {rightSide}
          </div>
        </div>
      </div>
    </HeroStyles>
  );
};

export default Hero;
