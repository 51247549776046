import styled from 'styled-components';

/*
  Generalized styles used within views. These are meant to show parallelism,
  and in the Homepage's case, provide styling :)
*/

const getBackgroundColor = (props) => {
  return props.theme.pages[props.colorKey] || props.theme.defaults.backgroundMain;
}

const BACKGROUND_TRANSITION =  `0.3s background-color`;

export const Home = styled.div`

    /*
    * ----------------------------
    *           PROJECTS
    * ----------------------------
    */

    .home__projects-accordion {
      [data-expand] {
        max-height: 0px;
        position: relative;
        overflow: hidden;
        padding: 0px 24px;
        color: ${getBackgroundColor};
        transition: 0.2s all ease-in-out;
      }
      [data-expand="true"] {
        max-height: 1000px;
        padding: 24px;
        opacity: 1;
        color: ${(({ theme }) => theme.defaults.text)};
      }

      [data-selected] {
        position: relative;

        &:after {
          position: absolute;
          font-size: 28px;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
          content: "+";
          color: ${(({ theme }) => theme.defaults.text)};
        }
      }

      [data-selected="true"] {
        background-color: ${(({ theme }) => theme.defaults.text)} !important;
        color: ${getBackgroundColor};

        &:after {
          color: ${getBackgroundColor};
          content: '-';
        }
      }
    }

       .item-summary {
         border-top: ${(({ theme }) => theme.shorthand.border)};
         img {
           display: block;
           margin: 0 auto;
           text-align: center;
           width: 100%;
         }
         &:nth-child(odd) {
           border-right: ${(({ theme }) => theme.shorthand.border)};

           @media ${(({ theme }) => theme.breakpoints.mobile)} {
             border-right: none;

           }

         }
       }

      .item-description {
        a {
          text-decoration: underline;
        }
        img {
          display: block;
          margin: 0 auto 12px;
          text-align: center;
          margin-bottom: 12px;
          width: 100%;
        }
      }

      .home__projects-item {
        display: block;
        width: 100%;
        padding: 12px 24px;
        padding-right: 40px;
        background: none;
        border: none;
        border-top: ${(({ theme }) => theme.shorthand.border)};
        outline: none;
        text-align: left;

        .item {
          &-title {
            display: inline-block;
            vertical-align: middle;
            width: 50%;

            @media ${(({ theme }) => theme.breakpoints.mobile)} {
              display: block;
              width: 100%;
            }

            @media ${(({ theme }) => theme.breakpoints.desktop)} {
              width: 40%;
            }
          }
          &-meta {
            vertical-align: middle;
            width: 50%;
            display: inline-block;

            justify-content: space-between;

            @media ${(({ theme }) => theme.breakpoints.mobile)} {
              width: 100%;
            }

            @media ${(({ theme }) => theme.breakpoints.desktop)} {
              width: 60%;
              display: inline-flex;

            }

          }
          &-role {
            font-size: 16px;
            @media ${(({ theme }) => theme.breakpoints.tablet)} {
              font-size: 14px;
            }

            @media ${(({ theme }) => theme.breakpoints.mobile)} {
              font-size: 12px;
            }
          }
          &-stack {
            font-size: 16px;
            @media ${(({ theme }) => theme.breakpoints.tablet)} {
              font-size: 10px;
            }
          }
        }
      }

    .home__skills-block {
      &:before {
        position: absolute;
        z-index: 0;
        left: 45px;
        width: 5px;
        height: 100%;
        border-left: 4px dotted ${(({ theme }) => theme.defaults.text)};

        @media ${(({ theme }) => theme.breakpoints.notMobile)} {
          content: "";
        }
      }

      position: relative;
      height: 238px;

      @media ${(({ theme }) => theme.breakpoints.mobile)} {
        height: unset;
      }

      .home__skills-fill {
        background-color: ${getBackgroundColor};

        &:before {
          position: absolute;
          content: "";
          top: calc(100% + 4px);
          left: 0;
          height: 240px;
          width: 100%;
          background-color: ${getBackgroundColor};

          @media ${(({ theme }) => theme.breakpoints.mobile)} {
            content: "";
          }
        }
        z-index: 2;

        height: 91px;
        border-bottom: ${(({ theme }) => theme.shorthand.border)};

        @media ${(({ theme }) => theme.breakpoints.mobile)} {
          display: none;
        }

        @media ${(({ theme }) => theme.breakpoints.tablet)} {
          border-right: none;
          width: 108px;
        }

        @media ${(({ theme }) => theme.breakpoints.desktop)} {
          width: 10%;
        }
      }

      .home__skills-stick {
        @media ${(({ theme }) => theme.breakpoints.notMobile)} {
          position: sticky;
          top: 50%;
          border-top: 4px solid black;
          margin-top: -4px;
          margin-bottom: -4px;
        }
      }

      .home__skills-title {
        width: 380px;
        line-height: 33px;
        font-size: 24px;
        padding: 25px 12px;
        text-align: center;

        @media ${(({ theme }) => theme.breakpoints.tablet)} {
          display: none;
        }

        @media ${(({ theme }) => theme.breakpoints.mobile)} {
          width: 100%;
          font-size: 20px;
          text-align: center;
          margin: 0;
          border-left: ${(({ theme }) => theme.shorthand.border)};
          border-right: ${(({ theme }) => theme.shorthand.border)};
        }
      }
      .home__skills-showcase {
        width: calc(90% - 380px);
        line-height: 43px;
        font-size: 41px;

        @media ${(({ theme }) => theme.breakpoints.tablet)} {
          width: calc(100% - 108px);
        }

        @media ${(({ theme }) => theme.breakpoints.mobile)} {
          height: auto;
          width: 100%;
        }

        .home__skill {
          position: relative;
          margin: 5px 0;
          display: block;
          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            margin: 6px 0px;
            width: 20px;
            text-align: right;
            height: 20px;
            float: right;
            margin-right: 0;
            background-image: url("assets/home/circle-filler.svg");
            background-size: 20px;
            background-position: top right;
          }

          &[data-tier="1"]:after {
            width: 200px;

            @media ${(({ theme }) => theme.breakpoints.mobile)} {
              width: 140px;
            }
          }
          &[data-tier="2"]:after {
            width: 140px;

            @media ${(({ theme }) => theme.breakpoints.mobile)} {
              width: 100px;
            }
          }
          &[data-tier="3"]:after {
            width: 100px;

            @media ${(({ theme }) => theme.breakpoints.mobile)} {
              width: 60px;
            }
          }
        }
      }
    }

    .home__experience {
      padding: 0;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
          transition: 0.4s all ease-in-out;
          padding: 12px;

          &[data-hovered="true"] {
            background: ${(({ theme }) => theme.defaults.text)};
            color: ${getBackgroundColor};
          }
        }
      }

      @media ${(({ theme }) => theme.breakpoints.mobile)} {
        display: block;
        width: 100% !important;
        border-right: ${(({ theme }) => theme.shorthand.border)};
      }
    }

    .home__experience-box {
      @media ${(({ theme }) => theme.breakpoints.mobile)} {
        border-top: ${(({ theme }) => theme.shorthand.border)};
        display: block;
        width: 100% !important;
        min-height: 300px;
      }
    }

    .home__about {
      font-size: 14px;
      line-height: 18px;
      padding: 46px;
    }

    .home__career {
      line-height: 37px;
      font-size: 24px;
    }

    .home__career-image {
      padding: 14px;
      vertical-align: middle;
    }

    .home__experience {
      vertical-align: middle;
    }
  }

`;

export const About = styled.div`
  .about__slider-block {
    position: relative;
    height: 400px;

    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      height: unset;
    }

    .about__slider-slick {
      width: 85%;
      float: left;
      margin-bottom: -8px;

      .slick-dots {
        bottom: 25px;
        width: 55%;
        left: 0;

        @media ${(({ theme }) => theme.breakpoints.mobile)} {
          display: none !important;
        }

        button {
          border-radius: 50%;
        }
      }
      .slick-slider {
        line-height: 0;
      }

      @media ${(({ theme }) => theme.breakpoints.mobile)} {
        width: 100%;
        float: unset;
      }
    }

    .about__slider-description {
      position: relative;

      height: 400px;
      padding: 25px;
      width: 55%;

      .home__project-copy {
        position: absolute;
        padding: 0 25px;
        left: 0;
        bottom: 55px;
      }

      @media ${(({ theme }) => theme.breakpoints.mobile)} {
        height: 40vh;
        width: 100%;
        border-bottom: none;

      }
    }
    .about__slider-showcase {
      padding: 0;

      height: 400px;
      width: 45%;
      overflow: hidden;

      @media ${(({ theme }) => theme.breakpoints.mobile)} {
        border-top: ${(({ theme }) => theme.shorthand.border)};
        border-left: ${(({ theme }) => theme.shorthand.border)};
        border-bottom: none;
        height: 40vh;
        width: 100%;
      }
    }

    .inner-arrows {
      z-index: 3;
      width: 15%;
      height: 40px;
      border-right: ${(({ theme }) => theme.shorthand.border)};
      position: absolute;
      right: 0;

      @media ${(({ theme }) => theme.breakpoints.mobile)} {
        width: 100%;
        position: relative;
        border: ${(({ theme }) => theme.shorthand.border)};
        border-bottom: none;
      }

      button {
        background: transparent;
        height: 100%;
        width: 50%;
        border: none;
        border-top: none;
        border-bottom: ${(({ theme }) => theme.shorthand.border)};
        &:first-child {
          border-right: ${(({ theme }) => theme.shorthand.border)};
        }
      }
    }
  }
`;

export const Article = styled.div`
    padding-top: 117px;

    .bread {
      position: relative;
      z-index: 1;
      a {
        cursor: pointer;

        &:hover {
          background: transparent;
        }
      }

      * {
        position: relative;
        font-size: 13px;
        padding: 0 12px;
        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          font-weight: 500;
        }
      }
    }

    .article__hero {
      background-size: cover;
      background-position: center;
      min-height: calc(100vh - 140px);

      .article__hero-box {
        backdrop-filter: blur(4px);
        background: rgba(255,255,255,0.1);
        vertical-align: middle;
        /* color: white; */
      }
    }

    .article__meta-holder {
      text-align: center;
      max-width: 460px;
      margin: auto;
      h1 {
        font-size: 46px;
        margin: 12px 0;
        text-align: center;
      }
      h2 {
        font-size: 24px;
      }
      h3 {
        font-size: 16px;
      }
    }

    .article__box {
      img {
        width: 100%;
        text-align: center;
        margin: 16px 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
      h2 {
        text-align: center;
      }

      &-inner {
        @media ${(({ theme }) => theme.breakpoints.mobile)} {
          padding: 12px;
        }

        h3 {
          margin-top: 4rem;
          margin-bottom: 1rem;
        }
        pre {
          margin-bottom: 1rem;
        }
        * {
          margin-bottom: 2rem;
        }

        code {
          background: rgba(255, 255, 255, 0.4);
        }

        pre {
          padding: 6px 0;

          > div {
            border-radius: 0 !important;
            border: ${(({ theme }) => theme.shorthand.border)};
          }
        }
        a {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
`;

export const Contact = styled.div`
  .contact-form {
    &__box {
      margin: 16px 0;
    }
    &__label {
      font-size: 24px;
    }
    &__input {
      transition: 0.1s border-color;
      padding: 8px;
      display: block;
      width: 100%;
      appearance: none;
      border-radius: 0;
      outline: none;
      background: rgba(255, 255, 255, 0.1);
      font-size: 16px;
      border: 4px solid ${getBackgroundColor};
      border-bottom: ${(({ theme }) => theme.shorthand.border)};

      &:focus {
        border-color: ${(({ theme }) => theme.defaults.text)};
      }
    }
    &__btn {
      margin-top: 24px;
      transition: 0.5s background, 0.5s color;
      background: ${(({ theme }) => theme.defaults.text)};
      color: ${getBackgroundColor};
      border: ${(({ theme }) => theme.shorthand.border)};
      width: 100%;
      padding: 12px;
      font-size: 24px;

      &:hover {
        background: rgba(255,255,255,.1);
        color:  ${(({ theme }) => theme.defaults.text)};
      }
    }

    &__success {
      min-height: 492px;
      position: relative;
      h2 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        text-align: center;
      }
    }
  }
`;

export default styled.div`
  padding: 50px 0;
  min-height: 100vh;
  cursor: url("assets/cursors/dot.svg"), auto;
  position: relative;
  color: ${(({ theme }) => theme.defaults.text)};
  /* transition: ${() => BACKGROUND_TRANSITION};
  background-color: ${getBackgroundColor};
  background-attachment: fixed; */

  .title {
    &__h2 {
      line-height: 33px;
      font-size: 24px;
      padding: 0;
      margin: 0;
    }

    &__h3 {
      line-height: 26px;
      font-size: 22px;
      padding: 0;
      margin: 0;
    }

    &__h4 {
      line-height: 22px;
      font-size: 18px;
      padding: 0;
      margin: 4px 0 8px;
    }

  }

  .inner-left {
    float: left;
    position: relative;
    height: 100%;
    border: ${(({ theme }) => theme.shorthand.border)};
    border-bottom: none;
    padding: 25px;
    border-top: none;

    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      float: unset;
    }

  }

  .inner-right {
    float: left;
    position: relative;
    height: 100%;
    border-right: ${(({ theme }) => theme.shorthand.border)};
    padding: 25px;

    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      float: unset;
    }

  }

  .inner-fill {
    background-image: url("assets/home/circle-filler.svg");
    float: left;
    background-size: auto 25%;
    background-position: top;

    & + .inner-left {
      border-left: none;
    }
    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      float: unset;
    }

  }

  .inner-left, .inner-right {
    &.fit-height {
      height: unset;
      border-right: none;
      border-bottom: ${(({ theme }) => theme.shorthand.border)};

    }
  }
  .fit-height + .inner-right {
    border-left: ${(({ theme }) => theme.shorthand.border)};
  }


  .outer-block {
    border-top: ${(({ theme }) => theme.shorthand.border)};
    width: auto;
    /* &:not(:first-child) {
      .inner-right, .inner-left {
        border-top: none;
      }
    } */

    &.no-top {
      border-top: none;
    }
  }

  .site__hero-links a {
    transition: ${() => BACKGROUND_TRANSITION}, 0.2s padding-right, 0.2s opacity;

    background-color: ${getBackgroundColor};
  }

  .main__logo {
    position: absolute;
    left: -25px;
    padding: 8px;
    top: 19px;
    border: 0;
    box-sizing: border-box;
    box-shadow: inset 0 0 0 4px transparent;
    font-size: inherit;
    font-weight: 700;

    .main__background {
      position: absolute;
      left: 20px;
      top:0;
      z-index: 0;
      width: 10px;
      transition: ${() => BACKGROUND_TRANSITION};
      background: ${getBackgroundColor};
      height: 100%;
    }

    h1 {
      position: relative;
      margin: 0;
    }

    &::before,
    &::after {
      box-sizing: inherit;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transform-origin: center;
      transition: transform 0.5s;
    }

    &:hover {
      background: ${getBackgroundColor};
      text-decoration: none;
      color: ${(({ theme }) => theme.defaults.text)};
      .main__background {
        transition: 1s;
        opacity: 0;
      }
    }

    &::before {
      border-top: ${(({ theme }) => theme.shorthand.border)};
      border-bottom: ${(({ theme }) => theme.shorthand.border)};
      transform: scale3d(0,1,1);
    }

    &::after {
      border-left: ${(({ theme }) => theme.shorthand.border)};
      border-right: ${(({ theme }) => theme.shorthand.border)};
      transform: scale3d(1,0,1);
    }

    &:hover::before,
    &:hover::after {
      transform: scale3d(1,1,1);
    }
  }

  .main-logo {
    background: ${getBackgroundColor};
  }

  .main-menu {
    background: ${getBackgroundColor};

    &__option {
      background: ${getBackgroundColor};
        &:first-child {
          @media ${(({ theme }) => theme.breakpoints.mobile)} {
            background: ${getBackgroundColor} !important;
            &:after {
              color: ${(({ theme }) => theme.defaults.text)};
            }

            &:hover {
              background: ${getBackgroundColor} !important;
            }
          }
        }
      }

      &__more {
        a {
          color: black;
          border-color: black;
          transition: ${() => BACKGROUND_TRANSITION}, 0.2 left, 0.2 opacity;

          background: ${getBackgroundColor};

          &:hover  {
            color: ${getBackgroundColor};
            background: black;
            transition: ${() => BACKGROUND_TRANSITION}, 0.2 opacity;
          }
        }
      }
    }

  .home__skills-connectors {
    float: unset;
    width: 100%;
    height: 84px;
    border-left: ${(({ theme }) => theme.shorthand.border)};
    border-right: ${(({ theme }) => theme.shorthand.border)};
    border-bottom: ${(({ theme }) => theme.shorthand.border)};
    margin-left: auto;
    margin-right: auto;

    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      margin: auto;
      width: 80%;
    }
  }

  .blog-category-holder {
    white-space: no-wrap;
  }

  .has-password {
    &:after {
      float: right;
      content: '🗝️';

    }
  }

  .home__blog-photo {
    background-size: cover;
    background-size: auto 100%;
    background-position: center;

    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      display: none;
    }
  }

  .home__blog-box {
    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      background: rgba(255,255,255,0.1);
      background-size: cover;
      backdrop-filter: blur(5px);
    }
  }

  .home__blog-article {
    background-size: cover;
    @media ${(({ theme }) => theme.breakpoints.notMobile)} {
      background: none !important;
    }
    min-height: 250px;

    .blog__article-tags {
      .blog__article-tag {
        display: inline-block;
        margin: 0 6px;
        padding: 0 6px;

        &:first-child {
          margin: 0;
        }

        &[data-filter-selected="true"] {
          float: left;
          background: ${(({ theme }) => theme.defaults.text)};
          color: ${getBackgroundColor};
        }
      }
    }
  }

  .home__blog-upper {
    a {
      padding: 12px;

      &[data-filtered="true"]{
        background: ${(({ theme }) => theme.defaults.text)};
        color: ${getBackgroundColor};
      }
    }
  }

  .home__blog-block {
    width: 80%;
    height: 500px;
    margin: auto;
  }

  .footer__attention {
    font-size: 12px;
    text-align: center;
  }
  .footer__credit {
    font-size: 12px;
    text-align: right;
    margin-bottom: 0;
    margin-top: 1rem;
    a {
      text-decoration: underline;
    }
  }

  .footer-link {
    border-radius: 50%;
    img {
      transition: 0.2s ease-in;
      margin: 0 16px;
      height: 40px;
      width: auto;
    }
    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      display: inline-block;
      margin: 8px 0;
    }

    &:hover {
      img {
        transform: rotate(20deg);
      }
    }
  }
  .icon-box {
    text-align: center;
    margin: auto;
    max-width: 432px;

    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      max-width: 285px;
    }

    &[data-condensed] {
      max-width: unset;
      white-space: nowrap;
      img {
        margin: 0px 12px;
        height: 32px;
      }

      @media ${(({ theme }) => theme.breakpoints.notMobile)} {
        margin-left: 0;
        text-align: left;
      }
    }
  }

  [data-password] {
    position: relative;
    width: 100%;
    height: calc(100vh - 100px);
    text-align: center;

    .password-box {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .username, .password {
      font-size: 30px;
      width: 400px;
      max-width: 90vw;
      border: none;
      background: none;
      border-bottom: 4px solid ${(({ theme }) => theme.defaults.text)};
      color: ${(({ theme }) => theme.defaults.text)};

      &:focus {
        outline: none;
      }
    }

    .big-circle {
      background: ${getBackgroundColor};
      transition: 1s max-height ease-in-out;
      transform: scale(1.1);
      max-height: 0px;
      opacity: 1;
      top: 0;
      left: 0;
      height: 100vh;
      overflow: hidden;

      &[data-redirect="true"] {
        position: absolute;
        width: 100%;
        max-height: 2000px;
      }
    }
  }

  [data-rmiz-wrap] {
    a, button {
      &:hover {
        background: ${(({ theme }) => theme.defaults.text)} !important;
        color: ${getBackgroundColor};
        opacity: 0.7;
        &:after {
          content: "Click to Zoom";
        }
      }

    }
  }

  a, button {
    transition: 0.2s ease-in-out;
    color: ${(({ theme }) => theme.defaults.text)};

    &:hover {
      cursor: url("assets/cursors/dot-hover.svg"), auto;
      color: ${(({ theme }) => theme.defaults.text)};

      &:not(.no-default) {
        background: ${(({ theme }) => theme.defaults.text)} !important;
        color: ${getBackgroundColor};
      }
    }
  }

  .hero-images {
    display: block;
    width: 100%;

    img {
      display: inline;
      opacity: 0;
      width: 12%;
      margin-right: 16px;
      height: auto;
      opacity: 1;
    }
    &[data-reveal="0"] {
      img:nth-child(0) {
        opacity: 1;
      }
    }
    &[data-reveal="1"] {
      img:nth-child(1) {
        opacity: 1;
      }
    }
  }
`;
