import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { TransitionGroup } from 'react-transition-group'

import 'bootstrap/dist/css/bootstrap.min.css';
import TagManager from 'react-gtm-module'
import ReactGA from 'react-ga';
import { Controller, Scene } from 'react-scrollmagic';

import FloatingNav from './components/FloatingNav/Nav';

import Home from './views/Home';
import Contact from './views/Contact';
import Blog from './views/Blog';
import Article from './views/Article';
import Resume from './views/Resume';
import Projects from './views/Projects';
import ProjectDetails from './views/ProjectDetails';
import AbstractCareer from './views/AbstractCareer';
import FriendPortal from './views/FriendPortal';

import ViewStyles from './views/Views.style';

import GlobalStyle from './App.style';
import theme from './global/global';

const Layout = ({ children, themeKey }) => (
  <main>
    <div className="trigger-section">
    <Controller>
      <Scene classToggle={['.main-nav', 'down-trigger']} reverse={true} indicators={false}>
        <div />
      </Scene>
    </Controller>
    </div>
    <div className="nom" />
      <ViewStyles colorKey={themeKey}>
        {children}
      </ViewStyles>
  </main>
)

const App = (props) => {
  const locationKey = useLocation();

  TagManager.initialize({
      gtmId: 'GTM-TMZZPZP',
  });
  ReactGA.initialize('UA-181209009-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [locationKey]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle colorKey={locationKey.pathname} />
        <Layout themeKey={locationKey.pathname}>
          <FloatingNav />
          <TransitionGroup transitionName="swipe"
            transitionAppear
            transitionAppearTimeout={500}
            transitionEnter={false}
            transitionLeave={false}
          >
              <Switch location={locationKey}>
                <Route path="/article/:slug">
                  <Article />
                </Route>
                <Route path="/blog">
                  <Blog />
                </Route>
                <Route path="/abstract">
                  <AbstractCareer />
                </Route>
                <Route path="/contact">
                  <Contact />
                </Route>
                <Route path="/resume">
                  <Resume />
                </Route>
                <Route path="/projects" exact="true">
                  <Projects />
                </Route>
                <Route path="/projects/:slug">
                  <ProjectDetails />
                </Route>
                <Route path="/friends">
                  <FriendPortal />
                </Route>
                <Route path="/">
                  <Home />
                </Route>
              </Switch>
          </TransitionGroup>
        </Layout>
    </ThemeProvider>
  )
}

const Portfolio = () => (
  <Router>
    <Route path="/" component={App} />
  </Router>
);


export default Portfolio;
