import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

import InnerBox from '../components/UI/InnerBox/InnerBox';
import OuterBox from '../components/UI/OuterBox/OuterBox';
import Hero from '../components/UI/Hero/Hero';
import { Contact } from './Views.style';

export default () => {
  const form = useRef();
  const [showForm, toggleShowForm] = useState(true);
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      process.env.REACT_APP_EMAIL_SERVICE_ID,
      process.env.REACT_APP_EMAIL_TEMPLATE_ID,
      form.current,
      process.env.REACT_APP_EMAIL_USER_ID,
    )
      .then((result) => {
          toggleShowForm(false);
      }, (error) => {
          alert("There was an issue with the form. I'm sorry! If you want, email me at sam@chami.io.")
      });
  };

  return (
      <Contact colorKey="/contact" className="container">
        <div className="box" style={{ position: 'relative' }}>
          <Hero className="bottom">
            Contact Me, let's collaborate!
          </Hero>
          <OuterBox dimension="80%" className="center">
            <InnerBox>
              {showForm ? (
                <form className="contact-form" ref={form} onSubmit={sendEmail}>
                  <div className="contact-form__box">
                    <label className="contact-form__label">Name</label>
                    <input className="contact-form__input" type="text" name="name" />
                  </div>
                  <div className="contact-form__box">
                    <label className="contact-form__label">Email</label>
                    <input className="contact-form__input" type="email" name="their_email" />
                  </div>
                  <div className="contact-form__box">
                    <label className="contact-form__label">Message</label>
                    <textarea rows="4" className="contact-form__input" name="message" />
                  </div>
                  <input className="contact-form__btn" type="submit" value="Send" />
                </form>
              ) : (
                <div className="contact-form__success">
                  <h2>
                    Thank you! We'll be in touch!
                  </h2>
                </div>
              )}
            </InnerBox>
          </OuterBox>
        </div>
      </Contact>
  )
};
