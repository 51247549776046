import React from 'react';

const Socials = ({ ...rest }) => {
  return (
    <div className="icon-box" {...rest}>
      <a
        target="_blank"
        className="footer-link no-default"
        href="mailto:sam@chami.io" rel="noopener noreferrer"
      >
        <img src="/assets/nav/email.svg" alt="email" />
      </a>
      <a
        target="_blank"
        className="footer-link no-default"
        href="https://www.linkedin.com/in/samchami" rel="noopener noreferrer"
      >
        <img src="/assets/nav/linkedin.svg" alt="linkedin" />
      </a>
      <a
        target="_blank"
        className="footer-link no-default"
        href="/SAMUEL_CHAMI_22.pdf" rel="noopener noreferrer"
      >
        <img src="/assets/nav/pdf.svg" alt="resume" />
      </a>
      <a
        target="_blank"
        className="footer-link no-default"
        href="https://github.com/samchami" rel="noopener noreferrer"
      >
        <img src="/assets/nav/github.svg" alt="github" />
      </a>
    </div>
  );
};

export default Socials;
