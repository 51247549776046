import styled from 'styled-components';


export default styled.div`
  position: relative;
  background: ${(({ theme }) => theme.colors.black)};
  color: ${(({ theme }) => theme.colors.white)};
  text-align: center;
  padding: 20px;

  span {
    font-size: 15px;
  }
`;
