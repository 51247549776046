import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import InnerBox from '../components/UI/InnerBox/InnerBox';
import OuterBox from '../components/UI/OuterBox/OuterBox';
import Hero from '../components/UI/Hero/Hero';
import { projectData } from '../constants/projects';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { Home } from './Views.style';

const Projects = (() => {
  const [reveal, setReveal] = useState(-1);

  return (
    <Home>
      <div className="container">
        <div className="box" style={{ position: 'relative' }}>
          <Hero>
            Projects
          </Hero>
          <OuterBox>
            <InnerBox className="no-pad">
              <div className="row m-0">
                {projectData.map((project, index) => {
                  const ProjectContainer = project.slug ? Link : Zoom;
                  return (
                    <div className="item-summary right col-md-6 col-12 p-0">
                      <ProjectContainer to={`/projects/${project.slug}`} className="no-default">
                      <img width="100%" src={project.img} alt={project.name} />
                      </ProjectContainer>
                      <div className="item-info p-2">
                        <h3 className="item-title title__h3">
                          {project.type}&nbsp;
                          {project.name}
                        </h3>
                        <div className="item-meta">
                          <div className="item-role">{project.role}</div>
                          <div className="item-stack">{project.stack}</div>
                        </div>
                        <div key={project.excerpt} className="item-description mt-3" data-expand={reveal === index}>
                          {project.excerpt}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            <div className="d-none home__projects-accordion">
                {projectData.map((project, index) => ([
                  <button data-project={project.name} key={project.name} data-selected={reveal === index} onClick={() => setReveal(reveal !== index ? index : -1)} className="home__projects-item">
                    <h3 className="item-title title__h3">
                      {project.type}&nbsp;
                      {project.name}
                    </h3>
                    <div className="item-meta">
                      <div className="item-role">{project.role}</div>
                      <div className="item-stack">{project.stack}</div>
                      <div>
                        {project.excerpt}
                      </div>
                    </div>
                  </button>,
                  <div key={project.summary} className="item-description" data-expand={reveal === index}>
                    {project.summary}
                  </div>
                ]))}
            </div>
            </InnerBox>
          </OuterBox>
        </div>
      </div>
    </Home>
  );
});

export default Projects;
