import styled, { keyframes } from 'styled-components';

const zoom = keyframes`
  0%{
    right: -100px;
  }
  100%{
    right: calc(100vw + 100px);
  }
`;

const thanks = keyframes`
  0%{
    left: 0;
  }
  68%{
    left: 0;
  }
  100%{
    left: -100px;
  }
`;

export default styled.div`
  z-index: 200000;
  font-size: 100px;

  .take {
    z-index: 200000;
    transform: rotate(44deg);
    animation: 2s ${thanks} ease-in-out forwards;
    position: absolute;
    left: 0;
  }

  .give {
    z-index: 200000;
    animation: 2s ${zoom} ease-in-out forwards;
    position: absolute;
  }

  .appreciate-you {
    font-size: 23px;
    position: absolute;
    top: 50%;
    left: 50%;
  }

`;
