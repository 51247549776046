import React from 'react';

export const workData = [
  {
    logo: 'olive.png',
    title: 'Software Engineer',
    company: 'Olive AI - Remote',
    description: (
      <ul>
        <li>Designed and built a reusable solution in Python to efficiently parse thousands of patient records and accurately provide valuable analytics for customer satisfaction</li>
        <li>Collaborated across teams and with stakeholders to implement custom automations in TypeScript to improve the coverage identification processes of several hospital systems </li>
        <li>Created a command line tool that allows users to easily read and search through legacy automations, which were previously only readable via an end of life UI</li>
      </ul>
    ),
  },
  {
    logo: 'wonderful.png',
    title: 'Full Stack Engineer',
    company: 'Wonderful Collective - Los Angeles',
    description: (
      <ul>
        <li>Promoted to a full-time full-stack engineer from an intern role in less than one year</li>
        <li>Built from scratch the frontend for a non-profit funding platform (approx. 700K users) using React. Later took over backend feature request, database maintenance, and globalization, namely translation, location anonymization, and security</li>
        <li>Managed 4-5 client relationships at a time with different code bases (16 clients total)</li>
        <li>Communicated the technical aspects software development to clients in a manageable and digestible way</li>
        <li>Implemented new designs into several front-end applications with pixel perfect accuracy, usually in React and PHP</li>
        <li>Leveraged React and Redux to develop a live mobile platform for a large car company from scratch</li>
        <li>Designed and created an augmented reality web interface with ThreeJS to display information based on image recognition</li>
        <li>Created a technical library to systematize processes and speed up the development process</li>
        <li>Adjusted to handle large growth and increasing complexity</li>
      </ul>
    ),
  },
  {
    logo: 'twiz.png',
    title: 'Full Stack Engineer',
    company: 'Twiz - Los Angeles',
    description: (
      <ul>
        <li>Leading the programing and development efforts for a marketing content management startup company</li>
        <li>Collaborated with a Cross-functional team on planning, wireframing, and environment testing efforts</li>
        <li>Leading a team of three developers to create and execute an evolving task list and shifting priorities</li>
      </ul>
    ),
  },
  {
    logo: 'rw.png',
    title: 'Program Division Leader',
    company: 'River Way Ranch Camp - Sanger, CA',
    description: (
      <ul>
        <li>Selected to plan and lead all daily events for 300 + participants each week for a total of 12K</li>
        <li>Trained and observed over 80 counselors and monitored performance</li>
        <li>Communicated and collaborated with parents to ensure participant needs were met</li>
        <li>Managed crisis as they occurred and leveraged interpersonal communication and emotional intelligence to resolve conflict</li>
        <li>Leveraged inventory planning skills for food and other necessities</li>
        <li>Acted as a role model through exciting activities and programs to help to build their confidence and leadership skills</li>
      </ul>
    ),
  },
  {
    logo: 'lmu.png',
    title: 'B.S. in Computer Science',
    company: 'Loyola Marymount University - Los Angeles, CA',
    description: '',
    classes: 'no-bottom'
  },
];
