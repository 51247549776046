import React from 'react';
import EducationStyle from './Education.style';

const Education = () => (
  <EducationStyle>
    <h1>
      <span>I have a</span> B.S. <span>in</span> Computer Science
    </h1>
    <h3>
      <span>from</span> Loyola Marymount University.
    </h3>

  </EducationStyle>
);

export default Education;
