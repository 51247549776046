import React from 'react';
import HeroStyle from './Hero.style';

function Hero() {
  return (
    <HeroStyle>
      <div className="hero-holder">
        <div className="centered-text">
        <div className="exit-panel" />
          <h1>
            Hi. I'm Sam.
          </h1>
          <h4>
            I am a full stack software engineer who creates the software that I wish already existed
          </h4>
        </div>
      </div>
    {
      // <a
      //   className="duck"
      //   style={pos}
      //   onMouseEnter={handleMouseEnter}
      //   onMouseMove={handleMouseMove}
      //   onMouseLeave={handleMouseLeave}
      //   href="google.com"
      // >
      //   <img
      //     src="https://www.stickpng.com/assets/images/580b57fbd9996e24bc43bbe5.png"
      //     alt="Experimental Click"
      //   />
      // </a>
    }

    </HeroStyle>
  );
}

export default Hero;
