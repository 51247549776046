import styled from 'styled-components';

export default styled.div`
  vertical-align: top;
  display: table-cell;
  position: relative;
  height: 100%;
  padding: 25px;

  border-left: ${(({ theme }) => theme.shorthand.border)};;

  &:last-child {
    border-right: ${(({ theme }) => theme.shorthand.border)};;
  }

  &[data-fill="true"] {
    background-image: url("assets/home/circle-filler.svg");

    background-size: 9%;
    background-position: top;
  }

  &.no-pad {
    padding: 0;
  }

  &.mobile-hide {
    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      display: none;
    }
  }

  &.tablet-hide {
    @media ${(({ theme }) => theme.breakpoints.tablet)} {
      display: none;
    }
  }
`;
