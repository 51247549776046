import styled from 'styled-components';

export default styled.div`
.site__hero-block {
  position: relative;
  height: 40vh;
  min-height: 460px;
  .site__hero-summary {
    width: 85%;
    font-size: 24px;

    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      width: 100%;
    }

    .site__hero-copy {
      position: absolute;
      width: calc(100% - 50px);
      bottom: 5px;
      font-size: 36px;
      text-align: left;

      @media ${(({ theme }) => theme.breakpoints.mobile)} {
        font-size: 29px;
      }
    }
  }
  .site__hero-right {
    position: absolute;
    width: calc(100% - 50px);
    bottom: 5px;
    font-size: 16px;
    text-align: left;
  }
  .site__hero-nav {
    width: 15%;
    position: relative;

    @media ${(({ theme }) => theme.breakpoints.mobile)} {
      display: none;
    }

    .site__hero-links {
      position: absolute;
      right: -25px;
      font-size: 24px;
      text-align: right;

      a {
        display: inline-block;
        min-width: 60%;
        color: ${(({ theme }) => theme.defaults.text)};
        text-align: right;
        list-style-type: none;
        &:hover {
          padding-right: 35px;
          background-color: transparent;
        }
        &[data-current="true"] {
          pointer-events: none;
          text-decoration: underline;
          padding-right: 45px;
          position: relative;
          &:after {
            content: "*";
            position: absolute;
            right: 15px;
            font-size: 36px;
            top: 0px;
          }
        }
      }
    }
  }
}
`;
