import React from 'react';

import DoubleStyles from './InnerBox.style';

const Double = ({ children, dimension, fill, styles={}, ...rest }) => {
  return (
    <DoubleStyles data-fill={fill} style={{ width: dimension, ...styles}} {...rest}>
      {children}
    </DoubleStyles>
  );
};

export default Double;
