import React, { useState } from 'react';

import FriendAppreciationStyle from './FriendAppreciation.style';

const FriendAppreciation = () => {
  const EMOJIS = ['👄', '😋', '🍅', '🍆', '🥣']
  const topNumber = `${Math.random() * 100}%`;

  return (
    <FriendAppreciationStyle>
      <div className="take" style={{'top': topNumber}}>✋</div>
      <div className="give" style={{'top': topNumber}}>
        {EMOJIS[(Math.floor(Math.random() * EMOJIS.length)) - 1]}
        <span className="appreciate-you">THANKS</span>
      </div>

    </FriendAppreciationStyle>
  );
}

export default FriendAppreciation;
