import styled from 'styled-components';

export default styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  transition: 0.4s all ease-in-out;

  height: 50px;
  width: 100%;
  color: ${(({ theme }) => theme.colors.windowsTan)};

  &.blue {
    color: ${(({ theme }) => theme.colors.white)};

    .logo-top {
      background: ${(({ theme }) => theme.colors.xpBlue)};
      border-color: ${(({ theme }) => theme.colors.xpBlue)};
    }
    .logo-side {
      background: ${(({ theme }) => theme.colors.xpGreen)};
      border-color: ${(({ theme }) => theme.colors.xpGreen)};

    }
    .logo-side {
      .logo {
        fill: ${(({ theme }) => theme.colors.white)};
      }
    }
    .links {
      a {
        color: ${(({ theme }) => theme.colors.white)};
        fill: ${(({ theme }) => theme.colors.white)};
      }
    }
  }

  .logo-top {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 156px);
    height: 50px;
    background: ${(({ theme }) => theme.colors.windowsTan)};
    border-bottom: 2px solid ${(({ theme }) => theme.colors.windowsTan)};

    transition: 0.4s all ease-in-out;
  }

  .logo-side {
    position: absolute;
    top: 0;
    left: -80px;
    transform: rotate(0);
    transform-origin: bottom right;
    width: 237px;
    height: 50px;
    border-bottom: 2px solid ${(({ theme }) => theme.colors.windowsTan)};
    background: ${(({ theme }) => theme.colors.windowsTan)};
    transform: rotate(0);
    transition: 0.4s all ease-in-out;

    .logo {
      display: inline-block;
      vertical-align: top;
      font-family: Helvetica;
      fill: ${(({ theme }) => theme.colors.black)};
      position: absolute;
      bottom: 3px;
      right: 0;
      transition: 0.4s all ease-in-out;

    }
  }

  .links {
    display: inline-block;
    vertical-align: top;
    float: right;
    line-height: 50px;
    transition: 0.4s all ease-in-out;

    a {
      display: inline-block;
      vertical-align: top;
      height: 50px;
      text-decoration: none;
      transition: 0.4s all ease-in-out;
      color: ${(({ theme }) => theme.colors.black)};
      font-weight: bold;
      padding: 0 20px;

      &:hover {
        background: ${(({ theme }) => theme.colors.fadedWhite)};
        transition: 0.2s all ease-in-out;

        .hover-explanation {
          padding-left: 12px;
          max-width: 500px;
        }
      }

      svg {
        vertical-align: top;
        margin: 13px 0;
      }

      .hover-explanation {
        transition: 0.4s all ease-in-out;
        position: relative;
        display: inline-block;
        max-width: 0px;
        overflow: hidden;
      }
    }
  }

  &.down-position {
    color: ${(({ theme }) => theme.colors.white)};
    fill: ${(({ theme }) => theme.colors.white)};
    border-color: ${(({ theme }) => theme.colors.white)};
    .logo-top {
      border-color: ${(({ theme }) => theme.colors.white)};
      background: transparent;

    }
    .logo-side {
      transform: rotate(-45deg);
      color: ${(({ theme }) => theme.colors.white)};
      border-color: ${(({ theme }) => theme.colors.white)};
      background: transparent;
      .logo {
        fill: ${(({ theme }) => theme.colors.white)};
      }
    }
    .links {
      a {
        fill: ${(({ theme }) => theme.colors.white)};
        color: ${(({ theme }) => theme.colors.white)};

        &:hover {
          background: ${(({ theme }) => theme.colors.white)};
          fill: ${(({ theme }) => theme.colors.black)};
          color: ${(({ theme }) => theme.colors.black)};
          transition: 0.2s all ease-in-out;
        }
      }
    }

    .nav-hamburger {
      display: none;
    }
  }

  &[data-mobile="true"] {
    &.blue {
      .nav-hamburger span {
        background: ${(({ theme }) => theme.colors.white)};
      }
    }
    .nav-hamburger {
      display: inline-block;
      float: right;
      margin: 13px;
      width: 36px;
      height: 24px;
      position: relative;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: #000;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
        &:nth-child(1) {
          top: 0px;
        }
        &:nth-child(2) {
          top: 10px;
        }
        &:nth-child(3) {
          top: 20px;
        }
      }
    }

    .links {
      position: absolute;
      width: 100%;
      height: calc(100vh - 50px);
      top: 52px;
      left: 100%;
      display: block;

      a {
        display: block;

        .hover-explanation {
          max-width: 500px;
          padding-left: 20px;
        }
      }
    }

    &[data-opened="true"] {
      .nav-hamburger {
        span {
          &:nth-child(1) {
            top: 10px;
            -webkit-transform: rotate(-135deg);
            -moz-transform: rotate(-135deg);
            -o-transform: rotate(-135deg);
            transform: rotate(-135deg);
          }
          &:nth-child(2) {
            opacity: 0;
            right: -60px;
          }
          &:nth-child(3) {
            top: 10px;
            -webkit-transform: rotate(135deg);
            -moz-transform: rotate(135deg);
            -o-transform: rotate(135deg);
            transform: rotate(135deg);
          }
        }
      }

      .links {
        background: inherit;
        left: 0%;
      }
    }

    &.down-position {
      .nav-hamburger span {
        background: ${(({ theme }) => theme.colors.white)};
      }
      .links {
        a {
          background: ${(({ theme }) => theme.colors.black)};
        }
      }
    }
  }

  &.bye-bye {
    top: -300px;
    pointer-events: none;
  }
`;
