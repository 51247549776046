import styled from 'styled-components';

export default styled.div`
  padding: 10%;
  background: ${(({ theme }) => theme.colors.black)};
  position: relative;
  text-align: center;
  overflow: hidden;

  .skills-section {
    position: absolute;
    top: 50vh;
  }

  .skills-holder {
    position: absolute;
    width: auto;
    top: 50%;
    transform: translateY(50%);    white-space: nowrap;
  }


  .skills-cluster {
    vertical-align: top;
    max-width: 100%;
    width: 600px;
    margin: 0 80px;
    white-space: normal;
    display: inline-block;

    .skills-text {
      font-size: 26px;
      font-weight: bold;
      text-align: center;
      line-height: 28px;
      white-space: nowrap;
      color: ${(({ theme }) => theme.colors.white)};
    }

    &[data-max="4"] {
      width: 650px;
    }
  }

  .skill-box {
    display: inline-block;
    width: 116px;
    height: 144px;
    margin: 20px;
    vertical-align: top;

    .skill-logo {
      height: 116px;
      width: 116px;
      border-radius: 12px;
      overflow: hidden;

      img {
        height: calc(100% - 10px);
        padding: 5px;
        width: auto;
      }
    }

    .skill-text {
      text-align: center;
      font-size: 20px;
      line-height: 28px;
      color: ${(({ theme }) => theme.colors.white)};
      font-weight: bold;
      white-space: nowrap;
    }

    &[data-size="small"] {
      width: 80px;
      height: 96px;
      .skill-logo {
        height: 80px;
        width: 80px;
      }

      .skill-text {
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
`;
