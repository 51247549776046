import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import navLinks from '../../constants/nav';
import NavStyle from './Nav.style';

const Nav = ({ initialExpand=false, ...rest }) => {
  const [expanded, setExpanded] = useState(initialExpand);
  const location = useLocation().pathname;

  return (
    <NavStyle
      className="main-nav"
      data-down={location.includes('article')}
      data-hide={location === '/abstract' || location === '/'}
      {...rest}
    >
      <div className="container">
        <Link to="/" className="main-logo">
          chami.io
        </Link>

        <div className="main-menu" data-expanded={expanded}>
          <button className="main-menu__option" onClick={() => setExpanded(!expanded)}>More</button>
          <div className="main-menu__more">
            {navLinks.slice(1, -1).map((n) => (
              <Link key={n.path} to={n.path} className="no-default" data-current={location === n.path}>
                {n.title}
              </Link>
            ))}
          </div>
          <Link to="/contact" className="main-menu__option email-option"  data-current={location === '/contact'}>
            Contact
          </Link>
        </div>
      </div>
    </NavStyle>
  );
}

export default Nav;
