import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import InnerBox from '../components/UI/InnerBox/InnerBox';
import OuterBox from '../components/UI/OuterBox/OuterBox';
import Hero from '../components/UI/Hero/Hero';
import { projectData } from '../constants/projects';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { Home } from './Views.style';

const ProjectDetails = (() => {
  const { slug } = useParams();
  const [reveal, setReveal] = useState(-1);

  const projectDetails = projectData.find(p => p.slug === slug);

  return (
    <Home>
      <div className="container">
        <div className="box" style={{ position: 'relative' }}>
          <Hero>
            <span className="emphasize">{projectDetails.name}</span>
            <span>&nbsp;{projectDetails.subname}</span>
          </Hero>
          <OuterBox className="top">
            <InnerBox dimension="100%">

            </InnerBox>
          </OuterBox>
        </div>
      </div>
    </Home>
  );
});

export default ProjectDetails;
