import React, { useState, useEffect} from 'react';
import {
  Link,
  useLocation,
} from "react-router-dom";

import articles from '../md/articleDictionary';
import InnerBox from '../components/UI/InnerBox/InnerBox';
import OuterBox from '../components/UI/OuterBox/OuterBox';
import Hero from '../components/UI/Hero/Hero';

const TAGS_FOR_SELECT = [
    "Professional",
    "Technical",
    "Career"
]

export default () => {
  const location = useLocation();
  const [filterBy, setFilter] = useState('');

  useEffect(() => {
    setFilter(new URLSearchParams(location.search).get('category'))
  }, [location]);

  return (
    <div className="container">
      <Hero colorKey={'#F5CF65'}>
        Blog
        { /* <ReactMarkdown children={md} /> */ }
      </Hero>
      <OuterBox className="top no-vertical-overflow">
        <InnerBox className="home__blog-upper">
          <div className="blog-category-holder">
            <Link data-filtered={!filterBy} to={`/blog`}>
              All
            </Link>
            {TAGS_FOR_SELECT.map((tag) => (
              <Link data-filtered={tag === filterBy} to={`/blog?category=${tag}`}>
                {tag}
              </Link>
            ))}
          </div>
        </InnerBox>
      </OuterBox>
      {Object.entries(articles).map(([slug, article]) => {
        if (!filterBy || article.tags.includes(filterBy)) {
          return (
          <Link key={slug} to={`/article/${slug}`} className="no-default">
            <OuterBox dimension="80%" className="mobile-full home__blog-article center"  styles={{ backgroundImage: `url(${article.thumbnail || article.hero})`}}>
              <InnerBox className="home__blog-photo" dimension="250px" styles={{ backgroundImage: `url(${article.thumbnail || article.hero})`}} />
              <InnerBox className="home__blog-box">
                <div className="d-flex flex-column">
                  <h2 className={!!article.password ? 'has-password' : ''}>{article.name}</h2>
                  <h5 className="">
                    {article.subheading}
                  </h5>
                  <div className="blog__article-tags">
                    {article.tags.map((a) => (
                      <div data-filter-selected={a === filterBy} className="blog__article-tag">
                        {a}
                      </div>
                    ))}
                  </div>
                </div>
              </InnerBox>
            </OuterBox>
          </Link>
          )
        }
        return false;
      })}
      <div className="inner-fill" />
    </div>
  )
};
