const defaults = {
  white: '#fff',
  black: '#000',
  text: '#000',
  background: '#A4B9D6',
  backgroundMain: '#A4B9D6',
  backgroundSecond: '#ffdb00',
  _background: '#A4B9D6' // This will not change
}
const colors = {
  midPrimary: '#A2D5C6',
  highlight: '#e8b272',
  white: '#fff',
  offWhite: '#838191',
  fadedWhite: 'rgba(255, 255, 255, 0.2)',
  fadedBlack: 'rgba(0, 0, 0, 0.4)',
  black: '#000000',
  windowsTan: '#CCC6BB',
  windowsBlue: '#2E5A95;',
  xpBlue: '#2240cc',
  xpBlueGradient: 'linear-gradient(0deg,  #0f46e3, #104bf4, #2240cc)',
  xpGreen: '#36A02F',
  windows: 'linear-gradient(-90deg, #97c3f1, #0e0f56)',
  workGradient: 'linear-gradient(0deg,  #838191, #838191, #838191, #838191)',
};

const pages = {
  '/':'#A4B9D6',
  '/about': '#f0a0a0',
  '/resume': '#e2e6f3',
  '/projects': '#87cff9',
  '/friends': '#ffffff',
  '/blog': '#ffc0c9',
  '/contact': '#FFC784',
  '/article/wonderful': '#e2e6f3',
  '/article/2021-javascript-operators': '#f5df1c',
  '/article/001-thoughts-fear': '#96a1a2',
  '/projects/nutraceuticals': '#fb956b',
  '/projects/cavu': '#446cd9',
};

const faded = {
  green: 'rgba(18,219,61,0.6)',
  yellow: 'rgba(255,255,0,0.6)',
  grey: 'rgba(0,0,0,0.6)',
  white: 'rgba(255,255,255,0.6)',
}

const sizes = {
  small: '767px',
  medium: '1023px',
  large: '1024px',
}

const breakpoints = {
  mobile: `(max-width: ${sizes.small})`,
  notMobile: `(min-width: ${sizes.small})`,
  tablet: `(max-width: ${sizes.medium})`,
  desktop: `(min-width: ${sizes.large})`,
}

const shorthand = {
  border: `4px solid ${defaults.text}`
}

const fonts = {

}

export default {
  fonts,
  colors,
  defaults,
  faded,
  shorthand,
  breakpoints,
  pages,
};
