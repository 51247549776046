import styled from 'styled-components';

export default styled.span`
  position: relative;
  display: inline-block;
  vertical-align: text-bottom;
  height: 43px;
  width: auto;
  overflow: hidden;

  @media ${(({ theme }) => theme.breakpoints.mobile)} {
    height: 35px;
  }

  .alternate-box {
    text-align: left;
    transition: 0.4s margin-top ease-in-out;
    position: relative;

    &[data-index="0"] {
      margin-top: 0;
    }

    &[data-index="1"] {
      margin-top: -43px;

      @media ${(({ theme }) => theme.breakpoints.mobile)} {
        margin-top: -35px;
      }
    }

    &[data-index="2"] {
      margin-top: -86px;

      @media ${(({ theme }) => theme.breakpoints.mobile)} {
        margin-top: -70px;
      }
    }
    &[data-index="3"] {
      margin-top: -129px;

      @media ${(({ theme }) => theme.breakpoints.mobile)} {
        margin-top: -87px;
      }
    }
    &[data-index="4"] {
      margin-top: -129px;

      @media ${(({ theme }) => theme.breakpoints.mobile)} {
        margin-top: -87px;
      }
    }
  }
`;
